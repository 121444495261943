import {useTranslation} from "react-i18next";
import {useGetUserSettingsQuery, useSetUserSettingsMutation} from "@/api/user";
import {useMemo} from "react";
import {Checkbox} from "@/components/ui/checkbox";
import HourSelector from "@/components/data/HourSelector";
import {Button} from "@/components/ui/button";
import {Plus, X} from "lucide-react";
import {DialogClose} from "@/components/ui/dialog";

const QueueSettings = () => {
    const {t} = useTranslation();

    const [setSettings] = useSetUserSettingsMutation();
    const {data: userSettings, isLoading} = useGetUserSettingsQuery();

    const queueSettings = useMemo<any>(() => userSettings?.postsQueue ?? {}, [userSettings]);
    const queueSlots = useMemo(() => queueSettings?.slots ?? [{
        time: "9:00",
        days: []
    }], [queueSettings]);

    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    const dayCellClassname = "w-full flex-1 border-l px-2 text-center";
    const timeCellClassname = "w-32";

    if (isLoading) {
        return <></>
    }

    return (
        <div>
            <div className="my-4">
                <div
                    className="flex w-full"
                >
                    <p className={timeCellClassname}>
                        &nbsp;
                    </p>

                    <div className="flex items-center flex-grow w-full">
                        {days.map((day) => (
                            <div key={day} className={dayCellClassname}>
                                <p className="w-full truncate uppercase text-xs text-muted-foreground">
                                    {t(`common.${day}`)}
                                </p>
                            </div>
                        ))}
                    </div>

                    <div className="w-12"/>
                </div>
                {queueSlots.map((slot: any, index: number) => (
                    <div
                        key={index}
                        className="flex w-full items-center"
                    >
                        <p className={timeCellClassname}>
                            <HourSelector
                                variant="text"
                                hour={parseInt(slot.time?.split(':')[0])}
                                min={parseInt(slot.time?.split(':')[1])}
                                onHourChange={(time) => {
                                    const newSlots = [...queueSlots];
                                    newSlots[index] = {
                                        ...newSlots[index],
                                        time: `${time}:${newSlots[index].time?.split(':')[1]}`
                                    }

                                    setSettings({
                                        key: 'postsQueue',
                                        value: {
                                            ...queueSettings,
                                            slots: newSlots
                                        }
                                    })
                                }}
                                onMinChange={(min) => {
                                    const newSlots = [...queueSlots];
                                    newSlots[index] = {
                                        ...newSlots[index],
                                        time: `${newSlots[index].time?.split(':')[0]}:${min}`
                                    }

                                    setSettings({
                                        key: 'postsQueue',
                                        value: {
                                            ...queueSettings,
                                            slots: newSlots
                                        }
                                    })
                                }}
                            />
                        </p>

                        <div className="flex items-center flex-grow w-full">
                            {days.map((day) => (
                                <div key={day} className={dayCellClassname}>
                                    <Checkbox
                                        checked={slot.days.includes(day)}
                                        onCheckedChange={(checked) => {
                                            const newSlots = [...queueSlots];
                                            newSlots[index] = {
                                                ...newSlots[index],
                                                days: checked
                                                    ? [...newSlots[index].days, day]
                                                    : newSlots[index].days.filter((d: string) => d !== day)
                                            }

                                            setSettings({
                                                key: 'postsQueue',
                                                value: {
                                                    ...queueSettings,
                                                    slots: newSlots
                                                }
                                            })
                                        }}
                                    />
                                </div>
                            ))}
                        </div>

                        <div className="w-12">
                            <Button
                                onClick={() => {
                                    setSettings({
                                        key: 'postsQueue',
                                        value: {
                                            ...queueSettings,
                                            slots: queueSlots.filter((_: any, i: number) => i !== index)
                                        }
                                    })
                                }}
                                variant="ghost"
                            >
                                <X/>
                            </Button>
                        </div>
                    </div>
                ))}
            </div>

            <div className="mt-4 flex gap-4">
                <Button
                    variant="outline"
                    className="flex-grow"
                    onClick={() => {
                        setSettings({
                            key: 'postsQueue',
                            value: {
                                ...queueSettings,
                                slots: [
                                    ...queueSlots,
                                    {
                                        time: "9:00",
                                        days: []
                                    }
                                ]
                            }
                        })
                    }}
                >
                    <Plus/>
                    {t('feed.my_queue.add_slot')}
                </Button>

                <DialogClose>
                    <Button>
                        {t('feed.my_queue.close')}
                    </Button>
                </DialogClose>
            </div>
        </div>
    )
}

export default QueueSettings;