import {FeedItem} from "@/api/feed.types";
import {Link} from "react-router-dom";
import {Button} from "@/components/ui/button";
import {ExternalLinkIcon, Recycle} from "lucide-react";
import LikeButton from "@/modules/feed/components/PostFeed/LikeButton";
import PostComments from "@/modules/feed/components/PostFeed/PostComments";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import DateRenderer from "@/components/elements/DateRenderer";
import PostMedias from "@/modules/feed/components/PostFeed/PostMedias";
import ResourceButton from "@/modules/feed/components/PostFeed/ResourceButton";
import Markdown from "react-markdown";
import {UserPost} from "@/api/writer.types";
import {clsx} from "clsx";
import ObjectAvatar from "@/components/elements/ObjectAvatar";
import PostStatus from "@/modules/feed/components/PostFeed/PostStatus";
import {useWriter} from "@/modules/feed/components/PostWriterDialog";
import {useContactSheet} from "@/modules/contacts/components/ContactSheet";

type PostItemProps = {
    post: FeedItem;
    withStatus?: string;
    withInteractions?: boolean
    withExternaLink?: boolean
}

const PostItem = ({post, withStatus, withInteractions = true, withExternaLink = true}: PostItemProps) => {
    const {t} = useTranslation();
    const [showComments, setShowComments] = useState(false)

    const truncatedPostContent = post.content?.split(' ').slice(0, 32).join(' ') ?? '';
    const [postContent, setPostContent] = useState(truncatedPostContent);

    const isTruncated = post.content !== postContent;

    const {open: openWriter} = useWriter();

    const {setContactUuid} = useContactSheet((state) => state) as any;

    return (
        <div key={post.uuid}
             className={clsx(`max-w-[560px] w-full flex-shrink-0 justify-between border rounded mb-4 overflow-hidden`)}
        >
            <div className={`flex items-center border-b py-4 mx-4 gap-4`}>
                <Link
                    to={!post.author?.uuid ? '#' : `/contacts/${post.author?.uuid}`}
                    onClick={(e: any) => {
                        if (!post.author?.uuid) return;
                        e.preventDefault();
                        setContactUuid(post.author?.uuid);
                    }}
                >
                    <ObjectAvatar
                        object={post.author}
                        className="size-12"
                    />
                </Link>

                <Link to={!post.author?.uuid ? '#' : `/contacts/${post.author?.uuid}`} className="flex-grow"
                      onClick={(e: any) => {
                          if (!post.author?.uuid) return;
                          e.preventDefault();
                          setContactUuid(post.author?.uuid);
                      }}
                >
                    <p className="font-semibold">
                        {post.author.display_name}

                        {post.trending_level === 'high' && <span className="ml-2">🔥</span>}
                    </p>
                    <p
                        className="text-sm opacity-50"
                    >
                        <DateRenderer
                            date={(post as UserPost).created_at ?? (post as UserPost).scheduled_at ?? post.posted_at}
                            format="fromNow"/>
                    </p>
                </Link>

                <div
                    className="flex"
                >

                    <Button
                        variant="link"
                        className="text-current opacity-25"
                        onClick={() => openWriter(post.original_text)}
                    >
                        <Recycle/>
                    </Button>

                    {withExternaLink &&
                        <Button asChild
                                variant="link"
                                className="text-current opacity-25"
                        >
                            <Link target="_blank" to={post.url}>
                                <ExternalLinkIcon/>
                            </Link>
                        </Button>
                    }

                </div>

                {withStatus && <PostStatus status={withStatus}/>}

            </div>

            <div
                className="p-4 [&>p]:mb-2 [&>p:last-child]:mb-0"
            >
                <Markdown>
                    {postContent + (isTruncated ? '...' : '')}
                </Markdown>
            </div>

            {isTruncated &&
                <Button
                    variant="link"
                    onClick={() => setPostContent(post.content)}
                >
                    {t('feed.read_more')}
                </Button>
            }

            <PostMedias post={post}/>

            {post.counts && (
                <div
                    className="flex gap-4 p-4 justify-end"
                >
                    {post.counts.likes > 0 &&
                        <div className="text-sm lowercase space-x-1">
                            <span className="font-semibold">{post.counts.likes}</span>
                            <span
                                className="opacity-50">{t('feed.counts.like' + (post.counts.likes > 1 ? 's' : ''))}</span>
                        </div>
                    }
                    {post.counts.comments > 0 &&
                        <div className="text-sm lowercase space-x-1">
                            <span className="font-semibold">{post.counts.comments}</span>
                            <span
                                className="opacity-50">{t('feed.counts.comment' + (post.counts.comments > 1 ? 's' : ''))}</span>
                        </div>
                    }
                    {post.counts.shares > 0 &&
                        <div className="text-sm lowercase space-x-1">
                            <span className="font-semibold">{post.counts.shares}</span>
                            <span
                                className="opacity-50">{t('feed.counts.share' + (post.counts.shares > 1 ? 's' : ''))}</span>
                        </div>
                    }
                    {post.counts.impressions > 0 &&
                        <div className="text-sm lowercase space-x-1">
                            <span className="font-semibold">{post.counts.impressions}</span>
                            <span
                                className="opacity-50">{t('feed.counts.view' + (post.counts.impressions > 1 ? 's' : ''))}</span>
                        </div>
                    }
                </div>
            )}

            {withInteractions &&
                <>
                    <div
                        className="flex gap-2 bg-muted/50 p-4"
                    >
                        <LikeButton
                            post={post}
                            initialStatus={post.interactions.liked}
                        />

                        <ResourceButton
                            post={post}
                            initialStatus={post.interactions.resourced}
                        />

                        <Button
                            variant={showComments ? 'secondary' : 'outline'}
                            onClick={() => setShowComments(!showComments)}
                        >
                            {t('feed.comment')}
                        </Button>
                    </div>

                    {showComments && <div className="p-4">
                        <PostComments post={post}/>
                    </div>
                    }
                </>
            }
        </div>
    )
}

export default PostItem;