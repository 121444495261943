import React, {useEffect} from "react";
import {useNavigate} from "react-router";
import useUser from "@/hooks/useUser";
import constants from "@/config/constants";
import {useSearchParams} from "react-router-dom";
import {redirectUrl} from "@/modules/login/LinkedInSignIn";

const Login = () => {
    const router = useNavigate();

    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const source = searchParams.get("source");
    const redirect = searchParams.get("redirect");

    const {refetch} = useUser()

    useEffect(() => {
        if (source === "extension_token") {
            // Shown in an iframe, push the token to the parent window
            window.parent.postMessage({
                token: localStorage.getItem(constants.SESSION_TOKEN)
            }, "*");

            return;
        }

        if (source === 'extension') {
            window.location.href = redirectUrl;
        }

        router("/");

        if (token) {
            localStorage.setItem(constants.SESSION_TOKEN, token);

            if (redirect) {
                window.location.href = redirect;
            } else {
                window.location.reload();
            }
        }
    }, [refetch, token, router, source]);

    return (
        <>
        </>
    )
}

export default Login