import {Input} from "@/components/ui/input";
import {useEffect, useState} from "react";
import {useTextReplaceMutation} from "@/api/magic";
import {MagicWand} from "iconoir-react";
import {Button} from "@/components/ui/button";
import Loader from "@/components/elements/Loader";
import {Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious} from "@/components/ui/carousel";
import Markdown from "react-markdown";

type WysiwygMagicProps = {
    value: string;
    selectedParagraph: string;
    onAlternativeChoose: (value: string) => void;
    onExpand: (expanded: boolean) => void;
}

const WysiwygMagic = ({value, selectedParagraph,onAlternativeChoose,onExpand}: WysiwygMagicProps) => {
    const [instructionsValue, setInstructionsValue] = useState<string>('');
    const [textAlternatives, setTextAlternatives] = useState<string[]>([]);

    const [replaceText, {isLoading}] = useTextReplaceMutation()

    useEffect(() => {
        setTextAlternatives([])
        setInstructionsValue('')
    }, [value,selectedParagraph]);

    useEffect(() => {
        if(isLoading||textAlternatives.length){
            onExpand(true)
        } else {
            onExpand(false)
        }
    }, [isLoading, onExpand, textAlternatives]);

    const handleSubmit = (e: any) => {
        e.preventDefault();

        replaceText({
            text: value,
            replace: selectedParagraph,
            instructions: instructionsValue
        }).then((response) => {
            setTextAlternatives(response.data?.items??[])
        })
    }

    const handleAlternativeChoose = (alternative: string) => {
        const text = value.replace(selectedParagraph, alternative)
        onAlternativeChoose(text)
    }

    return (
        <div className="flex flex-col gap-1 flex-grow">
            <form onSubmit={handleSubmit} className="flex gap-1">
                <Input
                    value={instructionsValue}
                    onChange={(e) => setInstructionsValue(e.target.value)}
                    placeholder="Enter instructions"
                    className="w-full"
                    disabled={isLoading}
                />
                <Button type="submit" disabled={isLoading}>
                    <MagicWand/>
                </Button>
            </form>

            <div className="flex-grow flex flex-col items-center justify-center">
                {isLoading && <Loader/>}
                {!isLoading && textAlternatives.length > 0 && (
                    <Carousel
                        opts={{
                            align: "start",
                        }}
                        className="max-w-sm w-2/3 mx-auto h-full"
                    >
                        <CarouselContent>
                            {textAlternatives.map((alternative, index) => (
                                <CarouselItem key={index}>
                                        <div className="h-[210px] flex flex-col items-center justify-center gap-2 overflow-y-auto">
                                            <Markdown
                                                className="pointer-events-none"
                                            >{alternative}</Markdown>
                                            <Button onClick={() => handleAlternativeChoose(alternative)}>
                                                Choose
                                            </Button>
                                        </div>
                                </CarouselItem>
                            ))}
                        </CarouselContent>
                        <CarouselPrevious />
                        <CarouselNext />
                    </Carousel>
                )}
            </div>
        </div>
    )
}
export default WysiwygMagic;