import {Helmet} from "react-helmet";
import React from "react";
import AppError from "@/components/elements/AppError";

const NoPage = () => {
    return (
        <div>
            <Helmet>
                <title>Oooops!</title>
            </Helmet>

            <AppError message="404 not found"/>
        </div>
    )
}

export default NoPage