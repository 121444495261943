import {useGetUserQuery, useGetUserSettingsQuery, useLogoutUserMutation} from "@/api/user";

const useUser = () => {
    const {data: user, isLoading, error, refetch} = useGetUserQuery();

    const {
        data: userSettings
    } = useGetUserSettingsQuery()

    const [logout] = useLogoutUserMutation();

    return {
        user,
        userSettings,
        isLoading,
        error,
        refetch,
        logout
    }
}

export default useUser;