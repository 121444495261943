import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select";
import {cn} from "@/lib/utils";
import {useTranslation} from "react-i18next";

type HourSelectorProps = {
    hour?: number;
    min?: number;
    onHourChange: (hours: number) => void;
    onMinChange: (min: number) => void;
    minutesRange?: number;
    disabled?: boolean;
    variant?: 'text'
}

const HourSelector = ({disabled, hour, min, onHourChange, onMinChange, minutesRange, variant}: HourSelectorProps) => {
    const hoursOptions = Array.from({length: 24}, (_, i) => i);
    const minutesOptions = Array.from({length: 60 / (minutesRange ?? 5)}, (_, i) => i * (minutesRange ?? 5));

    const {t} = useTranslation();

    const handleHourChange = (value: string) => {
        onHourChange(parseInt(value));
    }

    const selectClassName = !variant ? undefined : cn({
        'border-none': variant === 'text',
        'rounded-none': variant === 'text',
        'p-0': variant === 'text',
    });

    return (
        <div className="flex items-center">
            <Select
                disabled={disabled}
                value={hour?.toString()}
                onValueChange={handleHourChange}
            >
                <SelectTrigger className={selectClassName}>
                    <SelectValue placeholder={t('common.hour')}/>
                </SelectTrigger>
                <SelectContent>
                    {hoursOptions.map((hour) => (
                        <SelectItem
                            key={hour}
                            value={hour.toString()}
                        >
                            {hour.toString().padStart(2, '0')}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
            <span className="mx-2">:</span>
            <Select
                disabled={disabled}
                value={min?.toString()}
                onValueChange={(value) => onMinChange(parseInt(value))}
            >
                <SelectTrigger className={selectClassName}>
                    <SelectValue placeholder={t('common.minute')}/>
                </SelectTrigger>
                <SelectContent>
                    {minutesOptions.map((min) => (
                        <SelectItem
                            key={min}
                            value={min.toString()}
                        >
                            {min.toString().padStart(2, '0')}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </div>
    );
}

export default HourSelector;