import {Contact} from "@/api/contact.types";
import {useCreateContactNoteMutation, useDeleteContactNoteMutation, useGetContactNotesQuery} from "@/api/contact";
import {FormEvent, useEffect, useState} from "react";
import WysiwygEditor from "@/components/data/WysiwygEditor";
import {Button} from "@/components/ui/button";
import {useTranslation} from "react-i18next";
import DateRenderer from "@/components/elements/DateRenderer";
import Markdown from "react-markdown";
import {X, XIcon} from "lucide-react";
import UploadButton from "@/components/elements/UploadButton";
import {Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle} from "@/components/ui/dialog";
import CreateFirst from "@/components/elements/CreateFirst";
import {clsx} from "clsx";

type ContactNotesProps = {
    contact: Contact
}
const ContactNotes = ({contact}: ContactNotesProps) => {
    const [newNote, setNewNote] = useState('')
    const [noteFiles, setNoteFiles] = useState<File[]>([])
    const [base64Files, setBase64Files] = useState<string[]>([])

    const [showNewNote, setShowNewNote] = useState(false)

    const {data: notes, isLoading: getLoading} = useGetContactNotesQuery({uuid: contact.uuid})

    const [createNote, {isLoading: createLoading}] = useCreateContactNoteMutation()
    const [deleteNote, {isLoading: deleteLoading}] = useDeleteContactNoteMutation()

    const isLoading = getLoading || createLoading || deleteLoading

    const {t} = useTranslation()

    useEffect(() => {
        // Convert files to base64
        noteFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                setBase64Files((prev) => {
                    return [...prev, reader.result as string]
                })
            }
            reader.readAsDataURL(file)
        })
    }, [noteFiles]);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()

        createNote({
            uuid: contact.uuid,
            note: newNote,
            files: base64Files
        }).then(() => {
            setNewNote('')
            setNoteFiles([])
            setShowNewNote(false)
        }).catch(() => {
            // handle error
        });
    }

    useEffect(() => {
        if (showNewNote) {
            setNewNote('')
        }
    }, [showNewNote]);

    if (getLoading) return null

    return (
        <>
            {notes?.length ? (
                <div className="flex justify-end gap-4">
                    <Button onClick={() => setShowNewNote(true)} variant="outline">
                        {t('contacts.notes.add_note')}
                    </Button>
                </div>) : (

                <CreateFirst
                    title={t('contacts.notes.no_notes_title')}
                    description={t('contacts.notes.no_notes_description')}
                >
                    <Button onClick={() => setShowNewNote(true)} variant="outline">
                        {t('contacts.notes.add_note')}
                    </Button>
                </CreateFirst>
            )}

            <div className="w-full max-w-[700px]">
                <div
                    className="space-y-4 mt-4"
                >
                    {notes?.map(note => (
                        <div key={note.uuid} className="border rounded-sm">
                            <div className="flex justify-between items-center">
                                <p
                                    className="text-sm opacity-50 mb-2 p-4"
                                >
                                    <DateRenderer date={note.createdAt}/>
                                </p>

                                <Button
                                    variant="link"
                                    onClick={() => {
                                        deleteNote({
                                            uuid: contact.uuid,
                                            noteUuid: note.uuid
                                        })
                                    }}>
                                    <XIcon
                                        className="size-6 text-foreground opacity-50"
                                    />
                                </Button>
                            </div>

                            <Markdown className="p-4 pt-0">{note.note}</Markdown>

                            {note.files && (
                                <div
                                    className={clsx([
                                        "grid",
                                        "grid-cols-5",
                                        "gap-2",
                                        "px-4 pb-4"
                                    ])}
                                >
                                    {note.files.map((file, index) => {
                                        return (
                                            <a
                                                target="_blank"
                                                href={file.url}
                                                className="w-full aspect-square relative" rel="noreferrer">
                                                <img
                                                    key={index}
                                                    src={file.thumbnail_url}
                                                    alt=""
                                                    className="object-cover rounded-sm w-full h-full"
                                                />
                                            </a>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <Dialog
                open={showNewNote}
                onOpenChange={setShowNewNote}
            >
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>
                            {t('contacts.notes.new_note')}
                        </DialogTitle>
                    </DialogHeader>

                    <form
                        onSubmit={handleSubmit}
                        className="space-y-4"
                    >
                        <WysiwygEditor value={newNote} onChange={setNewNote} outline={true} disabled={isLoading}/>

                        {noteFiles.length > 0 && (
                            <div
                                className={clsx([
                                    "grid",
                                    "grid-cols-5",
                                    "gap-2",
                                ])}
                            >
                                {noteFiles.map((image, index) => {
                                    return (
                                        <div
                                            className="w-full aspect-square relative">
                                            <img
                                                key={index}
                                                src={URL.createObjectURL(image)}
                                                alt=""
                                                className="object-cover rounded-sm w-full h-full"
                                            />
                                            <Button
                                                onClick={() => {
                                                    setNoteFiles((prev) => {
                                                        return prev.filter((_, i) => i !== index)
                                                    })
                                                }}
                                                variant="outline"
                                                className="absolute top-1 right-1 !size-6 !rounded-full !p-0"
                                            >
                                                <X
                                                    className="size-4"
                                                />
                                            </Button>
                                        </div>
                                    )
                                })}
                            </div>
                        )}

                        <DialogFooter>
                            <UploadButton
                                onUpload={setNoteFiles}
                                disabled={isLoading} variant="ghost"
                            />

                            <Button type="submit" onClick={handleSubmit} variant="outline"
                                    disabled={isLoading || !newNote}>
                                {t('contacts.notes.submit_note')}
                            </Button>
                        </DialogFooter>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ContactNotes;