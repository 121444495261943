import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import {useHeaderContainerStore} from "@/components/layout/header/HeaderContainer";
import React, {useCallback, useEffect} from "react";
import {usePostQuery, usePostsQuery} from "@/api/writer";
import UserPost from "@/modules/feed/components/UserPost";
import {UserPost as UserPostType} from "@/api/writer.types";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "@/components/ui/tabs";
import {HorizontalScroller} from "@/components/elements/HorizontalScroller";
import PostItemSkeleton from "@/modules/feed/components/PostFeed/PostItemSkeleton";
import dayjs from "dayjs";
import {useNavigate, useParams} from "react-router";
import {Dialog, DialogContent, DialogHeader, DialogTitle} from "@/components/ui/dialog";
import {ScrollArea} from "@/components/ui/scroll-area";
import {useWriterDialogStore} from "@/modules/feed/components/PostWriterDialog";
import {Button} from "@/components/ui/button";

const MyPostsPage = () => {
    const {t} = useTranslation();

    const {
        setCrumbs,
    } = useHeaderContainerStore((state) => state) as any;

    const {uuid} = useParams();
    const hasSinglePostItem = !!uuid;

    const {data: singlePostItem, isLoading: singlePostItemLoading} =
        usePostQuery({
            uuid: uuid ?? ''
        }, {
            skip: !uuid
        });

    const navigate = useNavigate();

    const backToFeed = useCallback(() => navigate('/feed/posts'), [navigate]);
    const {setOpenState: setWriterOpenState} = useWriterDialogStore((state) => state) as any;

    useEffect(() => {
        if (hasSinglePostItem && !singlePostItemLoading && !singlePostItem) {
            backToFeed();
        }
    }, [hasSinglePostItem, singlePostItemLoading, singlePostItem, backToFeed]);

    useEffect(() => {
        setCrumbs([{
            label: t('feed.page_title'),
            to: "/feed"
        }, {
            label: t('feed.my_posts.page_title'),
            to: "/feed/my-posts"
        }]);
    }, [t, setCrumbs]);

    const {data: scheduledPosts, isLoading: scheduledLoading} = usePostsQuery({
        page: 1,
        status: "scheduled"
    });

    const {data: draftPosts, isLoading: draftLoading} = usePostsQuery({
        page: 1,
        status: "draft"
    });

    const {data: publishedPosts, isLoading: publishedLoading} = usePostsQuery({
        page: 1,
        status: "published"
    });

    const globalLoading = scheduledLoading || draftLoading || publishedLoading;

    // group by scheduled date
    const groupedScheduledPosts = scheduledPosts?.data.reduce((acc, post) => {
        const date = dayjs(post.scheduled_at).format('LL');
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(post);
        return acc;
    }, {} as Record<string, UserPostType[]>) ?? {}

    return (
        <div>
            <Helmet>
                <title>{t('feed.my_posts.page_title')}</title>
            </Helmet>

            <div className="p-4">

                <Tabs
                    defaultValue={scheduledPosts?.data && scheduledPosts?.data?.length > 0 ? 'scheduled' : 'draft'}
                    className="w-full"
                >
                    <div className="flex items-center justify-between">
                        <TabsList>
                            <TabsTrigger
                                value="draft"
                                disabled={draftPosts?.data?.length === 0}
                            >
                                {t('feed.my_posts.tabs.draft')}
                            </TabsTrigger>
                            <TabsTrigger
                                value="scheduled"
                                disabled={scheduledPosts?.data?.length === 0}
                            >
                                {t('feed.my_posts.tabs.scheduled')}
                            </TabsTrigger>
                            <TabsTrigger
                                value="published"
                                disabled={publishedPosts?.data?.length === 0}
                            >
                                {t('feed.my_posts.tabs.published')}
                            </TabsTrigger>
                        </TabsList>

                        <div>
                            <Button
                                onClick={() => setWriterOpenState(true)}
                            >
                                {t('feed.my_posts.write_post')}
                            </Button>
                        </div>
                    </div>

                    <TabsContent value="scheduled">
                        {globalLoading
                            ? <PostItemSkeleton/>
                            : Object.keys(groupedScheduledPosts)?.map(date => (
                                <div key={date}>
                                    <h2 className="text-lg font-bold mt-4">{date}</h2>
                                    <HorizontalScroller>
                                        {groupedScheduledPosts[date].map((post) => (
                                            <UserPost
                                                key={post.uuid}
                                                post={post}
                                            />
                                        ))}
                                    </HorizontalScroller>
                                </div>
                            ))
                        }
                    </TabsContent>
                    <TabsContent value="published">
                        {globalLoading
                            ? <PostItemSkeleton/>
                            : <div>
                                {publishedPosts?.data?.map((post) => (
                                        <UserPost
                                            key={post.uuid}
                                            post={post}
                                        />
                                    )
                                )}
                            </div>
                        }
                    </TabsContent>
                    <TabsContent value="draft">
                        {globalLoading
                            ? <PostItemSkeleton/>
                            : <div className="space-y-4">
                                {draftPosts?.data?.map((post) => (
                                        <UserPost
                                            key={post.uuid}
                                            post={post}
                                        />
                                    )
                                )}
                            </div>
                        }
                    </TabsContent>
                </Tabs>
            </div>

            {hasSinglePostItem &&
                <Dialog
                    open={hasSinglePostItem}
                    onOpenChange={(open) => {
                        if (!open) backToFeed()
                    }}
                >
                    <DialogContent className="sm:max-w-[620px]">
                        <DialogHeader>
                            <DialogTitle>
                                {t('feed.single_post.dialog_title')}
                            </DialogTitle>
                        </DialogHeader>

                        <ScrollArea
                            className="max-h-[86vh]"
                        >
                            {singlePostItemLoading ? (
                                <PostItemSkeleton/>
                            ) : singlePostItem ?
                                <UserPost post={singlePostItem}/> : null
                            }
                        </ScrollArea>
                    </DialogContent>
                </Dialog>
            }
        </div>
    )
}

export default MyPostsPage