import {Contact, ContactActivity as ContactActivityType} from "@/api/contact.types";
import {useGetContactActivitiesQuery} from "@/api/contact";
import ContactActivityItem from "@/modules/contacts/components/ContactActivityItem";
import {useCallback, useEffect, useRef, useState} from "react";
import Loader from "@/components/elements/Loader";
import ContactActivityItemSkeleton from "@/modules/contacts/components/ContactActivityItemSkeleton";
import CreateFirst from "@/components/elements/CreateFirst";
import {useTranslation} from "react-i18next";

type ContactActivityProps = {
    contact: Contact
}

const ContactActivity = ({contact}: ContactActivityProps) => {
    const bottomRef = useRef<HTMLDivElement>(null);
    const [data, setData] = useState<ContactActivityType[]>([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const {t} = useTranslation();

    const {refetch, data: currentPageData, isLoading} = useGetContactActivitiesQuery({
        uuid: contact.uuid,
        page
    }, {
        skip: !contact.uuid
    });

    useEffect(() => {
        setData([])
        setPage(1)
        setHasMore(true)
    }, [contact]);

    useEffect(() => {
        if (currentPageData) {
            setData(prev => [...prev, ...currentPageData.data.filter(item => {
                return !prev.find(i => (i.action + i.tracked_at) === (item.action + item.tracked_at))
            })])
            if (currentPageData.meta.last_page === currentPageData.meta.current_page) {
                setHasMore(false)
                if (page === 1) setTimeout(() => {
                    refetch()
                }, 5000);
            }
        }
    }, [currentPageData, page, refetch])

    const loadNextPage = useCallback(() => {
        if (currentPageData && hasMore) {
            setPage(page + 1)
            setPage(currentPageData.meta.current_page + 1)
            setHasMore(currentPageData.meta.last_page > currentPageData.meta.current_page)
        }
    }, [currentPageData, hasMore, page])

    const onScroll = useCallback(() => {
        if (bottomRef.current) {
            if (bottomRef.current.getBoundingClientRect().bottom <= (window.innerHeight * 5)) {
                loadNextPage()
            }
        }
    }, [loadNextPage])

    useEffect(() => {
        window.addEventListener('scroll', onScroll)

        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, [onScroll])


    if (isLoading) return (

        <ol className="ml-5 relative border-s border-muted dark:border-muted">
            <ContactActivityItemSkeleton/>
            <ContactActivityItemSkeleton/>
            <ContactActivityItemSkeleton/>
        </ol>
    );

    if (!data.length) return (
        <CreateFirst
            title={t('contacts.activity.no_activity_title')}
            description={t('contacts.activity.no_activity_description')}
        />
    );

    return (
        <>
            <ol className="ml-5 relative border-s border-muted dark:border-muted">
                {data?.map((activity, index) => (
                    <ContactActivityItem key={index} activity={activity}/>
                ))}
            </ol>

            <div ref={bottomRef}>
                {hasMore && <div className="flex justify-center align-center p-8">
                    <Loader/>
                </div>
                }
            </div>
        </>
    )
}

export default ContactActivity;