import {useEffect, useRef} from "react";
import {clsx} from "clsx";

type VideoPlayerProps = {
    playOnVisible?: boolean
    videoUrl: {
        mp4?: string
    },
    posterUrl: string;
    className?: string;
}
 
const VideoPlayer = ({playOnVisible, videoUrl, posterUrl, className}: VideoPlayerProps) => {
    const player = useRef<HTMLVideoElement>(null)

    const checkVisible = () => {
        if (player.current) {
            const rect = player.current.getBoundingClientRect()
            const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
            const isVisible = !(rect.bottom < 0 || rect.top - viewHeight >= 0)
            if (isVisible) {
                player.current.play()
            } else {
                player.current.pause()
            }
        }
    }

    useEffect(() => {
        if (playOnVisible) {
            window.addEventListener('scroll', checkVisible)
        }
        return () => {
            window.removeEventListener('scroll', checkVisible)
        }
    }, [playOnVisible]);

    return (
        <video
            ref={player}
            poster={posterUrl}
            muted={playOnVisible}
            controls={false}
            playsInline={true}
            loop={true}
            className={clsx(className, ['w-full'])}
        >
            {videoUrl.mp4 &&
                <source src={videoUrl.mp4} type="video/mp4"/>
            }
        </video>
    )
}

export default VideoPlayer