import {create} from "zustand";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle
} from "@/components/ui/alert";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {Checkbox} from "@/components/ui/checkbox";

type AlertOption = {
    name: string;
    label: string;
    description?: string;
    onChange?: (checked: boolean) => void;
}
type AlertType = {
    message: string;
    title: string;
    action: () => void;
    cancellable?: boolean;
    options?: AlertOption[]
}

const useUiAlertStore = create((set) => ({
    alerts: [],
    addAlert: (alert: AlertType) => set((state: any) => ({alerts: [...state.alerts, alert]})),
    removeAlert: (alert: AlertType) => set((state: any) => ({alerts: state.alerts.filter((a: AlertType) => a !== alert)}))
}))

export const useUiAlert = () => {
    const {addAlert} = useUiAlertStore((state: any) => state);

    return {
        alert: addAlert
    };
}

export const UiAlert = () => {
    const {t} = useTranslation();

    const {alerts, removeAlert} = useUiAlertStore((state: any) => state);

    const [alert, setAlert] = useState<AlertType | null>(null);
    const [hasAlert, setHasAlert] = useState(false);

    useEffect(() => {
        if (alerts[0]) {
            setAlert(alerts[0])
            setHasAlert(true)
        } else {
            setHasAlert(false)
        }
    }, [alerts])

    return (
        <AlertDialog
            open={hasAlert}
            onOpenChange={(open) => {
                if (!open) {
                    setHasAlert(false)
                    setTimeout(() => removeAlert(alert), 2000)
                }
            }}
        >
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>
                        {alert?.title}
                    </AlertDialogTitle>
                    <AlertDialogDescription>
                        {alert?.message}
                    </AlertDialogDescription>
                </AlertDialogHeader>

                {alert?.options && <div className='space-y-2'>
                    {alert.options.map((option, index) => (
                        <div key={index} className='flex items-start space-x-2'>
                            <Checkbox
                                onCheckedChange={option.onChange}
                                id={option.name}
                                className='mt-1'
                            />
                            <label
                                htmlFor={option.name}
                            >
                                <p>{option.label}</p>
                                {option.description && <p className='text-gray-500 text-sm'>{option.description}</p>}
                            </label>
                        </div>
                    ))}
                </div>}

                <AlertDialogFooter>
                    {alert?.cancellable &&
                        <AlertDialogCancel>
                            {t('common.cancel')}
                        </AlertDialogCancel>
                    }
                    <AlertDialogAction
                        onClick={() => {
                            alert?.action()
                            removeAlert(alert)
                        }}
                    >
                        {t('common.continue')}
                    </AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}