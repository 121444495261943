import ServiceCard from "@/modules/user/components/ServiceCard";
import React from "react";
import {useGetUserServicesQuery} from "@/api/user";

const UserServices = () => {
    const {data: services, isLoading} = useGetUserServicesQuery();

    if (isLoading) {
        return <div>Loading...</div>
    }

    return (
        <div className="space-y-4">
            {services && services.map((service) => (
                <ServiceCard service={service} key={service.name}/>
            ))}
        </div>
    )
}

export default UserServices;