import {Sheet, SheetContent} from "@/components/ui/sheet"
import {create} from "zustand";
import ContactPage from "@/modules/contacts/pages/ContactPage";
import HeaderContainer from "@/components/layout/header/HeaderContainer";

export const useContactSheet = create((set) => ({
    isOpen: false,
    setIsOpen: (isOpen: boolean) => set({isOpen}),
    contactUuid: '',
    setContactUuid: (contactUuid: string, open = true) => set({contactUuid, isOpen: open})
}))

export const ContactSheet = () => {
    const {isOpen, setIsOpen, contactUuid} = useContactSheet((state) => state) as any;

    return (
        <Sheet
            open={isOpen}
            onOpenChange={setIsOpen}
        >
            <SheetContent
                className="!w-[700px] !max-w-[80vw] !p-0 data-[state=close]:!duration-200 data-[state=open]:!duration-200"
            >
                <HeaderContainer></HeaderContainer>
                <div
                    className="h-[calc(100vh-4rem)] overflow-y-auto"
                >
                    {contactUuid && <ContactPage forceContactUuid={contactUuid}/>}
                </div>
            </SheetContent>
        </Sheet>
    )
}