import {FlameKindling} from "lucide-react";

const Loader = ({className}: {
    className?: string
}) => {
    return (
        <div>
            <FlameKindling className="size-8 opacity-10 animate-pulse"/>
        </div>
    )
}

export default Loader