import {Field} from "@/api/dataset.types";
import {useTranslation} from "react-i18next";
import {cn} from "@/lib/utils";
import ObjectAvatar from "@/components/elements/ObjectAvatar";
import {Button} from "@/components/ui/button";
import {
    ArrowDownNarrowWide,
    ArrowDownUp,
    ArrowUpNarrowWide,
    Filter,
    GripVertical,
    PanelLeftOpen,
    Search
} from "lucide-react";
import ScoreChip from "@/modules/contacts/components/ScoreChip";
import {Contact} from "@/api/contact.types";
import ListChip from "@/modules/contacts/components/ListChip";
import {EditableTextValue} from "@/components/data/EditableTextValue";
import {useEffect, useMemo, useState} from "react";
import ChipElement from "@/components/elements/ChipElement";
import slugify from "slugify";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select";
import dayjs from "dayjs";
import DateRenderer from "@/components/elements/DateRenderer";

type NotableRow = Record<string, any>;

type NotableProps = {
    fields: Field[],
    data: NotableRow[],
    fieldsLoading: boolean,
    dataLoading: boolean
    draggable?: boolean
    navigateTo?: (uuid: string) => void
}

const Notable = ({fields, data, fieldsLoading, draggable, dataLoading, navigateTo}: NotableProps) => {
    const {t} = useTranslation();

    // Force one line
    const cellClassnames = 'truncate py-1.5 px-3 text-sm border-b border-r text-left last:border-r-0 font-normal focus-within:bg-muted'
    const headerCellClassnames = 'text-foreground/50 pr-1.5'
    const fieldClassnames = 'flex items-center justify-between [&>*:first-child]:flex-grow w-full relative'
    const draggableAreaClassnames = 'border-r-0 px-0'

    const [sortField, setSortField] = useState<Field | null>(null);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

    const [searchValues, setSearchValues] = useState<Record<string, string>>({});
    const [openSearchAndFilters, setOpenSearchAndFilters] = useState(false);

    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setOpenSearchAndFilters(false);
                setSearchValues({});
            }
        };
        window.addEventListener('keydown', handleEsc);
        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);

    const filterValues = useMemo(() => {
        return fields.reduce((acc, field) => {
            if (field.filterable) {
                // @ts-ignore
                acc[field.name] = [...new Set((data ?? []).map((row) => row[field.name]).filter(v => v).sort())];
            }
            return acc;
        }, {} as Record<string, string[]>);
    }, [fields, data]);

    const filteredData = ([...(data ?? [])]).filter((row) => {
        return fields.every((field) => {
            if (!field.searchable) return true;
            if (!openSearchAndFilters) return true;

            return slugify((row[field.name] ?? '').toString().toLowerCase()).includes(slugify(searchValues[field.name]?.toLowerCase() ?? ''));
        });
    });

    const newContacts = filteredData.filter((row) => {
        return dayjs(row.created_at).isAfter(dayjs().subtract(1, 'day'))
    });

    const sortedData = filteredData
        .sort((a, b) => {
            return dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? -1 : 1;
        })
        .sort((a, b) => {
            if (!sortField) {
                if (newContacts.includes(a) && !newContacts.includes(b)) return -1;
                if (!newContacts.includes(a) && newContacts.includes(b)) return 1;

                return a.score < b.score ? 1 : -1;
            }

            if (sortOrder === 'asc') {
                return a[sortField.name] > b[sortField.name] ? 1 : -1;
            }

            return a[sortField.name] < b[sortField.name] ? 1 : -1;
        });

    if (fieldsLoading) {
        return <></>
    }

    return (
        <div className="overflow-x-auto">
            <table>
                <thead>
                <tr
                    className="border-t"
                >
                    {draggable &&
                        <th className={cn(cellClassnames, headerCellClassnames, draggableAreaClassnames)}></th>}
                    {fields.map((field) => (
                        <th
                            key={field.name}
                            className={cn(cellClassnames, headerCellClassnames)}
                            style={{
                                width: ((field.size ?? 10) / 3) + 'em',
                                minWidth: ((field.size ?? 10) / 3) + 'em',
                                maxWidth: ((field.size ?? 10) / 3) + 'em'
                            }}
                        >
                            <div className="flex items-center gap-2 justify-between">
                                <span>
                                {
                                    field.hideLabel ? '' :
                                        field.label ?? t('common.fields.' + field.name)
                                }
                                </span>

                                <div className="flex gap-1">
                                    {field.filterable && (
                                        <Button
                                            variant="ghost"
                                            className="text-foreground !p-1 !h-6 bg-muted rounded-xs"
                                            onClick={() => {
                                                setOpenSearchAndFilters(true);
                                                setTimeout(() => {
                                                    (document.querySelector(`select[name="${field.name}_filter"]`) as any)?.click()
                                                }, 200)
                                            }}
                                        >
                                            <Filter
                                                className="size-4 opacity-50"
                                            />
                                        </Button>
                                    )}

                                    {field.searchable && (
                                        <Button
                                            variant="ghost"
                                            className="text-foreground !p-1 !h-6 bg-muted rounded-xs"
                                            onClick={() => {
                                                setOpenSearchAndFilters(true);
                                                setTimeout(() => {
                                                    (document.querySelector(`input[name="${field.name}_search"]`) as any)?.focus()
                                                }, 200)
                                            }}
                                        >
                                            <Search
                                                className="size-4 opacity-50"
                                            />
                                        </Button>
                                    )}

                                    {field.sortable && (
                                        <Button
                                            variant="ghost"
                                            className="text-foreground !p-1 !h-6 bg-muted rounded-xs"
                                            onClick={() => {
                                                if (sortField === field) {
                                                    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
                                                } else {
                                                    setSortField(field)
                                                    setSortOrder('asc')
                                                }
                                            }}
                                        >
                                            {sortField !== field &&
                                                <ArrowDownUp
                                                    className="size-4 opacity-50"
                                                />
                                            }
                                            {sortField === field && sortOrder === 'asc' &&
                                                <ArrowDownNarrowWide
                                                    className="size-4"
                                                />
                                            }
                                            {sortField === field && sortOrder === 'desc' &&
                                                <ArrowUpNarrowWide
                                                    className="size-4"
                                                />
                                            }
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {openSearchAndFilters && (
                    <tr>
                        {draggable &&
                            <td className={cn(cellClassnames, draggableAreaClassnames)}></td>}
                        {fields.map((field) => (
                            <td
                                key={field.name}
                                className={cn(cellClassnames)}
                            >
                                {field.searchable && (
                                    <div className={fieldClassnames}>
                                        <input
                                            type="text"
                                            value={searchValues[field.name] ?? ''}
                                            onChange={(e) => setSearchValues({
                                                ...searchValues,
                                                [field.name]: e.target.value
                                            })}
                                            placeholder={t('common.search')}
                                            className="w-full outline-none bg-transparent"
                                            name={field.name + '_search'}
                                        />
                                    </div>
                                )}

                                {field.filterable && (
                                    <div>
                                        <Select
                                            onValueChange={(value) => {
                                                setSearchValues({
                                                    ...searchValues,
                                                    [field.name]: value
                                                })
                                            }}
                                        >
                                            <SelectTrigger
                                                className="w-full outline-none bg-transparent h-auto ring-0 focus:ring-0 border-0 px-0 focus:border-none"
                                                name={field.name + '_filter'}
                                            >
                                                <SelectValue placeholder={t('common.filter')}/>
                                            </SelectTrigger>
                                            <SelectContent>
                                                {filterValues[field.name]?.map((value) => (
                                                    <SelectItem
                                                        key={value}
                                                        value={value}
                                                    >
                                                        {value}
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </div>
                                )}
                            </td>
                        ))}
                    </tr>

                )}
                {sortedData.map((row) => (
                    <tr
                        key={row.uuid}
                        className="group"
                        draggable={draggable}
                        onDragStart={draggable ? (e) => {
                            e.dataTransfer.setData('text/plain', row.uuid)
                        } : undefined}
                    >
                        {draggable && <td className={cn(cellClassnames, draggableAreaClassnames)}>
                            <div
                                className="cursor-move"
                            >
                                <GripVertical
                                    className="size-4 opacity-10 group-hover:opacity-50"
                                />
                            </div>
                        </td>}
                        {fields.map((field) => (
                            <td key={field.name} className={cn(cellClassnames)}>
                                <div className={fieldClassnames}>
                                    <NotableField field={field} row={row}/>

                                    {field.isMain && (
                                        <Button
                                            onClick={navigateTo && row.uuid ? () => navigateTo(row.uuid) : undefined}
                                            variant="ghost"
                                            className="ml-4 text-foreground uppercase font-semibold flex gap-2 !p-1 !h-6 bg-muted rounded-xs opacity-0 group-hover:opacity-50 absolute right-0"
                                        >
                                            <PanelLeftOpen className="size-4"/>
                                            {t('common.open')}
                                        </Button>
                                    )}
                                </div>
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

const NotableField = ({field, row}: { field: Field, row: NotableRow }) => {
    if (field.type === 'image')
        return <ObjectAvatar
            className="size-6 text-xs text-foreground/50"
            object={row}
        />

    if (field.type === 'score')
        return <ScoreChip
            contact={row as Contact}
        />

    if (field.type === 'relation') {
        if (field.model === 'list') {
            return <ListChip
                uuid={(row[field.name] as any)?.uuid}
                contactUuid={row.uuid}
            />
        }

        return <p>Unknown relation model {field.model}</p>
    }

    if (field.type === 'string') {
        if (field.editable) {
            return <EditableTextValue
                name={field.name}
                item={row as Contact}
                model={'contact'}
                type={'text'}
                showPencil={false}
            />
        }
        return <p>{row[field.name]?.toString()}</p>
    }
    if (field.type === 'tags') {
        return <div className="flex gap-2">
            {(row[field.name] ?? [])?.slice(0, 5)?.map((tag: string) => (
                <ChipElement
                    label={tag}
                    key={tag}
                    variant="muted"
                />
            ))}
        </div>
    }
    if (field.type === 'date') {
        return <DateRenderer date={row[field.name]}/>
    }

    return <p>Unknown field type {field.type}</p>
}

export default Notable;