import {useGetUserPostsQuery} from "@/api/feed";
import Loader from "@/components/elements/Loader";
import {useMemo, useState} from "react";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent} from "@/components/ui/chart";
import {Bar, BarChart, CartesianGrid, XAxis} from "recharts"

const maxItems = 12;
const granularityOptions = ['day', 'week', 'month', 'year'];

const chartConfig = {
    posts: {
        label: "Posts",
        color: "hsl(var(--chart-1))",
    },
    likes: {
        label: "Likes",
        color: "hsl(var(--chart-2))",
    },
    // impressions: {
    //     label: "Impressions",
    //     color: "hsl(var(--primary))",
    // },
    comments: {
        label: "Comments",
        color: "hsl(var(--chart-3))",
    },
    shares: {
        label: "Shares",
        color: "hsl(var(--chart-4))",
    },
} satisfies ChartConfig

const stackGroups = [
    ['likes', 'shares', 'comments']
]

const UserPostInteractions = () => {
    const {t} = useTranslation();

    const [granularity, setGranularity] = useState<string>('month');

    const {data, isLoading} = useGetUserPostsQuery({})

    const dates = (data?.posts ?? []).map((post: any) => ({
        date: post.postedAt,
        likes: post.likes,
        impressions: post.impressions,
        shares: post.shares,
        comments: post.comments
    }));

    const chartData = useMemo(() => {
        const result: any = {};

        dates
            .sort((a: any, b: any) => dayjs(a.date).diff(dayjs(b.date)))
            .forEach((d: any) => {
                const date = dayjs(d.date);
                let key = '';
                switch (granularity) {
                    case 'day':
                        key = date.format('LL');
                        break;
                    case 'week':
                        key = 'Week ' + date.startOf('week')
                            .format('ww, YYYY');
                        break;
                    case 'month':
                        key = date
                            .startOf('month')
                            .format('MMM YYYY');
                        break;
                    case 'year':
                        key = date
                            .startOf('year')
                            .format('YYYY');
                        break;
                }

                if (!result[key]) {
                    result[key] = {
                        period: key,
                        likes: 0,
                        impressions: 0,
                        shares: 0,
                        comments: 0,
                        posts: 0,
                        popularityScore: 0,
                    }
                }

                result[key].likes += (d.likes ?? 0);
                result[key].impressions += (d.impressions ?? 0);
                result[key].shares += (d.shares ?? 0);
                result[key].comments += (d.comments ?? 0);
                result[key].posts++;
                result[key].popularityScore += (d.popularityScore ?? 0);
            });

        return Object.values(result).slice(-maxItems);
    }, [dates, granularity]);

    if (isLoading) {
        return <Loader/>
    }

    return (
        <>
            <ChartContainer config={chartConfig}
                            className="h-[220px] w-full relative"
            >
                <BarChart accessibilityLayer data={chartData}>
                    <CartesianGrid vertical={false}/>
                    <XAxis
                        dataKey="period"
                        tickLine={false}
                        tickMargin={10}
                        axisLine={false}
                        //tickFormatter={(value) => value.slice(0, 3)}
                    />
                    <ChartTooltip
                        cursor={false}
                        content={<ChartTooltipContent indicator="dashed"/>}
                    />

                    {Object.keys(chartConfig).map((key: any) => (
                        <Bar
                            key={key}
                            dataKey={key}
                            fill={(((chartConfig as any)[key] as any)?.color)}
                            radius={0}
                            stackId={stackGroups.find((group) => group.includes(key))?.join('-')}
                        />
                    ))}
                </BarChart>
            </ChartContainer>

            <div className="absolute top-0 right-0">
                <Select
                    value={granularity}
                    onValueChange={setGranularity}
                >
                    <SelectTrigger className="w-[120px] text-sm focus:ring-0">
                        <SelectValue placeholder={t('common.week')}/>
                    </SelectTrigger>
                    <SelectContent>
                        {granularityOptions.map((g) => (
                            <SelectItem key={g} value={g}>
                                {t(`common.${g}`)}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>
        </>
    )
}

export default UserPostInteractions;