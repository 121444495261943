import {clsx} from "clsx";

type LogoProps = {
    className?: string
}

const Logo = ({className}: LogoProps) => {
    const classes = clsx(
        "border", "border-solid", "border-current",
        className
    )
    return (
        <svg className={classes}
             viewBox="0 0 116.65625 120.58984"
             preserveAspectRatio="xMidYMid"
        >
            <g
                fill="currentColor"
                transform="translate(-137.51172,-124.53125)">
                <g
                    transform="translate(148.61791,227.81329)"
                    id="g197">
                    <g
                        id="g195">
                        <path
                            d="M 9.6875,0 V -82.890625 H 38.296875 V -70.9375 h 0.3125 c 0.21875,-0.425781 0.566406,-0.960938 1.046875,-1.609375 0.488281,-0.644531 1.539062,-1.773437 3.15625,-3.390625 1.613281,-1.613281 3.335938,-3.066406 5.171875,-4.359375 1.832031,-1.300781 4.203125,-2.460937 7.109375,-3.484375 2.90625,-1.019531 5.867188,-1.53125 8.890625,-1.53125 3.125,0 6.191406,0.433594 9.203125,1.296875 3.019531,0.855469 5.226562,1.714844 6.625,2.578125 l 2.265625,1.296875 L 70.125,-55.90625 c -3.554688,-3.019531 -8.507812,-4.53125 -14.859375,-4.53125 -3.449219,0 -6.414063,0.757812 -8.890625,2.265625 -2.480469,1.511719 -4.230469,3.34375 -5.25,5.5 -1.023438,2.15625 -1.75,3.992187 -2.1875,5.5 -0.429688,1.5 -0.640625,2.683594 -0.640625,3.546875 V 0 Z m 0,0"
                            id="path193"/>
                    </g>
                </g>
            </g>
            <g
                fill="currentColor"
                fillOpacity="1"
                id="g211"
                transform="translate(-137.51172,-124.53125)">
                <g
                    transform="translate(191.72108,227.81329)"
                    id="g209">
                    <g
                        id="g207">
                        <path
                            d="m 28.109375,-34.578125 c 5.175781,0 9.566406,1.808594 13.171875,5.421875 3.613281,3.605469 5.421875,7.992188 5.421875,13.15625 0,5.0625 -1.808594,9.402344 -5.421875,13.015625 -3.605469,3.601563 -7.996094,5.40625 -13.171875,5.40625 -5.0625,0 -9.402344,-1.804687 -13.015625,-5.40625 C 11.488281,-6.597656 9.6875,-10.9375 9.6875,-16 c 0,-5.164062 1.800781,-9.550781 5.40625,-13.15625 3.613281,-3.613281 7.953125,-5.421875 13.015625,-5.421875 z m 0,0"
                            id="path205"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default Logo