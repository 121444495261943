import {Label} from "@/components/ui/label";
import {clsx} from "clsx";
import {ButtonProps, buttonVariants} from "@/components/ui/button";
import {ImageIcon} from "lucide-react";
import {Input} from "@/components/ui/input";

type UploadButtonProps = {
    onUpload: (files: File[]) => void;
    variant?: ButtonProps['variant'];
    disabled?: boolean;
}

const UploadButton = ({
                          onUpload,
                          variant,
                          disabled
                      }: UploadButtonProps) => {
    return (<>
            <Label htmlFor="upload-component-files"
                   className={clsx(buttonVariants({
                       variant: variant,
                   }), 'cursor-pointer')}
            >
                <ImageIcon className="size-6"/>
            </Label>
            <Input
                id="upload-component-files"
                type="file"
                className="hidden"
                multiple
                accept="image/*"
                disabled={disabled}
                onChange={(e) => {
                    onUpload(Array.from(e.target.files ?? []));
                }}
            />
        </>
    )
}

export default UploadButton;