import {usePatchListMutation, usePatchListPipelineStageMutation} from "@/api/list";
import {useSetUserSettingsMutation} from "@/api/user";

const useAnyModel = (model: string, item: any) => {
    const [patchPipelineStage] = usePatchListPipelineStageMutation()
    const [patchList] = usePatchListMutation()

    const [setUserSetting] = useSetUserSettingsMutation()

    const patch = (field: string, value: any, params?: Record<string, any>) => {
        if (model === 'user_setting') {
            setUserSetting({
                key: field,
                value: value
            })
        } else {
            if (model === 'pipeline_stage') {
                patchPipelineStage({
                    ...(params ?? {}),
                    uuid: item.uuid,
                    [field]: value
                })
            }

            if (model === 'list') {
                patchList({
                    uuid: item.uuid,
                    [field]: value
                })
            }
        }
    }

    return {patch}
}

export default useAnyModel