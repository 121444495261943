import {useTranslation} from "react-i18next";
import {z} from "zod"
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import {Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Button} from "@/components/ui/button";
import {Textarea} from "@/components/ui/textarea";
import useFullLists from "@/hooks/useFullLists";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select";
import {useCreateAutomationMutation} from "@/api/automation";
import {useNavigate} from "react-router";

const CreateAutomationForm = () => {
    const {t} = useTranslation();

    const formSchema = z.object({
        name: z.string().min(3),
        description: z.string().optional(),
        listUuid: z.string().uuid()
    });

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: undefined,
            description: undefined,
            listUuid: undefined,
        },
    })

    const {content: lists, isLoading: listsLoading} = useFullLists();

    const [createAutomation, {isLoading}] = useCreateAutomationMutation();

    const navigate = useNavigate();

    function onSubmit(values: z.infer<typeof formSchema>) {
        if (isLoading) return;

        createAutomation(values).then(response => {
            navigate(`/automation/${(response as any).data?.uuid ?? ''}`)
        })
    }

    const generalLoading = listsLoading || isLoading;

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                <FormField
                    control={form.control}
                    name="name"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>
                                {t('automation.creation_form.name_label')}
                            </FormLabel>
                            <FormControl>
                                <Input
                                    disabled={generalLoading}
                                    {...field} />
                            </FormControl>
                            <FormDescription>
                                {t('automation.creation_form.name_description')}
                            </FormDescription>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="description"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>
                                {t('automation.creation_form.description_label')}
                            </FormLabel>
                            <FormControl>
                                <Textarea
                                    disabled={generalLoading}
                                    {...field} />
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
                <FormField
                    control={form.control}
                    name="listUuid"
                    render={({field}) => (
                        <FormItem>
                            <FormLabel>
                                {t('automation.creation_form.list_label')}
                            </FormLabel>
                            <FormControl>
                                <Select
                                    disabled={generalLoading}

                                    {...field}
                                    onValueChange={(value) => form.setValue('listUuid', value)}
                                >
                                    <SelectTrigger className="w-[180px]">
                                        <SelectValue placeholder={t('automation.creation_form.list_placeholder')}/>
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            {lists.map((list) => {
                                                return (
                                                    <SelectItem key={list.uuid} value={list.uuid}>
                                                        {list.name}
                                                    </SelectItem>
                                                )
                                            })}
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
                <Button type="submit">
                    {t('automation.creation_form.create')}
                </Button>
            </form>
        </Form>
    );
}

export default CreateAutomationForm;