import {Skeleton} from "@/components/ui/skeleton";


const ContactActivityItemSkeleton = () => {
    return (
        <li className="mb-8 ms-10">
        <span
            className="absolute text-primary dark:text-white flex items-center justify-center size-10 p-2 bg-muted/50 rounded-full -start-5 ring-8 ring-background">
        </span>
            <h3 className="flex items-center mb-1 text-lg font-semibold">
                <Skeleton className="w-24 h-4"/>
            </h3>
            <div
                className="block mb-2 text-sm font-normal leading-none opacity-50">

                <Skeleton className="w-10 h-4"/>
            </div>
            <div className="mb-4 text-base font-normal">
                <Skeleton className="w-32 h-4"/>
            </div>
        </li>
    );
};

export default ContactActivityItemSkeleton;