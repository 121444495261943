import {useGetUserStatisticsQuery} from "@/api/user";
import {ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent} from "@/components/ui/chart";
import {Area, AreaChart, CartesianGrid, XAxis, YAxis} from "recharts";
import {useState} from "react";
import {Button} from "@/components/ui/button";
import dayjs from "dayjs";
import {cn} from "@/lib/utils";

const chartConfig = {
    totalFollowers: {
        label: "Followers",
        color: "hsl(var(--chart-4))",
    },
    dailyFollowers: {
        label: "New followers",
        color: "hsl(var(--chart-1))",
    },
    dailyLostFollowers: {
        label: "Lost followers",
        color: "hsl(var(--chart-2))",
    },
} satisfies ChartConfig

const FollowersChart = () => {
    const [type, setType] = useState<string>('daily');

    const {data, isLoading} = useGetUserStatisticsQuery();

    const chartData = (data ?? [])
        .map((stat: any) => ({
            ...stat,
            dailyFollowers: stat.dailyFollowers > 0 ? stat.dailyFollowers : 0,
            dailyLostFollowers: stat.dailyFollowers < 0 ? stat.dailyFollowers : 0,
            period: dayjs(stat.date).format('LL')
        }))
        .slice(-16)

    if (isLoading) return <></>;

    return (
        <>
            <ChartContainer config={chartConfig}
                            className="h-[220px] w-full relative">
                <AreaChart
                    accessibilityLayer
                    data={chartData}
                    margin={{
                        left: 12,
                        right: 12,
                    }}
                >
                    <CartesianGrid vertical={false}/>
                    <XAxis
                        dataKey="period"
                        tickLine={false}
                        axisLine={false}
                        tickMargin={8}
                    />
                    <YAxis
                        axisLine={false}
                        tickLine={false}
                        tickMargin={8}
                        scale="auto"
                        interval={1}
                    />
                    <ChartTooltip
                        cursor={false}
                        content={<ChartTooltipContent indicator="dot"/>}
                    />
                    {type === 'daily' &&
                        <>
                            <Area
                                dataKey="dailyFollowers"
                                fill="hsl(var(--chart-1))"
                                fillOpacity={0.4}
                                stroke="hsl(var(--chart-1))"
                                type="linear"
                            />

                            <Area
                                dataKey="dailyLostFollowers"
                                fill="hsl(var(--chart-2))"
                                fillOpacity={0.4}
                                stroke="transparent"
                                type="linear"
                            />

                            {/*<Area*/}
                            {/*    dataKey="dailyProfileViews"*/}
                            {/*    type="natural"*/}
                            {/*    fill="hsl(var(--chart-2))"*/}
                            {/*    fillOpacity={0.4}*/}
                            {/*    stroke="hsl(var(--chart-2))"*/}
                            {/*/>*/}
                        </>
                    }
                    {type === 'total' &&
                        <>
                            <Area
                                dataKey="totalFollowers"
                                type="linear"
                                fill="hsl(var(--chart-4))"
                                fillOpacity={0.4}
                                stroke="hsl(var(--chart-4))"
                            />
                            {/*<Area*/}
                            {/*    dataKey="totalProfileViews"*/}
                            {/*    type="natural"*/}
                            {/*    fill="hsl(var(--chart-2))"*/}
                            {/*    fillOpacity={0.4}*/}
                            {/*    stroke="hsl(var(--chart-2))"*/}
                            {/*/>*/}
                        </>
                    }
                </AreaChart>
            </ChartContainer>

            <div className="flex justify-end absolute top-0 right-0">
                <Button
                    onClick={() => setType('total')}
                    size="sm"
                    className={cn("rounded-tr-none rounded-br-none !bg-background border-4 border-background text-foreground", {
                        '!bg-muted': type === 'total',
                    })}
                >
                    Total
                </Button>
                <Button
                    onClick={() => setType('daily')}
                    size="sm"
                    className={cn("rounded-tl-none rounded-bl-none !bg-background border-4 border-background text-foreground", {
                        '!bg-muted': type === 'daily',
                    })}
                >
                    Daily
                </Button>
            </div>
        </>
    );
}

export default FollowersChart;