import {useGetCalendarEventsQuery, usePatchCalendarEventMutation} from "@/api/calendar_event";
import {Checkbox} from "@/components/ui/checkbox";
import DateRenderer from "@/components/elements/DateRenderer";
import {cn} from "@/lib/utils";
import ObjectAvatarsStack from "@/components/elements/ObjectAvatarsStack";
import dayjs from "dayjs";

const UserReminders = () => {
    const {data, isLoading} = useGetCalendarEventsQuery({
        page: 1,
        type: 'reminder',
        sortByKey: 'due_date',
        sortByDirection: 'desc',
    });

    const [patchCalendarEvent, {isLoading: updateLoading}] = usePatchCalendarEventMutation();

    if (isLoading) {
        return <></>;
    }

    if (!data?.data?.length) {
        return <div className="text-center text-lg text-foreground/50">No reminders found</div>
    }

    return (
        <div className="w-full">
            {data?.data && data?.data.map((reminder) => (
                <div
                    key={reminder.uuid}
                    className={cn("flex gap-2 py-2 border-b last:border-b-0 w-full")}
                >
                    <div className="pt-[1px]">
                        <Checkbox
                            className="border-foreground data-[state=checked]:bg-foreground data-[state=checked]:text-background rounded-[3px]"
                            checked={reminder.completed}
                            onCheckedChange={(completed) => {
                                if (updateLoading) return;

                                patchCalendarEvent({
                                    uuid: reminder.uuid,
                                    reminder: {
                                        completed: !!completed
                                    },
                                });
                            }}
                        />
                    </div>

                    <div className="flex-grow">
                        <p className={cn("w-full", {
                            'opacity-50': reminder.completed,
                            'line-through': reminder.completed,
                            'text-destructive': !reminder.completed && dayjs(reminder.dueDate).isBefore(dayjs(), 'day'),
                        })}>
                            {reminder.name}
                        </p>

                        {!reminder.completed && (
                            <p className="text-xs opacity-50">{reminder.description}</p>
                        )}
                    </div>

                    {!reminder.completed && (
                        <p className="text-xs opacity-50 flex-shrink-0 pt-1">
                            <DateRenderer date={reminder.dueDate}/>
                        </p>
                    )}

                    <ObjectAvatarsStack objects={reminder.relatedContacts ?? []}/>
                </div>
            ))}
        </div>
    )
}

export default UserReminders;