import {List, ListQuery, Pipeline, PipelineQuery, PipelineStage, StageQuery} from "./list.types";
import {api} from "@/api/api";
import {ObjectQuery, PaginatedQuery, PaginatedResponse} from "@/api/api.types";

export const listApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getLists: builder.query<PaginatedResponse<List>, PaginatedQuery>({
            query: ({page}) => `lists?page=${page ?? 1}`,
            providesTags: ["Lists"],
        }),
        getList: builder.query<List, ObjectQuery>({
            query: ({uuid}) => `lists/${uuid}`,
            providesTags: (result, error, {uuid}) => ["Lists"],
        }),
        patchList: builder.mutation<List, ListQuery & { uuid: string }>({
            query: ({uuid, ...body}) => ({
                url: `lists/${uuid}`,
                method: "PATCH",
                body: body,
            }),
            invalidatesTags: (result, error, {uuid}) => ["Lists"],
        }),
        deleteList: builder.mutation<void, ObjectQuery & {
            deleteContacts?: boolean
        }>({
            query: ({uuid, deleteContacts}) => ({
                url: `lists/${uuid}?${deleteContacts === true ? 'deleteContacts=true' : ''}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, {uuid}) => ["Lists"],
        }),
        createList: builder.mutation<List, ListQuery>({
            query: (list) => ({
                url: `lists`,
                method: "POST",
                body: list,
            }),
            invalidatesTags: ["Lists"],
        }),
        getListPipeline: builder.query<Pipeline, ObjectQuery>({
            query: ({uuid}) => `lists/${uuid}/pipeline`,
            providesTags: (result, error, {uuid}) => ["Lists", "Pipelines"],
            transformResponse: (response: Pipeline) => {
                response.stages.sort((a, b) => a.order - b.order);
                return response;
            },
        }),
        patchListPipeline: builder.mutation<Pipeline, ObjectQuery & PipelineQuery>({
            query: ({uuid, ...params}) => ({
                url: `lists/${uuid}/pipeline`,
                method: "PATCH",
                body: {
                    ...params,
                },
            }),
            invalidatesTags: (result, error, {uuid}) => ["Pipelines"]
        }),
        patchListPipelineStage: builder.mutation<PipelineStage, ObjectQuery & StageQuery>({
            query: ({listUuid, uuid, ...params}) => ({
                url: `lists/${listUuid}/pipeline/stages/${uuid}`,
                method: "PATCH",
                body: {
                    ...params,
                },
            }),
            invalidatesTags: (result, error, {uuid}) => ["Pipelines"]
        }),
        createListPipelineStage: builder.mutation<PipelineStage, StageQuery & { insertBefore?: string }>({
            query: ({listUuid, ...params}) => ({
                url: `lists/${listUuid}/pipeline/stages`,
                method: "POST",
                body: {
                    ...params,
                },
            }),
            invalidatesTags: ["Pipelines"]
        }),
        removeListPipelineStage: builder.mutation<void, ObjectQuery & { listUuid: string }>({
            query: ({listUuid, uuid}) => ({
                url: `lists/${listUuid}/pipeline/stages/${uuid}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, {uuid}) => ["Pipelines", 'Contacts']
        }),
        setListPipelineStagesOrder: builder.mutation({
            query: ({uuid, stageUuids}) => ({
                url: `lists/${uuid}/pipeline/stages/order`,
                method: "PATCH",
                body: {
                    stageUuids,
                },
            }),
            invalidatesTags: (result, error, {uuid}) => ["Pipelines"]
        }),
        setListPipelineStageContacts: builder.mutation({
            query: ({uuid, stageUuid, contactUuids}) => ({
                url: `lists/${uuid}/pipeline/stages/${stageUuid}/contacts`,
                method: "PATCH",
                body: {
                    contactUuids,
                },
            }),
            invalidatesTags: (result, error, {uuid}) => ["Pipelines", "Contacts"]
        }),
        moveListContactToPipelineStage: builder.mutation({
            query: ({listUuid, contactUuid, stageUuid}) => ({
                url: `lists/${listUuid}/pipeline/stages/move-contact`,
                method: "POST",
                body: {
                    contactUuid,
                    stageUuid,
                },
            }),
            invalidatesTags: (result, error, {uuid}) => ["Pipelines", "Contacts"]
        }),
    }),
});

export const {
    useGetListsQuery,
    useGetListQuery,
    usePatchListMutation,
    useDeleteListMutation,
    useCreateListMutation,
    useGetListPipelineQuery,
    useSetListPipelineStagesOrderMutation,
    useSetListPipelineStageContactsMutation,
    usePatchListPipelineStageMutation,
    usePatchListPipelineMutation,
    useMoveListContactToPipelineStageMutation,
    useCreateListPipelineStageMutation,
    useRemoveListPipelineStageMutation,
} = listApi;
