import PostWriter from "@/modules/feed/components/PostWriter";
import {Dialog, DialogContent, DialogHeader, DialogTitle} from "@/components/ui/dialog";
import {useTranslation} from "react-i18next";
import {Textarea} from "@/components/ui/textarea";
import {create} from "zustand";

export const useWriterDialogStore = create((set) => ({
    isOpen: false,
    setOpenState: (isOpen: boolean) => set({isOpen}),
    initialMagicPrompt: '',
    setInitialMagicPrompt: (initialMagicPrompt: string) => set({initialMagicPrompt})
}))

export const useWriter = () => {
    const {setOpenState, setInitialMagicPrompt} = useWriterDialogStore((state) => state) as any;

    return {
        open: (initialMagicPrompt: string) => {
            setInitialMagicPrompt(initialMagicPrompt)
            setOpenState(true)
        },
        close: () => setOpenState(false)
    }
}

export const WriterDialog = () => {
    const {t} = useTranslation()
    const {isOpen, setOpenState} = useWriterDialogStore((state) => state) as any;

    return (<Dialog
        open={isOpen}
        onOpenChange={setOpenState}
    >
        <DialogContent className="sm:max-w-[620px]">
            <DialogHeader>
                <DialogTitle>
                    {t('feed.writer.dialog_title')}
                </DialogTitle>
            </DialogHeader>

            <PostWriter/>
        </DialogContent>
    </Dialog>)
}

const PostWriterDialog = () => {
    const {t} = useTranslation()
    const {setOpenState} = useWriterDialogStore((state) => state) as any;

    return <>
        <div
            onClick={(e: any) => {
                e?.stopPropagation()
                e?.preventDefault()
                setOpenState(true)
            }}
        >
            <Textarea
                placeholder={t('feed.writer.write_something')}
                className="w-full"
            />
        </div>
    </>
}

export default PostWriterDialog