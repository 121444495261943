import Loader from "@/components/elements/Loader";
import {useEffect} from "react";
import {useGetUserActionTokenMutation} from "@/api/user";

const Proxy = () => {
    const [getToken] = useGetUserActionTokenMutation()

    useEffect(() => {
        getToken().then(data => {
            const token = ((data as any)?.data?.token)

            const apiHost = process.env.REACT_APP_API_HOST

            const url = window.location.href
            const uri = url.split('/proxy/')[1].split('?')[0]
            let queryString = url.split('?')[1]
            if (token) {
                queryString += (queryString ? '&' : '') + '_at=' + encodeURIComponent(token)
            }

            setTimeout(() => {
                window.location.href = apiHost + '/' + uri + (queryString ? '?' + queryString : '')
            }, 500)
        });
        // eslint-disable-next-line
    }, []);

    return <div
        className={'flex items-center justify-center h-screen'}
    >
        <Loader/>
    </div>
}

export default Proxy;