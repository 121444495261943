import {clsx} from "clsx";

const Privacy = () => {
    return (
        <div
            className={clsx(([
                'p-4',
                'space-y-4',
                'max-w-2xl',
                'mx-auto',
                'w-full',
                '[&>h1]-text-2xl',
                '[&>h2]-text-lg',
                '[&>p]-text-sm'
            ]))}
        >
            <p><span data-style="font-weight: 400;">Your personal data is processed to meet one or several of the following purposes. Each purpose is associated with a legal basis, the list of which can be found below.</span>
            </p>
            <p>&nbsp;</p>
            <p><span data-style="font-weight: 400;">Based on our legitimate interest of offering you a safe, optimum, efficient, and personalized experience, we carry out processing operations for the following purposes :</span>
            </p>
            <p>&nbsp;</p>
            <ul>
                <li data-style="font-weight: 400;"><span data-style="font-weight: 400;">To solve any problems and improve the use of our sites and services.</span>
                </li>
                <li data-style="font-weight: 400;"><span data-style="font-weight: 400;">To personalize, assess, and improve our services, content, and materials.</span>
                </li>
                <li data-style="font-weight: 400;"><span data-style="font-weight: 400;">To analyze the volume and history of your use of our services.</span>
                </li>
                <li data-style="font-weight: 400;"><span data-style="font-weight: 400;">To inform you about our services as well as our partners&rsquo; services and/or promotional offers.</span>
                </li>
                <li data-style="font-weight: 400;"><span data-style="font-weight: 400;">To sign up to our newsletters, use case studies and marketing material.</span>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p><span data-style="font-weight: 400;">On the performance basis of a contract to which you are a party, we carry out processing operations for the following purposes:</span>
            </p>
            <ul>
                <li data-style="font-weight: 400;"><span data-style="font-weight: 400;">To provice our services.</span>
                </li>
                <li data-style="font-weight: 400;"><span data-style="font-weight: 400;">To facilitate performance, including verifications relating to you.</span>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p><span data-style="font-weight: 400;">On the bases of your consent, we carry out processing operations for the following purposes:</span>
            </p>
            <ul>
                <li data-style="font-weight: 400;"><span data-style="font-weight: 400;">To use cookies, as described in our cookies policy.</span>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p><span data-style="font-weight: 400;">Based on the compliance with our legal and regulatory obligations, we carry out processing for the following purposes:</span>
            </p>
            <ul>
                <li data-style="font-weight: 400;"><span data-style="font-weight: 400;">To prevent, detect and investigate any activities that are potentially prohibited, unlawful, contrary to good practices and to ensure compliance with our terms of use and sending policy.</span>
                </li>
                <li data-style="font-weight: 400;"><span
                    data-style="font-weight: 400;">To answer to exercise of rights.</span>
                </li>
            </ul>
            <p>&nbsp;</p>
            <h1><span data-style="font-weight: 400;">THIRD PARTY DISCLOSURES</span></h1>
            <p>&nbsp;</p>
            <p><span
                data-style="font-weight: 400;">Personal data relating to you collected on our website are destined for&nbsp; own use and may be forwarded to warmr&rsquo;s partner companies so that we may obtain assistance and support in the context of carrying out our services.</span>
            </p>
            <p><span data-style="font-weight: 400;">Warmr ensures that it has in place clear dataprotection requirements for all its third-party providers.</span>
            </p>
            <p><span data-style="font-weight: 400;">We do not sell or rent your personal data to third parties for marketing purposes whatsoever.</span>
            </p>
            <p><span data-style="font-weight: 400;">In addition, Warmr does not disclose your personal data to third parties, except if:</span>
            </p>
            <p>&nbsp;</p>
            <p><span data-style="font-weight: 400;">you (or your account administrator acting on your behalf) requests or authorized disclosure thereof.</span>
            </p>
            <p><span data-style="font-weight: 400;">the disclosure is required to process transactions or supply services which you have requested (i.e. to check you are employing best practices in your mailings or for the purposes of processing an acquisition card with credit-card issuing companies) ;</span>
            </p>
            <p><span data-style="font-weight: 400;">warmr is compelled to do so by a government authority or a regulatory body, in the case of a court order, a summons to appear in court or any other similar requisition from a government or the judiciary, or to establish or defend a legal application ;</span>
            </p>
            <p><span data-style="font-weight: 400;">or the third party is a subcontractor or sub-processor of warmr in the carrying out of services(warmr uses the services of an Internet provider, a telecommunications company, a host provider, CRM tools, customer support client tools, invoices tools, payment tools, analytics tools).</span>
            </p>
            <p>&nbsp;</p>
            <h1><span data-style="font-weight: 400;">DATA RETENTION PERIODS</span></h1>
            <p><span data-style="font-weight: 400;">Your personal data is kept for the periods set out below :</span>
            </p>
            <p>&nbsp;</p>
            <ul>
                <li data-style="font-weight: 400;"><span data-style="font-weight: 400;">Personal data collected for the execution of the &nbsp; &nbsp; services as well as information about how and when you use the services: The time &nbsp; &nbsp; needed to provide to you our services, and in no event no longer than 3 &nbsp; &nbsp; years after closing your account (unless otherwise required by law).</span>
                </li>
                <li data-style="font-weight: 400;"><span
                    data-style="font-weight: 400;">Personal data collected in order to send &nbsp; &nbsp; newsletters, requests and direct marketing: 3 years from data collection or last active contact with the prospect.</span>
                </li>
                <li data-style="font-weight: 400;"><span data-style="font-weight: 400;">Personal data collected for evidentiary purposes: Duration of the statutory limitation period (generally 5 years).</span>
                </li>
                <li data-style="font-weight: 400;"><span data-style="font-weight: 400;">Personal data collected as part of your data subject&rsquo;s rights: If we ask you for proof of identity, we only retain it for the time necessary to verify your identity. Once the verification has been carried out, the proof is deleted.</span>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p><span data-style="font-weight: 400;">If you exercise your right to object to direct marketing: we keep this information for 3 years. The information allowing the management of your requests to exercise your rights under the GDPR will be kept for 3 years from the date of the request.</span>
            </p>
            <p>&nbsp;</p>
            <h1><span data-style="font-weight: 400;">LOCATION OF DATA STORAGE AND TRANSFERS</span></h1>
            <p><span data-style="font-weight: 400;">The host servers on which warmr processes and stores its databases are located exclusively within the European Union.</span>
            </p>
            <p>&nbsp;</p>
            <p><span
                data-style="font-weight: 400;">Regarding the tools we use (see article &ldquo;Third party disclosures&rdquo; on recipients of the collected data, concerning our processors), your personal data could be transferred outside the European Union. The transfer of your data within this framework is secured by the following safeguards:</span>
            </p>
            <p>&nbsp;</p>
            <ul>
                <li data-style="font-weight: 400;"><span data-style="font-weight: 400;">These data are transferred to a country which has &nbsp; &nbsp; been deemed to offer an adequate level of protection by the European Commission.</span>
                </li>
                <li data-style="font-weight: 400;"><span data-style="font-weight: 400;">Or we have concluded with our processors a specific contract governing the transfer of your data outside the European Union, based on the standard contractual clauses approved by the European Commission between a data controller and a processor.</span>
                </li>
            </ul>
            <p>&nbsp;</p>
            <h1><span data-style="font-weight: 400;">SECURITY</span></h1>
            <p><span data-style="font-weight: 400;">Within the framework of its services, warmr attributes the very highest importance to the security and integrity of its customers&rsquo; personal data.</span>
            </p>
            <p>&nbsp;</p>
            <p><span data-style="font-weight: 400;">Thus and in accordance with the GDPR, warmr undertakes to take all pertinent precautions in order to preserve the security of the data and, in particular, to protect them against any accidental or unlawful destruction, accidental loss, corruption, unauthorized circulation or access, as well as against any other form of unlawful processing or disclosure to unauthorized persons.</span>
            </p>
            <p>&nbsp;</p>
            <p><span data-style="font-weight: 400;">To this end, warmr implements industry standard security measures to protect personal data from unauthorized disclosure. Using industry recommended methods of encoding, warmr takes the measures necessary to protect information connected with payments and credit cards.</span>
            </p>
            <p>&nbsp;</p>
            <p><span data-style="font-weight: 400;">Moreover, in order to avoid in particular all unauthorized access, to guarantee accuracy and the proper use of the data, warmr has put the appropriate electronic, physical and managerial procedures in place with a view to safeguarding and preserving the data gathered through its services.</span>
            </p>
            <p>&nbsp;</p>
            <p><span data-style="font-weight: 400;">Notwithstanding this, there is no absolute safety from piracy or hackers. That is why in the event a breach of security were to affect you, warmr undertakes to inform you thereof without undue delay and to use its best efforts to take all possible measures to neutralize the intrusion and minimize the impacts. Should you suffer any loss by reason of the exploitation by a third party of a security breach, warmr undertakes to provide you with every assistance necessary so you are able to assert your rights. Moreover if, by some exceptional case, the direct loss incurred arose due to fault or gross negligence by warmr, you will be able to seek compensation within the limit of liability referred to in our Terms ofUse.</span>
            </p>
            <p>&nbsp;</p>
            <p><span data-style="font-weight: 400;">You should keep in mind that any user, customer,or hacker who discovers and takes advantage of a breach in security renders him or herself liable to criminal prosecution and that warmr will take all measures, including filing a complaint and/or bringing court action, to preserve the data and the rights of its users and of itself and to limit the impacts.</span>
            </p>
            <p>&nbsp;</p>
            <h1><span data-style="font-weight: 400;">YOUR DATA PROTECTION RIGHTS</span></h1>
            <p><span data-style="font-weight: 400;">In accordance with the French Data ProtectionLaws and the European General Data Protection Regulation 2016/679 (GDPR) you have several rights related to the collection of your personal data:</span>
            </p>
            <p>&nbsp;</p>
            <p><span data-style="font-weight: 400;">Right to be informed: This is precisely why we have drafted this privacy policy.</span>
            </p>
            <p><span data-style="font-weight: 400;">Right of access: You have the right to access all your personal data at any time.</span>
            </p>
            <p><span data-style="font-weight: 400;">Right to rectification: You have the right to rectify your inaccurate, incomplete or obsolete personal data at any time.</span>
            </p>
            <p><span data-style="font-weight: 400;">Right to restriction of processing: You have the right to restrict the processing of your personal data in certain cases stated in art.18 of the GDPR.</span>
            </p>
            <p><span data-style="font-weight: 400;">Right to erasure (&lsquo;right to be forgotten&rsquo;): You have the right to demand that your personal data be deleted and to prohibit any future collection.</span>
            </p>
            <p><span data-style="font-weight: 400;">Right to file a complaint to a competent supervisory authority (in France, the CNIL), if you consider that the processing of your personal data constitutes a breach of applicable regulations.</span>
            </p>
            <p><span data-style="font-weight: 400;">Right to define instructions related to the retention, deletion and communication of your personal data after your death.</span>
            </p>
            <p><span data-style="font-weight: 400;">Right to withdraw your consent at any time: for purposes based on consent, Article 7 of the GDPR provides that you may withdraw your consent at any time. Such withdrawal will not affect the lawfulness of the processing carried out &nbsp; &nbsp; before the withdrawal.</span>
            </p>
            <p><span data-style="font-weight: 400;">Right to data portability: You have the right to receive the personal data you have provided us in a standard machine-readable format and to require their transfer to the recipient of your choice.</span>
            </p>
            <p><span data-style="font-weight: 400;">Right to object: You have the right to object to the processing of your personal data. Please note however that we may continue to process your personal data despite this opposition for legitimate reasons or for &nbsp; &nbsp; the defence of legal claims.</span>
            </p>
            <p><span data-style="font-weight: 400;">How to exercice your rights :</span></p>
            <p>&nbsp;</p>
            <p><span data-style="font-weight: 400;">You can exercise these rights by sending us asupport ticket directly on the support chat (either on the website or on theapp) or, by sending an email at privacy@warmr.io. Your requests will be processed within 30 days. We may require that your request be accompanied by a photocopy of proof of identity or authority.</span>
            </p>
            <p><span data-style="font-weight: 400;">You are also able at any time to modify personal data by logging into your account and navigating to &ldquo;User Settings&rdquo;.</span>
            </p>
            <p>&nbsp;</p>
            <p><span data-style="font-weight: 400;">Right to refer to the supervisory authority :</span></p>
            <p>&nbsp;</p>
            <p><span data-style="font-weight: 400;">If you believe, after having contacted us, that your rights on your data are not respected, you can address a complaint to the competent Supervisory authority of your country of residence.</span>
            </p>
            <p>&nbsp;</p>
            <p><span data-style="font-weight: 400;">In France, where our head office is located, the control authority is the CNIL.</span>
            </p>
            <p>&nbsp;</p>
            <h2><span data-style="font-weight: 400;">Newsletters and marketing emails :</span></h2>
            <p>&nbsp;</p>
            <p><span data-style="font-weight: 400;">For those of you that have expressly opted in to receive our warmr newsletters, you are easily able to unsubscribe by following the &ldquo;unsubscribe&rdquo; links included in every email sent.</span>
            </p>
            <p>&nbsp;</p>
            <h2><span data-style="font-weight: 400;">Testimonials :</span></h2>
            <p>&nbsp;</p>
            <p><span data-style="font-weight: 400;">warmr publishes a list of Customers &amp; Testimonials on its sites with information on our customers&rsquo; names and job titles. warmr undertakes to obtain the authorization of every customer before publishing any testimonial on its websites. If you wish to be removed from this list, you can send us an email to privacy@warmr.io and we will delete your information promptly.</span>
            </p>
            <p>&nbsp;</p>
            <h2><span data-style="font-weight: 400;">Third party data :</span></h2>
            <p>&nbsp;</p>
            <p><span data-style="font-weight: 400;">In the context of using our services, namely creating emailing campaigns and contact lists, warmr has access to the information contained in the email contact lists you create in your account, as well as the subject and content of the emails that you send out via our services. This data is stored on secure servers and only a limited number of people are authorized to access your contact lists, in particular for the purpose of providing support services. You are easily able to recover your contact lists from your warmr account at any time, by clicking on the &ldquo;export&rdquo;button. You may also modify and or delete contacts at any time from you raccount. In no case does warmr sell, share or rent out your contact lists to third parties, nor does it use them for any purposes other than those set forth in this policy. We will use the information from your contact lists only for legal requirements, to invoice and collect summaries for our own statistics and for the purposes of providing you with customer support services. As creator of the contact lists and associated email campaigns, you are considered the data controller within the meaning of the GDPR, and warmr is acting only as a data processor. In this capacity, you are responsible in particular for: </span>
            </p>
            <p>&nbsp;</p>
            <p><span data-style="font-weight: 400;">complying with all current regulations in force, including the data protection laws,</span>
            </p>
            <p><span data-style="font-weight: 400;">making all the declarations necessary to the relative data protection authority,</span>
            </p>
            <p><span data-style="font-weight: 400;">obtaining the explicit consent of the persons concerned when collecting their personal data,</span>
            </p>
            <p><span data-style="font-weight: 400;">ensuring your authority to use the personal data collected in accordance with the defined end purposes and refraining from any unauthorized use.</span>
            </p>
            <p><span data-style="font-weight: 400;">If a recipient of your emails sent via ourservices requests us to modify or delete his/her personal data, we will honorthat request after proper verification and will inform you of it.</span>
            </p>
            <p>&nbsp;</p>
            <h1><span data-style="font-weight: 400;">PRIVACY POLICY CHANGES</span></h1>
            <p><span data-style="font-weight: 400;">warmr reserves the right to update this Privacy Policy at any time, in particular pursuant to any changes made to the laws and regulations in force.</span>
            </p>
            <p>&nbsp;</p>
            <p><span data-style="font-weight: 400;">Any modifications made will be notified to you via our website or by email,to the extent possible, thirty (30) days at least before any changes come into force. We would recommend that you check these rules from time to time to stay informed of our procedures and rules relating to your personal information.</span>
            </p>
            <p>&nbsp;</p>
            <h1><span data-style="font-weight: 400;">CONTACT US</span></h1>
            <p><span data-style="font-weight: 400;">If you have questions, you can email at Data Protection Officer directly at: privacy@warmr.io or by mail to:</span>
            </p>
            <p>&nbsp;</p>
            <p><span data-style="font-weight: 400;">Warmr SAS, Attn: Data Protection Officer / Legal Department, 17 bis rue tarissan, 31400, Toulouse, France.</span>
            </p>
        </div>
    )
}

export default Privacy;