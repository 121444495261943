import {FeedComment, FeedItem, FeedQuery} from "./feed.types";
import {api} from "@/api/api";
import {PaginatedQuery, PaginatedResponse} from "@/api/api.types";

export const feedApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getFeed: builder.query<PaginatedResponse<FeedItem>, FeedQuery & PaginatedQuery>({
            query: (queryString) => {
                const queryStringParameters = Object.keys(queryString)
                    // @ts-ignore
                    .map((key) => key + "=" + queryString[key])
                    .join("&");

                return `feed?${queryStringParameters}`;
            },
            providesTags: ["Contacts", "Feeds"],
        }),
        getFeedItem: builder.query<FeedItem, { uuid: string }>({
            query: ({uuid}) => {
                return `feed/${uuid}`;
            },
            providesTags: ["Contacts", "Feeds"],
        }),
        likePost: builder.mutation<void, { post_uuid: string }>({
            query: ({post_uuid}) => {
                return {
                    url: `feed/${post_uuid}/like`,
                    method: "POST",
                }
            }
        }),
        resourcePost: builder.mutation<void, { post_uuid: string }>({
            query: ({post_uuid}) => {
                return {
                    url: `feed/${post_uuid}/resource`,
                    method: "POST",
                }
            }
        }),
        commentPost: builder.mutation<void, { post_uuid: string, comment: string }>({
            query: ({post_uuid, comment}) => {
                return {
                    url: `feed/${post_uuid}/comment`,
                    method: "POST",
                    body: {comment}
                }
            }
        }),
        getComments: builder.query<PaginatedResponse<FeedComment>, { post_uuid: string }>({
            query: ({post_uuid}) => {
                return `feed/${post_uuid}/comments`;
            }
        }),
        getUserPosts: builder.query<any, any>({
            query: () => {
                return `feed/user-posts`;
            },
            providesTags: ["User", "Feeds"],
        }),
    }),
});

export const {
    useGetFeedQuery,
    useGetFeedItemQuery,
    useLikePostMutation,
    useCommentPostMutation,
    useGetCommentsQuery,
    useResourcePostMutation,
    useGetUserPostsQuery,
} = feedApi;
