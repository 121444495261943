import {Link} from "react-router-dom";
import DateRenderer from "@/components/elements/DateRenderer";
import React from "react";
import {Automation} from "@/api/automation.types";
import {useDeleteAutomationMutation} from "@/api/automation";
import AutomationItemStatusSwitch from "@/modules/automation/components/AutomationItemStatusSwitch";
import {Button} from "@/components/ui/button";
import {TrashIcon} from "lucide-react";
import {useUiAlert} from "@/components/elements/UiAlert";
import {useTranslation} from "react-i18next";

type AutomationListItemProps = {
    automation: Automation;
}

const AutomationListItem = ({automation}: AutomationListItemProps) => {
    const [deleteAutomation] = useDeleteAutomationMutation();

    const {t} = useTranslation();
    const {alert} = useUiAlert();

    return (
        <div
            className="flex flex-col p-4 gap-2 border rounded-md"
        >
            <Link to={`/automation/${automation.uuid}`}>
                <div className="flex justify-between gap-2">
                    <h3 className="font-bold text-lg">
                        {automation.name}
                    </h3>

                    <div
                        className="text-sm opacity-50 text-right"
                    >
                        <p>
                            {t('common.created') + ' '} <DateRenderer date={automation.createdAt}/>
                        </p>

                        {(automation.createdAt !== automation.updatedAt) &&
                            <p>
                                {t('common.updated') + ' '} <DateRenderer date={automation.updatedAt}/>
                            </p>
                        }
                    </div>
                </div>
            </Link>
            <div className="flex justify-between gap-2">
                <div
                    className="text-sm opacity-50 flex-grow"
                >
                    {automation.description}
                </div>
                <AutomationItemStatusSwitch automation={automation}/>
                <Button
                    variant="destructive"
                    onClick={() => alert({
                        title: t('automation.delete_confirmation.title'),
                        message: t('automation.delete_confirmation.message'),
                        action: () => deleteAutomation({uuid: automation.uuid}),
                        cancellable: true
                    })}
                >
                    <TrashIcon/>
                </Button>
            </div>
        </div>
    )
}

export default AutomationListItem;