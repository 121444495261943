import {clsx} from "clsx";
import {cloneElement, ReactElement, ReactNode} from "react";
import {cn} from "@/lib/utils";

type ChipElementProps = {
    label: string
    icon?: ReactNode
    variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'muted'
}

const ChipElement = ({label, icon, variant = 'primary'}: ChipElementProps) => {
    const chipIcon = icon ? cloneElement(icon as ReactElement, {
        className: clsx([
            'size-4',
            'mr-1',
            'text-current'
        ])
    }) : null;

    return (
        <span
            className={cn([
                'inline-flex',
                'items-center',
                'px-2',
                'py-1',
                'rounded-full',
                'text-xs',
                'font-medium',
                'bg-opacity-50',
                'text-opacity-90',
                'text-white',
                'border'
            ], {
                'border-muted': variant === 'muted',
                'bg-muted': variant === 'muted',
                'text-foreground': variant === 'muted',

                'border-primary': variant === 'primary',
                'bg-primary': variant === 'primary',
                "text-primary-foreground": variant === "primary",

                'border-secondary': variant === 'secondary',
                'bg-secondary': variant === 'secondary',
                'text-secondary-foreground': variant === 'secondary',

                'border-success': variant === 'success',
                'bg-success': variant === 'success',
                'text-success-foreground': variant === 'success',

                'border-destructive': variant === 'danger',
                'bg-destructive': variant === 'danger',
                'text-destructive-foreground': variant === 'danger',

                'border-warning': variant === 'warning',
                'bg-warning': variant === 'warning',
                'text-warning-foreground': variant === 'warning',
            })}
        >
            {chipIcon}
            {label}
        </span>
    )
}

export default ChipElement;