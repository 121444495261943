import {Automation} from "@/api/automation.types";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "@/components/ui/dropdown-menu";
import {useGetAvailableAutomationActionsQuery} from "@/api/dataset";
import {useCreateAutomationActionMutation, useGetAutomationActionsQuery} from "@/api/automation";
import {PlusIcon} from "lucide-react";
import {Button} from "@/components/ui/button";
import {useTranslation} from "react-i18next";
import {clsx} from "clsx";
import ActionItem from "@/modules/automation/components/ActionItem";
import ActionFilter from "@/modules/automation/components/ActionFilter";

type AutomationActionsProps = {
    automation: Automation
}

const AutomationActions = ({automation}: AutomationActionsProps) => {
    const {t} = useTranslation();

    const {data: availableActions} = useGetAvailableAutomationActionsQuery();

    const {data: automationActions, isLoading: actionsLoading} = useGetAutomationActionsQuery({
        uuid: automation?.uuid ?? ''
    }, {
        skip: !automation
    });

    const hasActions = !actionsLoading && automationActions && automationActions?.length > 0

    const [createAction] = useCreateAutomationActionMutation();

    return (
        <>
            {automationActions?.map((action, index) => (
                <div key={action.uuid}>
                    <ActionItem
                        automation={automation}
                        action={action}
                        previousFilters={automationActions?.[index - 1]?.outputFilters}
                    />

                    {index !== automationActions.length - 1 && (
                        <div
                            className="flex flex-col gap-4 items-center justify-center w-full"
                        >
                            <div
                                className="w-1 ml-2 border-l-4 h-8"
                            >

                            </div>

                            <ActionFilter action={action} automation={automation}/>

                            <div
                                className="w-1 ml-2 border-l-4 h-8"
                            >

                            </div>

                        </div>
                    )}
                </div>
            ))}
            <div
                className={clsx("flex items-center justify-center w-full", {
                    'h-[70vh]': !hasActions,
                })}
            >
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button
                            variant={!hasActions ? undefined : "outline"}
                            className="size-16 rounded-full"
                        >
                            <PlusIcon
                                className="size-10"
                            />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                        {availableActions?.map((action) => (
                            <DropdownMenuItem key={action.name}
                                              onClick={() => createAction({
                                                  automationUuid: automation.uuid,
                                                  name: action.name
                                              })}
                            >
                                {t('automation.action.' + action.name)}
                            </DropdownMenuItem>
                        ))}
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
        </>
    )
}

export default AutomationActions;