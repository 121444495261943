import {Contact} from "@/api/contact.types";
import {useContactSheet} from "@/modules/contacts/components/ContactSheet";
import {Link} from "react-router-dom";
import ObjectAvatar from "@/components/elements/ObjectAvatar";
import Loader from "@/components/elements/Loader";
import {CaretRightIcon} from "@radix-ui/react-icons";

type WidgetContactListProps = {
    contacts: Contact[];
    isLoading?: boolean;
}

const WidgetContactList = ({contacts, isLoading}: WidgetContactListProps) => {
    const {setContactUuid} = useContactSheet((state) => state) as any;

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-full">
                <Loader/>
            </div>
        )
    }

    return (
        <div
            className="flex flex-col gap-2"
        >
            {contacts.map((contact) => (
                <Link
                    key={contact.uuid}
                    onClick={(e) => {
                        e.preventDefault();
                        setContactUuid(contact.uuid)
                    }}
                    to={`/contacts/${contact.uuid}`}
                    className="flex items-center gap-4 p-2 bg-background rounded-full"
                >
                    <ObjectAvatar
                        object={contact}
                        className="size-6"
                    />
                    <p className="flex-grow">{contact.display_name}</p>
                    <CaretRightIcon className="size-4"/>
                </Link>
            ))}
        </div>
    )
}

export default WidgetContactList;