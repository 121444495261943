import {Resource} from "./resource.types";
import {api} from "@/api/api";
import {ObjectQuery, PaginatedQuery, PaginatedResponse} from "@/api/api.types";

export const resourceApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getResources: builder.query<PaginatedResponse<Resource>, PaginatedQuery>({
            query: ({page}) => `resources?page=${page ?? 1}`,
            providesTags: ["Resources"],
        }),
        deleteResource: builder.mutation<void, ObjectQuery>({
            query: ({uuid}) => ({
                url: `resources/${uuid}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, {uuid}) => ["Resources"],
        }),
    }),
});

export const {
    useGetResourcesQuery,
    useDeleteResourceMutation,
} = resourceApi;
