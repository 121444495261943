import {useGetListQuery} from "@/api/list";
import {useTranslation} from "react-i18next";
import useUser from "@/hooks/useUser";
import ObjectChip from "@/components/elements/ObjectChip";
import EmailParameterLine from "@/modules/automation/components/EmailComposer/EmailParameterLine";

import {useState} from "react";
import {Textarea} from "@/components/ui/textarea";

type LinkedinMessageActionProps = {
    toList?: string;
    onUpdate?: (data: any) => void;
    data?: any;
}

const LinkedinMessageAction = ({onUpdate, toList, data}: LinkedinMessageActionProps) => {
    const [message, setMessage] = useState(data?.body ?? '');

    const {t} = useTranslation();

    const {user} = useUser();

    const {data: list, isLoading: listLoading} = useGetListQuery({uuid: toList ?? ''}, {
        skip: !toList
    });

    const toLoading = listLoading;
    const to = {
        avatar_url: null,
        display_name: list?.name,
    }

    const handleDataUpdate = () => {
        if (onUpdate) {
            onUpdate({
                body: message
            })
        }
    }

    return (
        <>
            <EmailParameterLine label={t('messages.from')}>
                <ObjectChip
                    object={user}
                />
            </EmailParameterLine>

            <EmailParameterLine label={t('messages.to')}>
                <ObjectChip
                    object={to}
                    loading={toLoading}
                    tags={[t('messages.tags.with_linkedin')]}
                />
            </EmailParameterLine>

            <Textarea
                value={message}
                onChange={(e) => {
                    setMessage(e.target.value);
                    handleDataUpdate();
                }}
                onKeyUp={(e) => {
                    setMessage((e.target as HTMLInputElement).value);
                    handleDataUpdate();
                }}
            />
        </>
    )
}

export default LinkedinMessageAction;