import {Automation, AutomationAction} from "@/api/automation.types";
import {Input} from "@/components/ui/input";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select";
import {useTranslation} from "react-i18next";
import {useGetAvailableAutomationActionsQuery} from "@/api/dataset";
import {usePatchAutomationActionMutation} from "@/api/automation";

type ActionFilterProps = {
    action: AutomationAction,
    automation: Automation
}

const ActionFilter = ({action, automation}: ActionFilterProps) => {
    const {t} = useTranslation();
    const {data: availableActions} = useGetAvailableAutomationActionsQuery();

    const [patchAction] = usePatchAutomationActionMutation();
    const handleDataUpdate = (patchData?: any) => {
        const data = {
            automationUuid: automation.uuid,
            actionUuid: action.uuid,
            delay: patchData?.delay ?? undefined,
            outputFilters: patchData?.outputFilters ?? undefined
        };

        console.log(data);

        patchAction(data);
    };

    return (
        <div className="flex flex-col gap-4">
            <label
                className="flex justify-center items-center gap-2"
            >
                <p
                    className="text-sm opacity-50"
                >
                    {t('automation.filter.delay') + ' '}
                </p>

                <Input
                    type="number"
                    placeholder={t('automation.filter.delay')}
                    className="w-16 text-center"
                    min={1}
                    max={100}

                    value={action.delay ?? 1}
                    onChange={(e) => {
                        const delayData = parseInt((e.target as HTMLInputElement).value);
                        handleDataUpdate({
                            delay: delayData
                        });
                    }}
                    onKeyUp={(e) => {
                        const delayData = parseInt((e.target as HTMLInputElement).value);
                        handleDataUpdate({
                            delay: delayData
                        });
                    }}
                />

                <p className="text-sm opacity-50">
                    {
                        action.delay === 1 ? t('common.day').toLowerCase() :
                            t('common.days').toLowerCase()}
                </p>
            </label>

            <label
                className="flex flex-col justify-center items-center"
            >
                <p
                    className="text-sm opacity-50 mb-2"
                >
                    {t('automation.filter.stop_filter')}
                </p>

                <Select
                    onValueChange={(value) => {
                        const outputFiltersData = [
                            ...action.outputFilters.filter((filter: any) => filter.type !== 'stop_if'),
                            {
                                type: 'stop_if',
                                value
                            }
                        ];

                        handleDataUpdate({
                            outputFilters: outputFiltersData
                        })
                    }}
                    value={action.outputFilters?.find((filter: any) => filter.type === 'stop_if')?.value ?? ''}
                >
                    <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder={t('automation.filter.select_filter')}/>
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value={'none'}>
                            <span
                                className="italic opacity-50"
                            >
                                {t('automation.filter.no_filter')}
                            </span>
                        </SelectItem>
                        {availableActions?.find(item => {
                            return item.name === action.name
                        })?.outputFilters?.map((filter) => (
                            <SelectItem value={filter.name} key={filter.name}>
                                {t('automation.filter.' + filter.name)}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </label>
        </div>
    );
}

export default ActionFilter;