import {useSearchParams} from "react-router-dom";

export const useQueryState = (key: string) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const get = () => searchParams.get(key);
    const set = (value?: string | number | null) => {
        if (value) {
            searchParams.set(key, typeof value === 'number' ? value.toString() : value);
        } else {
            searchParams.delete(key);
        }
        setSearchParams(searchParams);
    }

    return [get(), set] as const;
}