import {Automation, AutomationAction} from "./automation.types";
import {api} from "@/api/api";
import {PaginatedQuery, PaginatedResponse} from "@/api/api.types";

export const automationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAutomations: builder.query<PaginatedResponse<Automation>, PaginatedQuery>({
            query: (queryString) => {
                const queryStringParameters = Object.keys(queryString)
                    // @ts-ignore
                    .map((key) => key + "=" + queryString[key])
                    .join("&");

                return `automations?${queryStringParameters}`;
            },
            providesTags: ["Automations"],
        }),
        getAutomation: builder.query<Automation, { uuid: string }>({
            query: ({uuid}) => {
                return `automations/${uuid}`;
            },
            providesTags: ["Automations"],
        }),
        getAutomationActions: builder.query<AutomationAction[], { uuid: string }>({
            query: ({uuid}) => {
                return `automations/${uuid}/actions`;
            },
            providesTags: ["Automations"],
        }),
        createAutomationAction: builder.mutation<AutomationAction, { automationUuid: string, name: string }>({
            query: ({automationUuid, ...data}) => {
                return {
                    url: `automations/${automationUuid}/actions`,
                    method: "POST",
                    body: data
                }
            },
            invalidatesTags: ["Automations"]
        }),
        patchAutomationAction: builder.mutation<AutomationAction, {
            automationUuid: string,
            actionUuid: string,
            actionName?: string
            data?: any
            delay?: number
            outputFilters?: any
        }>({
            query: ({automationUuid, actionUuid, ...data}) => {
                return {
                    url: `automations/${automationUuid}/actions/${actionUuid}`,
                    method: "PATCH",
                    body: data
                }
            },
            invalidatesTags: ["Automations"]
        }),
        deleteAutomationAction: builder.mutation<void, { automationUuid: string, actionUuid: string }>({
            query: ({automationUuid, actionUuid}) => {
                return {
                    url: `automations/${automationUuid}/actions/${actionUuid}`,
                    method: "DELETE",
                }
            },
            invalidatesTags: ["Automations"]
        }),
        patchAutomation: builder.mutation<Automation, {
            uuid: string,
            name?: string,
            description?: string,
            status?: string
        }>({
            query: ({uuid, ...data}) => {
                return {
                    url: `automations/${uuid}`,
                    method: "PATCH",
                    body: data
                }
            },
            invalidatesTags: ["Automations"]
        }),
        createAutomation: builder.mutation<Automation, { name: string, description?: string, listUuid: string }>({
            query: ({name, description, listUuid}) => {
                return {
                    url: `automations`,
                    method: "POST",
                    body: {name, description, listUuid}
                }
            },
            invalidatesTags: ["Automations"]
        }),
        deleteAutomation: builder.mutation<void, { uuid: string }>({
            query: ({uuid}) => {
                return {
                    url: `automations/${uuid}`,
                    method: "DELETE",
                }
            },
            invalidatesTags: ["Automations"]
        }),
    }),
});

export const {
    useGetAutomationsQuery,
    useGetAutomationQuery,
    usePatchAutomationMutation,
    useCreateAutomationMutation,
    useDeleteAutomationMutation,
    useGetAutomationActionsQuery,
    useCreateAutomationActionMutation,
    usePatchAutomationActionMutation,
    useDeleteAutomationActionMutation,
} = automationApi;
