import {CalendarEvent, CalendarEventQuery} from "./calendar_event.types";
import {api} from "@/api/api";
import {PaginatedQuery, PaginatedResponse} from "@/api/api.types";

export const resourceApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getCalendarEvents: builder.query<PaginatedResponse<CalendarEvent>, PaginatedQuery & {
            contactUuid?: string;
            type?: string;
            sortByKey?: string;
            sortByDirection?: 'asc' | 'desc';
        }>({
            query: (queryString) => {
                const queryStringParameters = Object.keys(queryString)
                    // @ts-ignore
                    .map((key) => key + "=" + (queryString[key] ?? ''))
                    .join("&");

                return `calendar-events?${queryStringParameters}`;
            },
            providesTags: ["CalendarEvents"],
        }),
        createCalendarEvent: builder.mutation<void, CalendarEventQuery>({
            query: (reminder) => ({
                url: `calendar-events`,
                method: "POST",
                body: reminder,
            }),
            invalidatesTags: ["CalendarEvents"],
        }),
        deleteCalendarEvent: builder.mutation<void, { uuid: string }>({
            query: ({uuid}) => ({
                url: `calendar-events/${uuid}`,
                method: "DELETE",
            }),
            invalidatesTags: ["CalendarEvents"],
        }),
        patchCalendarEvent: builder.mutation<void, { uuid: string, reminder: CalendarEventQuery }>({
            query: ({uuid, reminder}) => ({
                url: `calendar-events/${uuid}`,
                method: "PATCH",
                body: reminder,
            }),
            invalidatesTags: ["CalendarEvents"],
        }),
    }),
});

export const {
    useGetCalendarEventsQuery,
    useCreateCalendarEventMutation,
    useDeleteCalendarEventMutation,
    usePatchCalendarEventMutation,
} = resourceApi;
