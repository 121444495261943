import {BrowserRouter} from "react-router-dom";
import Footer from "@/components/layout/footer/Footer";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import useDarkMode from "@/hooks/useDarkMode";
import AppRouter from "@/Router";
import UserSignInModal from "@/modules/login/UserSignInModal";
import {Toaster} from "@/components/ui/sonner";
import {clsx} from "clsx";
import UserMenu from "@/components/layout/header/UserMenu";
import Navigation from "@/components/layout/header/Navigation";
import HeaderContainer from "@/components/layout/header/HeaderContainer";
import {IconPicker} from "@/components/data/ObjectIcon";
import React from "react";
import {UiAlert} from "@/components/elements/UiAlert";
import {WriterDialog} from "@/modules/feed/components/PostWriterDialog";
import {ContactSheet} from "@/modules/contacts/components/ContactSheet";

function App() {
    useDarkMode();

    const productName = process.env.REACT_APP_PRODUCT_NAME;
    const {i18n} = useTranslation();
    const currentLanguage = i18n.language;

    return (
        <div className={"App"} vaul-drawer-wrapper={''}>
            <Helmet titleTemplate={"%s — " + productName}>
                <html lang={currentLanguage}/>
            </Helmet>

            <BrowserRouter>
                <div className={clsx([
                    "flex",
                    "min-h-screen",
                    "relative",
                    "w-full"
                ])}>
                    <div
                        className={clsx([
                            "flex",
                            "flex-shrink-0",
                            "flex-col",
                            "w-[16rem]",
                            "sticky",
                            "top-0",
                            "border-r",
                            'h-screen',
                            'bg-muted/30',
                            'max-md:fixed',
                            'max-md:z-[1000]',
                            'max-md:bg-muted',
                            'max-md:top-0',
                            'max-md:left-0',
                            'max-md:translate-x-[-16rem]',
                            'max-md:hover:translate-x-0',
                            'max-md:hover:shadow-xl',
                            'max-md:transition-transform',
                            'max-md:after:fixed',
                            'max-md:after:bottom-24',
                            'max-md:after:-right-12',
                            'max-md:after:size-12',
                            'max-md:after:block',
                            'max-md:after:bg-background',
                            'max-md:after:shadow-sm',
                            'max-md:after:border',
                            'max-md:after:border-l-0',
                            'max-md:after:rounded-tr-lg',
                            'max-md:after:rounded-br-lg',
                            'max-md:after:content-[">"]',
                            'max-md:after:text-foreground/50',
                            'max-md:after:font-bold',
                            'max-md:after:flex',
                            'max-md:after:items-center',
                            'max-md:after:justify-center',
                            'max-md:hover:after:opacity-0',
                        ])}
                    >
                        <HeaderContainer className={'bg-transparent'}>
                            <UserMenu/>
                            <p>&nbsp;</p>
                        </HeaderContainer>

                        <div className="flex-1">
                            <Navigation/>
                        </div>
                        <Footer/>
                    </div>
                    <div
                        className={clsx([
                            "flex",
                            "flex-col",
                            "flex-1",
                            "min-h-screen",
                            "w-[calc(100%-16rem)]",
                        ])}
                    >
                        <HeaderContainer isMain/>
                        <div
                            className={clsx([
                                "flex",
                                "flex-col",
                                "flex-1",
                                "overflow-x-hidden",
                            ])}
                        >
                            <AppRouter/>
                        </div>
                    </div>
                    <ContactSheet/>
                    <UserSignInModal/>
                    <IconPicker/>
                    <WriterDialog/>
                    <Toaster richColors/>
                    <UiAlert/>
                </div>
            </BrowserRouter>
        </div>
    );
}

export default App;