import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import {useHeaderContainerStore} from "@/components/layout/header/HeaderContainer";
import React, {useEffect} from "react";
import {useParams} from "react-router";
import {useGetContactQuery} from "@/api/contact";
import ContactHeader from "../components/ContactHeader";
import ContactActivity from "@/modules/contacts/components/ContactActivity";
import PostFeed from "@/modules/feed/components/PostFeed";
import {TabsContent} from "@/components/ui/tabs";
import ContactNotes from "@/modules/contacts/components/ContactNotes";
import ContactInformation from "@/modules/contacts/components/ContactInformation";
import ContactHeaderSkeleton from "@/modules/contacts/components/ContactHeaderSkeleton";
import ContactReminders from "@/modules/contacts/components/ContactReminders";
import {TabsLight, TabsListLight, TabsTriggerLight} from "@/components/ui/tabs-light";

const ContactPage = ({forceContactUuid}: {
    forceContactUuid?: string
}) => {
    const {uuid: uuidParam} = useParams();

    const uuid = forceContactUuid ?? uuidParam;

    const {t} = useTranslation();
    const {data, isLoading} = useGetContactQuery({
        uuid: uuid ?? '',
        relations: ['pipeline_stages', 'companies']
    }, {
        skip: !uuid
    });

    const {
        setCrumbs,
        setTitle
    } = useHeaderContainerStore((state) => state) as any;

    useEffect(() => {
        if (forceContactUuid) return;

        setCrumbs([{
            label: t('contacts.page_title'),
            to: '/contacts'
        }, {
            label: data?.display_name ?? '',
            to: '/contacts/' + data?.uuid
        }
        ]);
        setTitle(t('contacts.page_title'))
    }, [data?.display_name, data?.uuid, setCrumbs, setTitle, t, forceContactUuid]);

    if (isLoading || !data) return (
        <div className="relative">
            <ContactHeaderSkeleton/>
        </div>
    )

    return (
        <>
            <Helmet>
                <title>
                    {t('contacts.page_title')}
                </title>
            </Helmet>

            <div className="relative">
                <ContactHeader contact={data}/>

                <div className="p-8 pt-0 w-full">
                    <TabsLight defaultValue="information">
                        <TabsListLight>
                            <TabsTriggerLight value="information">
                                {t('contacts.tabs.information')}
                            </TabsTriggerLight>
                            <TabsTriggerLight value="feed">
                                {t('contacts.tabs.feed')}
                            </TabsTriggerLight>
                            <TabsTriggerLight value="activity">
                                {t('contacts.tabs.activity')}
                            </TabsTriggerLight>
                            <TabsTriggerLight value="notes">
                                {t('contacts.tabs.notes')}
                            </TabsTriggerLight>
                            <TabsTriggerLight value="reminders">
                                {t('contacts.tabs.reminders')}
                            </TabsTriggerLight>
                        </TabsListLight>

                        <TabsContent value="information">
                            <ContactInformation contact={data}/>
                        </TabsContent>
                        <TabsContent value="feed">
                            <PostFeed contact={data}/>
                        </TabsContent>
                        <TabsContent value="activity" className="pt-4">
                            <ContactActivity contact={data}/>
                        </TabsContent>
                        <TabsContent value="notes">
                            <ContactNotes contact={data}/>
                        </TabsContent>
                        <TabsContent value="reminders">
                            <ContactReminders contact={data}/>
                        </TabsContent>
                    </TabsLight>

                </div>
            </div>


        </>
    )
}

export default ContactPage;