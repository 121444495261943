type AppErrorProps = {
    message?: string

}
const AppError = ({message}: AppErrorProps) => {
    return (
        <div className="container h-[100vh] flex flex-col justify-center items-center">
            <h1 className="text-5xl font-bold mb-8 opacity-50">Ooooops!</h1>
            <p
                className="text-5xl font-bold mb-8"
            >
                🪵
            </p>
            <p
                className="text-xl font-bold opacity-50"
            >
                {message ?? 'This is an error page'}
            </p>
        </div>
    )
}

export default AppError