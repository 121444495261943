import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";

type DateRendererProps = {
    date: string | number | Date;
    format?: 'fromNow';
    showTime?: boolean;
}

export const DateRenderer = ({date, format, showTime = true}: DateRendererProps) => {
    dayjs.extend(relativeTime)

    const [rendered, setRendered] = useState<string>('')
    const {t} = useTranslation()

    const isToday = useMemo(() => !showTime && dayjs(date).isSame(dayjs(), 'day'), [date, showTime])

    const render = useCallback(() => {
        if (!format || format === 'fromNow') {
            if (isToday) {
                setRendered(t('common.today'));
                return;
            }

            const isFuture = dayjs(date).isAfter(dayjs())

            setRendered(!isFuture ? dayjs(date).fromNow() : dayjs(date).format(showTime ? 'LLL' : 'LL'))
        }
    }, [date, format, isToday, showTime, t])

    useEffect(() => {
        render()

        const interval = setInterval(() => {
            render()
        }, 1000 * 60)

        return () => {
            clearInterval(interval)
        }
    }, [date, format, render])

    return (
        <time dateTime={dayjs(date).toISOString()}>
            {rendered}
        </time>
    )
}

export default DateRenderer