import {Automation, AutomationAction} from "@/api/automation.types";
import {useDeleteAutomationActionMutation, usePatchAutomationActionMutation} from "@/api/automation";
import {clsx} from "clsx";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "@/components/ui/dropdown-menu";
import {useTranslation} from "react-i18next";
import {TrashIcon} from "lucide-react";

import {useUiAlert} from "@/components/elements/UiAlert";
import EmailAction from "@/modules/automation/components/EmailAction";
import Loader from "@/components/elements/Loader";
import {useRef} from "react";
import LinkedinMessageAction from "@/modules/automation/components/LinkedinMessageAction";

type ActionItemProps = {
    action: AutomationAction;
    automation: Automation;
    previousFilters?: any;
}

const ActionItem = ({previousFilters, action, automation}: ActionItemProps) => {
    const patchTimeout = useRef<any>();

    const {t} = useTranslation();

    const [deleteAction] = useDeleteAutomationActionMutation();
    const [patchAction, {isLoading: isUpdating}] = usePatchAutomationActionMutation();

    const {alert} = useUiAlert();

    const handleDataUpdate = (data: any) => {
        if (patchTimeout.current) {
            clearTimeout(patchTimeout.current);
        }

        patchTimeout.current = setTimeout(() => {
            patchAction({
                automationUuid: automation.uuid,
                actionUuid: action.uuid,
                data
            });
        }, 1000);
    }

    return (
        <div
            className={clsx([
                "border",
                "p-4",
                "max-w-4xl",
                "mx-auto",
                "my-8"
            ])}
        >
            <div
                className="flex justify-between mb-4"
            >
                <h2
                    className="font-bold text-lg flex-grow"
                >
                    {t('automation.action.' + action.name)}
                </h2>

                <div
                    className="flex items-center gap-2"
                >
                    {isUpdating && <Loader
                        className="!size-6"
                    />}

                    <DropdownMenu>
                        <DropdownMenuTrigger>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none"
                                 stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                 className="lucide lucide-ellipsis-vertical">
                                <circle cx="12" cy="12" r="1"/>
                                <circle cx="12" cy="5" r="1"/>
                                <circle cx="12" cy="19" r="1"/>
                            </svg>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            <DropdownMenuItem
                                className="text-destructive"
                                onClick={() => alert({
                                    title: t('automation.action.delete_confirmation.title'),
                                    message: t('automation.action.delete_confirmation.message'),
                                    action: () => deleteAction({
                                        automationUuid: automation.uuid,
                                        actionUuid: action.uuid
                                    }),
                                    cancellable: true
                                })}
                            >
                                <div className="w-full flex justify-between items-center">
                                    {t('common.delete')}
                                    <TrashIcon
                                        className="size-4"
                                    />
                                </div>
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </div>

            {action.name === 'send_email' && <EmailAction
                toList={automation?.listUuid}
                onUpdate={handleDataUpdate}
                data={action.data}
                toTags={previousFilters ? previousFilters.filter((filter: any) => filter.value !== 'none').map((filter: any) => t('messages.tags.' + filter.type + '_' + filter.value)) : []}
            />}

            {action.name === 'send_linkedin_message' && <LinkedinMessageAction
                toList={automation?.listUuid}
                onUpdate={handleDataUpdate}
                data={action.data}
            />}
        </div>
    )
}

export default ActionItem;