import {useGetUserAdvicesQuery, usePatchUserAdvicesMutation} from "@/api/user";
import {useTranslation} from "react-i18next";
import ObjectAvatar from "@/components/elements/ObjectAvatar";
import {useCallback, useMemo} from "react";
import {Button} from "@/components/ui/button";
import {useNavigate} from "react-router";
import Loader from "@/components/elements/Loader";

const WarmingAdvices = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const {data, isLoading: isGettingData, refetch} = useGetUserAdvicesQuery(undefined, {
        refetchOnFocus: true,
    });

    const advices = useMemo(() => data?.advices, [data]);

    const [patchAdvice, {isLoading: isUpdating}] = usePatchUserAdvicesMutation();
    const isLoading = isGettingData || isUpdating;

    const randomAdvice = useMemo(() => advices?.[Math.floor(Math.random() * advices.length)], [advices]);

    const handleSkip = () => {
        patchAdvice({uuid: randomAdvice?.uuid, relevant: false});

        refetch();
    }

    const handleComplete = useCallback(() => {
        if (!randomAdvice?.objectType) return;

        if (randomAdvice?.objectType === 'post') {
            navigate('/feed/' + randomAdvice?.object?.uuid)
        }

        patchAdvice({uuid: randomAdvice?.uuid, relevant: true, completed: true});

        refetch();
    }, [randomAdvice?.object?.uuid, randomAdvice?.objectType, randomAdvice?.uuid, navigate, patchAdvice]);

    if (isLoading) return (<div className="flex flex-col items-center w-full h-[224px] justify-around text-center">
        <Loader/>
    </div>);

    if (!randomAdvice) {
        return <div className="flex items-center p-12 w-full h-[220px] opacity-50 text-center">
            {t('advices.empty')}
        </div>
    }

    return (
        <div className="flex flex-col items-center w-full h-[224px] justify-around text-center">
            <ObjectAvatar object={randomAdvice?.contact} className="size-12 mb-4"/>

            <div>
                <p className="line-clamp-1 text-lg">{randomAdvice?.title}</p>
                <p className="line-clamp-2 opacity-50 text-sm">{randomAdvice?.description}</p>
            </div>

            <div className="flex items-end w-full justify-center mt-4 gap-4">
                {randomAdvice?.objectType &&
                    <Button onClick={handleComplete}>
                        {t('advices.' + (randomAdvice.objectType + '_action'), {...randomAdvice?.object})}
                    </Button>
                }

                <Button variant="ghost" onClick={handleSkip}>
                    {t('advices.skip')}
                </Button>
            </div>
        </div>
    );
}

export default WarmingAdvices;