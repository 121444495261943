import {configureStore, isRejectedWithValue, type Middleware, type MiddlewareAPI} from "@reduxjs/toolkit"; // Or from '@reduxjs/toolkit/query/react'
import {setupListeners} from "@reduxjs/toolkit/query";
import {api} from "@/api/api";
import {toast} from "sonner"
import {t} from "i18next";


/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
        // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
        if (isRejectedWithValue(action)) {
            const errorMessage = t('errors.api.' + ((action as any).payload?.data?.message ?? 'unknown'))
            toast.error(errorMessage)
        }

        return next(action)
    }

export const store = configureStore({
    reducer: {
        // Add the generated reducer as a specific top-level slice
        [api.reducerPath]: api.reducer,
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(api.middleware, rtkQueryErrorLogger),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
