import {Avatar, AvatarImage} from "@/components/ui/avatar";
import DateRenderer from "@/components/elements/DateRenderer";
import {useNavigate} from "react-router";
import {clsx} from "clsx";

type NotificationCardProps = {
    title: string,
    message: string,
    notifiedAt: string,
    image?: string;
    link?: string;
    type?: "default" | "progress";
    read?: boolean;
}

const NotificationCard = ({read, type, title, link, image, message, notifiedAt}: NotificationCardProps) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (link) {
            navigate(link);
        }
    }

    return (
        <div
            onClick={handleClick}
            className={clsx("bg-muted rounded-lg shadow-md gap-4 p-4 flex cursor-pointer", {
                "opacity-50": read
            })}>
            <Avatar>
                <AvatarImage src={image} alt={title}/>
            </Avatar>
            <div>
                <div className={"flex gap-2 items-center"}>
                    {type === 'progress' &&
                        <div className="bg-primary size-2 rounded-full animate-pulse"/>
                    }

                    <div className="text-lg font-bold">{title}</div>
                </div>

                <div className="text-sm opacity-50">
                    <DateRenderer date={notifiedAt}/>
                </div>

                <div className="text-sm mt-2">{message}</div>
            </div>
        </div>
    )
}

export default NotificationCard;