import React from "react";
import {Contact} from "@/api/contact.types";
import {LinkedinIcon, MailIcon, NetworkIcon} from "lucide-react";
import {Button} from "@/components/ui/button";
import ScoreChip from "@/modules/contacts/components/ScoreChip";
import ObjectAvatar from "@/components/elements/ObjectAvatar";
import ContactActions from "@/modules/contacts/components/ContactActions";

type ContactHeaderProps = {
    contact: Contact
}

const ContactHeader = ({contact}: ContactHeaderProps) => {
    return (
        <div className="p-8 w-full">
            <div className="flex gap-8">
                <div className="relative">
                    <ObjectAvatar
                        object={contact}
                        className="size-24"
                    />

                    <div
                        className="absolute top-0 right-0"
                    >
                        <ScoreChip contact={contact} variant="rounded-bordered"/>
                    </div>
                </div>

                <div className="flex-grow h-full flex flex-col">
                    <h1 className="text-2xl font-bold">
                        {contact.display_name}
                    </h1>
                    <p
                        className="text-sm opacity-50"
                    >
                        {contact.company_position ?? contact.company_name}
                        {contact.company_name && contact.company_position && ' @ '}
                        {contact.company_name}
                    </p>

                    <div className="flex-grow"/>

                    <div className="flex items-center mt-4 gap-4 hidden">
                        {contact?.linkedin_id &&
                            <Button variant="outline" asChild className="p-1 rounded-full aspect-square">
                                <a
                                    href={`https://linkedin.com/in/${contact.linkedin_id}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <LinkedinIcon className="size-4   block opacity-50"/>
                                </a>
                            </Button>
                        }
                        {contact?.email &&
                            <Button variant="outline" asChild className="p-1 rounded-full aspect-square">
                                <a
                                    href={`mailto:${contact.email}`}
                                >
                                    <MailIcon className="size-4   block opacity-50"/>
                                </a>
                            </Button>
                        }
                        {contact?.website &&
                            <Button variant="outline" asChild className="p-1 rounded-full aspect-square">
                                <a
                                    href={contact.website}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <NetworkIcon className="size-4   block opacity-50"/>
                                </a>
                            </Button>
                        }
                    </div>
                </div>

                <div>
                    <ContactActions contact={contact} variant="list-menu"/>
                </div>
            </div>
        </div>
    )
}

export default ContactHeader;