import React from 'react';
import ReactDOM from 'react-dom/client';

// Styles
import 'non.geist'
import 'non.geist/mono'

import './styles/index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {store} from "@/stores/store";
import './i18n';
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import * as Sentry from "@sentry/react";

dayjs.extend(localizedFormat)
dayjs.extend(advancedFormat);
dayjs.extend(weekOfYear);


Sentry.init({
    dsn: "https://ee64acefca44816e5ee99268fe22d7a3@o4506659536240640.ingest.us.sentry.io/4507924432093184",
    beforeSend(event, hint) {
        // Check if it is an exception, and if so, show the report dialog
        if (event.exception && event.event_id) {
            Sentry.showReportDialog({ eventId: event.event_id });
        }
        return event;
    },
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.feedbackIntegration({
            colorScheme: "system",
            isNameRequired: true,
            isEmailRequired: true,
        })
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/www.warmr\.app/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App/>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();