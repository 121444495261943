import {useGetContactsQuery} from "@/api/contact";
import WidgetContactList from "@/modules/dashboard/components/WidgetContactList";

const TopTrendingContacts = () => {
    const {data, isLoading} = useGetContactsQuery({
        page: 1,
        limit: 5,
        sortKey: 'popularity_score',
        sortDirection: 'desc'
    });

    return (
        <WidgetContactList contacts={data?.data || []} isLoading={isLoading}/>
    )
}

export default TopTrendingContacts;