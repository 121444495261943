import {UserPost} from "./writer.types";
import {api} from "@/api/api";
import {PaginatedQuery, PaginatedResponse} from "@/api/api.types";
import {ItemMedia} from "@/api/feed.types";

export const writerApi = api.injectEndpoints({
    endpoints: (builder) => ({
        newPost: builder.mutation<UserPost, {
            post_text: string,
            medias: ItemMedia[],
            style_of_contact_uuid?: string,
            original_text?: string,
        }>({
            query: (data) => {
                return {
                    url: `feed/writer/post`,
                    method: "POST",
                    body: data
                }
            },
            invalidatesTags: ["WriterPosts"]
        }),
        generatePost: builder.mutation<{
            text: string,
            medias: ItemMedia[]
        }, {
            post_text: string,
            style_of_contact_uuid: string,
            language: string,
            post_length: number,
        }>({
            query: (data) => {
                return {
                    url: `feed/writer/post/generate`,
                    method: "POST",
                    body: data
                }
            }
        }),
        posts: builder.query<PaginatedResponse<UserPost>, PaginatedQuery & {
            status?: string,
        }>({
            query: (data) => {
                const queryStringParameters = Object.keys(data)
                    // @ts-ignore
                    .map((key) => key + "=" + data[key])
                    .join("&");
                return `feed/writer/posts?${queryStringParameters}`;
            },
            providesTags: ["WriterPosts"],
        }),
        post: builder.query<UserPost, { uuid: string }>({
            query: ({uuid}) => {
                return `feed/writer/${uuid}`;
            },
            providesTags: ["WriterPosts"],
        }),
        deletePost: builder.mutation<void, { uuid: string }>({
            query: ({uuid}) => {
                return {
                    url: `feed/writer/${uuid}`,
                    method: "DELETE",
                }
            },
            invalidatesTags: ["WriterPosts"]
        }),
        updatePost: builder.mutation<UserPost, {
            uuid: string,
            text?: string,
            scheduledAt?: Date,
            status?: string
        }>({
            query: ({uuid, ...data}) => {
                return {
                    url: `feed/writer/${uuid}`,
                    method: "PUT",
                    body: data
                }
            },
            invalidatesTags: ["WriterPosts"]
        }),
    }),
});

export const {
    useNewPostMutation,
    useGeneratePostMutation,
    usePostsQuery,
    useDeletePostMutation,
    useUpdatePostMutation,
    usePostQuery,
} = writerApi;
