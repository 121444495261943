import {api} from "@/api/api";

export const magicApi = api.injectEndpoints({
    endpoints: (builder) => ({
        textReplace: builder.mutation<{
            items: string[]
        }, {
            text: string,
            replace: string,
            instructions?: string
        }>({
            query: (data) => {
                return {
                    url: `magic/text-replace`,
                    method: "POST",
                    body: data
                }
            },
        }),
    }),
});

export const {
    useTextReplaceMutation,
} = magicApi;
