"use client"

import * as React from "react"
import {useEffect} from "react"
import {format} from "date-fns"
import {Calendar as CalendarIcon} from "lucide-react"

import {cn} from "@/lib/utils"
import {Button} from "@/components/ui/button"
import {Calendar} from "@/components/ui/calendar"
import {Popover, PopoverContent, PopoverTrigger,} from "@/components/ui/popover"

type DatePickerProps = {
    value?: Date
    onChange?: (date?: Date) => void
    minDate?: Date
    maxDate?: Date
}

export function DatePicker({
                               value,
                               onChange,
                               minDate, maxDate
                           }: DatePickerProps) {
    const [date, setDate] = React.useState<Date>()

    const [isOpen, setIsOpen] = React.useState(false)

    useEffect(() => {
        if (value) {
            setDate(value)
        }
    }, [value])

    const handleSelect = (date?: Date) => {
        if (!date) return

        if (minDate && date < minDate) {
            date = minDate
        } else if (maxDate && date > maxDate) {
            date = maxDate
        }

        setDate(date)
        onChange?.(date)

        setIsOpen(false)
    }

    return (
        <Popover
            open={isOpen}
            onOpenChange={setIsOpen}
        >
            <PopoverTrigger asChild>
                <Button
                    onClick={() => setIsOpen(true)}
                    variant={"outline"}
                    className={cn(
                        "w-[280px] justify-start text-left font-normal",
                        !date && "text-muted-foreground"
                    )}
                >
                    <CalendarIcon className="mr-2 h-4 w-4"/>
                    {date ? format(date, "PPP") : <span>Pick a date</span>}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
                <Calendar
                    mode="single"
                    selected={date}
                    onSelect={handleSelect}
                    initialFocus
                />
            </PopoverContent>
        </Popover>
    )
}
