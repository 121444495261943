import PostItem from "@/modules/feed/components/PostFeed/PostItem";
import {UserPost as UserPostType} from "@/api/writer.types";
import {useDeletePostMutation, useUpdatePostMutation} from "@/api/writer";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import {FeedItem} from "@/api/feed.types";
import {Button} from "@/components/ui/button";
import {EditIcon, SendIcon, TrashIcon} from "lucide-react";
import {Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle} from "@/components/ui/dialog";
import {DatePicker} from "@/components/elements/DatePicker";
import {useTranslation} from "react-i18next";
import {Checkbox} from "@/components/ui/checkbox";
import {clsx} from "clsx";
import HourSelector from "@/components/data/HourSelector";
import WysiwygEditor from "@/components/data/WysiwygEditor";

type UserPostProps = {
    post: UserPostType
}

const UserPost = ({post}: UserPostProps) => {
    const {t} = useTranslation();

    const [isEditing, setIsEditing] = useState(false)
    const [isPublishing, setIsPublishing] = useState(false)

    const [scheduleDate, setScheduleDate] = useState<Date | undefined>(!post.scheduled_at ? undefined : (dayjs(post.scheduled_at)).toDate());
    const [postContent, setPostContent] = useState(post.content);
    const [publishNow, setPublishNow] = useState(false);

    const [deletePost, {isLoading: deleteLoading}] = useDeletePostMutation();
    const [updatePost, {isLoading: updateLoading}] = useUpdatePostMutation();

    const actionLoading = deleteLoading || updateLoading;

    const handlePostUpdate = (e: any, status = "draft") => {
        if (updateLoading) return;

        setIsEditing(false)
        setIsPublishing(false)

        updatePost({
            uuid: post.uuid,
            text: postContent,
            scheduledAt: publishNow ? dayjs().toDate() : scheduleDate,
            status
        })
    }

    const handlePostSchedule = (e: any) => {
        return handlePostUpdate(e, "scheduled")
    }

    const handlePostDelete = () => {
        if (deleteLoading) return;

        deletePost({
            uuid: post.uuid
        })
    }

    useEffect(() => {
        setScheduleDate(publishNow ? dayjs().startOf('hour').toDate() : undefined)
    }, [publishNow])

    useEffect(() => {
        if (!post.scheduled_at) {
            setPublishNow(true)
        } else {
            setPublishNow(false)
            setScheduleDate((dayjs(post.scheduled_at)).toDate())
        }
    }, [post]);

    return (
        <div className="max-w-[560px] w-full flex-shrink-0">
            <PostItem post={(post as FeedItem)}
                      withStatus={post.status}
                      withInteractions={false} withExternaLink={false}/>

            {post.isEditable && (
                <div
                    className="flex gap-2"
                >
                    <Button
                        disabled={actionLoading}
                        onClick={handlePostDelete}
                        variant="outline"
                        className="text-current"
                    >
                        <TrashIcon/>
                    </Button>

                    <Button
                        disabled={actionLoading}
                        onClick={() => {
                            setIsEditing(true)
                        }}
                        variant="outline"
                        className="text-current"
                    >
                        <EditIcon/>
                    </Button>

                    <Button
                        disabled={actionLoading}
                        onClick={() => {
                            setIsPublishing(true)
                        }}
                        variant="outline"
                        className="text-current"
                    >
                        <SendIcon/>
                    </Button>

                    <Dialog
                        open={isPublishing}
                        onOpenChange={setIsPublishing}
                    >
                        <DialogContent>
                            <DialogHeader>
                                <DialogTitle>
                                    {t('feed.my_posts.schedule.title')}
                                </DialogTitle>
                                <DialogDescription>
                                    {t('feed.my_posts.schedule.description')}
                                </DialogDescription>
                            </DialogHeader>

                            <div>
                                <div className="flex flex-col gap-4">
                                    <label className={clsx("flex gap-4 justify-between", {
                                        'opacity-50': publishNow
                                    })}
                                           onClick={() => setPublishNow(false)}
                                    >
                                        <span>
                                         {t('feed.my_posts.schedule.date')}
                                        </span>

                                        <DatePicker
                                            value={scheduleDate}
                                            onChange={setScheduleDate}
                                            minDate={new Date()}
                                        />
                                    </label>
                                    <label className="flex gap-4 justify-between">
    <span>
                                        {t('feed.my_posts.schedule.hour')}
                                        </span>
                                        <HourSelector
                                            disabled={publishNow}
                                            hour={scheduleDate?.getHours()}
                                            min={scheduleDate?.getMinutes()}
                                            onHourChange={(hour) => {
                                                setScheduleDate(dayjs(scheduleDate).hour(hour).toDate())
                                            }}
                                            onMinChange={(min) => {
                                                setScheduleDate(dayjs(scheduleDate).minute(min).toDate())
                                            }}
                                        />
                                    </label>
                                    <label className="flex gap-4 justify-between">
                                        <span>
                                        {t('feed.my_posts.schedule.publish_now')}
                                        </span>
                                        <Checkbox
                                            checked={publishNow}
                                            onClick={() => {
                                                setPublishNow(!publishNow)
                                            }}
                                        />
                                    </label>
                                </div>

                                <div
                                    className="mt-4 flex justify-end gap-4"
                                >
                                    <Button
                                        disabled={actionLoading}
                                        onClick={handlePostUpdate}
                                        variant="outline"
                                    >
                                        {t('feed.schedule_post.save')}
                                    </Button>

                                    <Button
                                        disabled={actionLoading}
                                        onClick={handlePostSchedule}
                                    >
                                        {
                                            publishNow ? t('feed.schedule_post.publish')
                                                : t('feed.schedule_post.schedule')
                                        }
                                    </Button>
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>

                    <Dialog
                        open={isEditing}
                        onOpenChange={setIsEditing}
                    >
                        <DialogContent
                        >
                            <DialogHeader>
                                <DialogTitle>
                                    {t('feed.my_posts.edit.title')}
                                </DialogTitle>
                            </DialogHeader>

                            <WysiwygEditor
                                outline={true}
                                value={postContent} onChange={setPostContent}
                            />

                            <div className="pt-4 flex justify-end">
                                <Button
                                    disabled={actionLoading}
                                    onClick={handlePostUpdate}
                                >
                                    {t('feed.update_post')}
                                </Button>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
            )}
        </div>
    )
}

export default UserPost