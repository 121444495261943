import UserReminders from "@/modules/dashboard/components/widgets/UserReminders";
import WidgetCard, {WidgetCardProps} from "@/modules/dashboard/components/WidgetCard";
import {cn} from "@/lib/utils";
import {FC} from "react";
import TopTrendingContacts from "@/modules/dashboard/components/widgets/TopTrendingContacts";
import TopScoringContacts from "@/modules/dashboard/components/widgets/TopScoringContacts";
import SubjectIdeas from "@/modules/dashboard/components/widgets/SubjectIdeas";
import UserPostInteractions from "@/modules/dashboard/components/widgets/UserPostInteractions";
import FollowersChart from "@/modules/dashboard/components/widgets/FollowersChart";
import WarmingContacts from "@/modules/dashboard/components/widgets/WarmingContacts";
import WarmingAdvices from "@/modules/dashboard/components/widgets/WarmingAdvices";

const DashboardWidgets = () => {
    const widgets: (WidgetCardProps & {
        component: FC
    })[] = [{
        title: 'LinkedIn Profile',
        component: FollowersChart,
        size: 4
    }, {
        title: "Warming Contacts",
        component: WarmingContacts,
        size: 2,
        background: 'gradient-1'
    }, {
        title: "Warming Advices",
        component: WarmingAdvices,
        size: 2,
        background: 'gradient-2'
    }, {
        title: 'Posts Interactions',
        component: UserPostInteractions,
        size: 4
    }, {
        title: 'Reminders',
        component: UserReminders,
        size: 4
    }, {
        title: 'Subject Ideas',
        component: SubjectIdeas,
        size: 2,
        background: 'gradient-3'
    }, {
        title: 'Top Trending Contacts',
        component: TopTrendingContacts,
        size: 2
    }, {
        title: 'Top Scoring Contacts',
        component: TopScoringContacts,
        size: 2
    }]
    return (
        <div
            className={cn([
                'grid',
                'grid-cols-1',
                'md:grid-cols-2',
                'lg:grid-cols-4',
                'xl:grid-cols-6',
                'gap-2'
            ])}
        >
            {widgets.map((widget) => (
                <WidgetCard key={widget.title} title={widget.title} scheme={widget.scheme}
                            background={widget.background}
                            size={widget.size}>
                    <widget.component/>
                </WidgetCard>
            ))}
        </div>
    );
}

export default DashboardWidgets;