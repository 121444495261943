import {useEffect} from "react";
import {toast} from "sonner";
import {useSearchParams} from "react-router-dom";

const useErrorMessage = () => {
    const [searchParams] = useSearchParams();
    const error = searchParams.get("error");

    useEffect(() => {
        if (error) {
            setTimeout(() => toast.error(error), 250);
        }
    }, [error])

    return {}
}

export default useErrorMessage;