import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import {useHeaderContainerStore} from "@/components/layout/header/HeaderContainer";
import {useGetAutomationsQuery} from "@/api/automation";
import {Dialog, DialogContent, DialogHeader} from "@/components/ui/dialog";
import CreateAutomationForm from "./automation/components/CreateAutomationForm";
import {clsx} from "clsx";
import {Button} from "@/components/ui/button";
import AutomationListItem from "@/modules/automation/components/AutomationListItem";

const Automation = () => {
    const {t} = useTranslation();

    const {
        setCrumbs,
        setTitle
    } = useHeaderContainerStore((state) => state) as any;

    useEffect(() => {
        setCrumbs([]);
        setTitle(t('automation.page_title'))
    }, [setCrumbs, setTitle, t]);

    const {data: automations, isLoading} = useGetAutomationsQuery({
        page: 1
    });

    const [creationOpen, setCreationOpen] = useState(false);

    useEffect(() => {
        if (!isLoading && !automations?.data?.length) {
            setCreationOpen(true);
        }
    }, [automations, isLoading]);

    return (
        <div>
            <Helmet>
                <title>
                    {t('automation.page_title')}
                </title>
            </Helmet>

            <div
                className={clsx([
                    "flex",
                    "p-4",
                    "gap-4",
                    "justify-end",
                    "items-center",
                    "pb-0"
                ])}>

                <Button
                    onClick={() => setCreationOpen(true)}
                >
                    {t('automation.create_button')}
                </Button>
            </div>
            <div
                className={clsx([
                    "flex",
                    "flex-col",
                    "p-4",
                    "gap-4"
                ])}>

                {automations?.data?.map((automation) => (
                    <AutomationListItem key={automation.uuid}
                                        automation={automation}/>
                ))}
            </div>

            <Dialog
                open={creationOpen}
                onOpenChange={setCreationOpen}>
                <DialogContent>
                    <DialogHeader>
                        {t('automation.creation_form.title')}
                    </DialogHeader>

                    <CreateAutomationForm/>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Automation