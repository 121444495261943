import {useCreateListMutation, useGetListQuery, useGetListsQuery} from "@/api/list";
import {Button} from "@/components/ui/button";
import ListEditPanel from "@/modules/contacts/components/ListEditPanel";
import {useState} from "react";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import {NavArrowDown} from "iconoir-react";
import {usePatchContactMutation} from "@/api/contact";
import {useTranslation} from "react-i18next";

type ListChipPropsT = {
    uuid: string,
    contactUuid?: string
}

const ListChip = ({uuid, contactUuid}: ListChipPropsT) => {
    const [editingListUuid, setEditingListUuid] = useState<string | null>(null);

    const {data, isLoading} = useGetListQuery({uuid});

    const {data: lists, isLoading: listsLoading} = useGetListsQuery({
        page: 1
    });

    const {t} = useTranslation()

    const [patchContact] = usePatchContactMutation()
    const [createList] = useCreateListMutation()

    const setContactList = async (listUuid: string) => {
        if (contactUuid) {
            await patchContact({
                uuid: contactUuid,
                list_uuid: listUuid
            })
        }
    }

    const newList = async () => {
        const createdList = await createList({
            name: "My awesome list"
        })

        // Edit the list
        if ((createdList as any)?.data?.uuid)
            setEditingListUuid((createdList as any).data.uuid)
    }

    if (isLoading) {
        return null
    }

    return <>
        <div
            className="flex items-center"
        >
            <Button
                variant="ghost"
                className="bg-muted/50 p-3 font-normal rounded-full h-6 rounded-e-none hover:bg-muted"
                onClick={() => setEditingListUuid(uuid)}
            >
                {data?.name}
            </Button>

            {contactUuid && <DropdownMenu>
                <DropdownMenuTrigger
                    className="!outline-none hover:bg-muted rounded-full p-1 -m-1 pr-4"
                    asChild
                >
                    <Button
                        variant="ghost"
                        className="ml-[1px] bg-muted/50 p-2 font-normal rounded-full h-6 rounded-s-none hover:bg-muted"
                        onClick={() => setEditingListUuid(uuid)}
                    >
                        <NavArrowDown className="h-4 w-4"/>
                    </Button>
                </DropdownMenuTrigger>

                <DropdownMenuContent
                    className="rounded-none"
                >
                    {lists?.data?.map((list) => (
                        <DropdownMenuItem
                            key={list.uuid}
                            onClick={() => setContactList(list.uuid)}
                        >
                            {list.name}
                        </DropdownMenuItem>
                    ))}

                    <DropdownMenuSeparator/>

                    <DropdownMenuItem
                        onClick={() => newList()}
                    >
                        {t('contacts.lists.new')}
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
            }

        </div>

        <ListEditPanel
            uuid={editingListUuid ?? uuid}
            isOpen={!!editingListUuid}
            onClose={() => setEditingListUuid(null)}
        />
    </>
}

export default ListChip