import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import {useHeaderContainerStore} from "@/components/layout/header/HeaderContainer";
import React, {useEffect, useState} from "react";
import WysiwygEditor from "@/components/data/WysiwygEditor";
import LinkedInPostPreview from "@/modules/feed/components/LinkedInPostPreview";
import {TabsLight, TabsListLight, TabsTriggerLight} from "@/components/ui/tabs-light";
import {TabsContent} from "@/components/ui/tabs";
import WriterOptions from "@/modules/feed/components/WriterOptions";
import Loader from "@/components/elements/Loader";

const Writer = () => {
    const [postText, setPostText] = useState<string>('')
    const [contentIsLoading, setContentIsLoading] = useState(false)

    const {t} = useTranslation();

    const {
        setCrumbs,
    } = useHeaderContainerStore((state) => state) as any;

    useEffect(() => {
        setCrumbs([{
            label: t('feed.page_title'),
            to: "/feed"
        }, {
            label: t('feed.writer.page_title'),
            to: "/feed/writer"
        }]);
    }, [t, setCrumbs]);

    return (
        <div>
            <Helmet>
                <title>{t('feed.writer.page_title')}</title>
            </Helmet>

            <div className="flex">
                <div className="flex-grow flex flex-col ">
                    <div className="w-full flex-grow max-w-[720px] mx-auto">
                        {!contentIsLoading ?
                            <WysiwygEditor
                                value={postText}
                                onChange={setPostText}
                                placeholder={t('feed.writer.write_something')}
                                className="p-4 md:p-8 flex-grow overflow-y-auto flex flex-col [&>div]:h-full [&>div>div]:h-full [&>div>div>div]:h-full !h-full"
                            />
                        : <div className={"flex flex-col items-center justify-center h-full"}>
                            <Loader/>
                        </div>
                        }
                    </div>
                    <div className="border-t p-4">
                        WIP: Images, Carousel, ...
                    </div>
                </div>
                <div className="flex-shrink-0 w-full max-w-[440px] bg-muted border-l min-h-[calc(100vh-3em)]">
                    <TabsLight defaultValue="writer">
                        <TabsListLight className="px-4">
                            <TabsTriggerLight value="writer">
                                {t('feed.writer.tabs.writer')}
                            </TabsTriggerLight>
                            <TabsTriggerLight value="preview">
                                {t('feed.writer.tabs.preview')}
                            </TabsTriggerLight>
                        </TabsListLight>

                        <TabsContent value="writer">
                            <div className="p-4">
                                <WriterOptions
                                    isSavingPost={false}
                                    onLoading={(loading) => {
                                        setContentIsLoading(loading)
                                    }}
                                    setPostText={(text) => {
                                        setContentIsLoading(true)
                                        setPostText(text)
                                        setTimeout(() => {
                                            setContentIsLoading(false)
                                        }, 100)
                                    }}
                                />
                            </div>
                        </TabsContent>

                        <TabsContent value="preview">
                            <div className="p-4">
                                <LinkedInPostPreview
                                    text={postText}
                                />
                            </div>
                        </TabsContent>
                    </TabsLight>
                </div>
            </div>
        </div>
    )
}

export default Writer