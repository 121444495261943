import {Tabs, TabsContent, TabsList, TabsTrigger} from "@/components/ui/tabs";
import {cn} from "@/lib/utils";

const TabsLight = (props: any) => {
    return (
        <Tabs
            className="w-full"
            {...props}
        />
    )
}

const TabsListLight = ({className, ...props}: any) => {
    return (
        <TabsList
            className={cn("!bg-transparent gap-6 border-b rounded-none p-0 w-full justify-start mb-2", className)}
            {...props}
        />
    )
}

const TabsTriggerLight = (props: any) => {
    return (
        <TabsTrigger
            className="!bg-transparent flex-shrink-0 px-0 rounded-none !shadow-none border-b-2 border-transparent data-[state=active]:border-foreground -mb-2"
            {...props}
        />
    )
}

const TabsContentLight = (props: any) => {
    return (
        <TabsContent
            {...props}
        />
    )
}

export {TabsLight, TabsListLight, TabsTriggerLight, TabsContentLight}