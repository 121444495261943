import {useGetContactsQuery} from "@/api/contact";
import {useParams} from "react-router";
import {useQueryState} from "@/hooks/useQueryState";

const useFullContactsList = (params?: Record<string, string | number | boolean>) => {
    // const [page, setPage] = useState(1);
    // const [content, setContent] = useState<Contact[]>([]);
    //
    // const {listUuid: listUuidParam} = useParams();
    // const [listUuidQuery] = useQueryState('listUuid');
    //
    // const {filterString: filters} = useSearchQuery();
    //
    // const [cachedQueriesCount, setCachedQueriesCount] = useState(0);
    //
    // const {
    //     data,
    //     isLoading,
    //     refetch: refetchInit
    // } = useGetContactsQuery({
    //     ...(params ?? {}),
    //     page,
    //     filters,
    //     listUuid: (listUuidParam ?? listUuidQuery) as string ?? undefined
    // });
    //
    // useEffect(() => {
    //     setContent([]);
    //     setPage(1);
    // }, [filters, listUuidParam, listUuidQuery]);
    //
    // useEffect(() => {
    //     if (!data?.data.length) return;
    //
    //     setContent([
    //         ...content,
    //         ...(data?.data ?? [])
    //     ]);
    //
    //     setPage(page + 1)
    //
    //     // eslint-disable-next-line
    // }, [data]);
    //
    // const refetch = () => {
    //     setContent([]);
    //     setPage(1);
    //     refetchInit();
    // }
    //
    // // listen to store reducer state change
    // useEffect(() => {
    //     store.subscribe(() => {
    //         const {api} = store.getState();
    //         // recursively count array items
    //         const hasCachedQueries = (api.provided.Contacts ?? {})?.__internal_without_id?.length ?? 0;
    //
    //         if (hasCachedQueries < cachedQueriesCount) {
    //             refetch();
    //         }
    //         setCachedQueriesCount(hasCachedQueries);
    //     });
    //     // eslint-disable-next-line
    // }, []);

    const page = 1;

    const {listUuid: listUuidParam} = useParams();
    const [listUuidQuery] = useQueryState('listUuid');

    const {data, isLoading, refetch} = useGetContactsQuery({
        ...(params ?? {}),
        page,
        listUuid: (listUuidParam ?? listUuidQuery) as string ?? undefined
    });

    const content = data?.data ?? [];

    return {
        content,
        isLoading,
        refetch
    }
}

export default useFullContactsList;