import useUser from "@/hooks/useUser";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuRadioGroup,
    DropdownMenuRadioItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import {Skeleton} from "@/components/ui/skeleton";
import useDarkMode from "@/hooks/useDarkMode";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import ObjectAvatar from "@/components/elements/ObjectAvatar";

const UserMenu = () => {
    const {user, isLoading, logout} = useUser();

    const {isDarkMode, setIsDarkMode} = useDarkMode();

    const {t} = useTranslation();

    const setAppearanceMode = (mode: string) => {
        if (mode === "dark") {
            setIsDarkMode(true);
        } else {
            setIsDarkMode(false);
        }
    }

    if (!isLoading && !user) return <></>;

    const UserMenuButtonContent = () => {
        if (isLoading) {
            return <>
                <Skeleton className="w-6 h-6 rounded-full"/>
                <Skeleton className="w-[100px] h-[20px] rounded-full"/>
            </>
        }

        const displayName = user?.first_name ?? user?.last_name ?? user?.email?.split("@")[0].replace(".", " ");
        const userInitials = displayName?.split(" ").map((name) => name[0]).join("");

        return <>
            <ObjectAvatar
                object={user}
            />

            <p>
                {displayName}
            </p>
        </>
    }

    const UserMenuButton = () => (
        <div
            className="flex space-x-2 items-center"
        >
            <UserMenuButtonContent/>
        </div>
    )

    if (isLoading) {
        return <UserMenuButton/>
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger
                className="!outline-none hover:bg-muted/50 rounded-full p-1 -m-1 pr-4"
            >
                <UserMenuButton/>
            </DropdownMenuTrigger>

            <DropdownMenuContent
                className="ml-2 rounded-none"
            >
                {user && (<>
                    <DropdownMenuItem asChild>
                        <Link
                            to="/user"
                        >
                            {t('common.user.settings')}
                        </Link>
                    </DropdownMenuItem>
                </>)}
                <DropdownMenuSeparator/>
                <DropdownMenuLabel>
                    {t('common.appearance.title')}
                </DropdownMenuLabel>
                <DropdownMenuRadioGroup
                    value={isDarkMode ? "dark" : "light"}
                    onValueChange={setAppearanceMode}
                >
                    <DropdownMenuRadioItem
                        value="light"
                    >
                        {t('common.appearance.light')}
                    </DropdownMenuRadioItem>
                    <DropdownMenuRadioItem
                        value="dark"
                    >
                        {t('common.appearance.dark')}
                    </DropdownMenuRadioItem>
                </DropdownMenuRadioGroup>
                <DropdownMenuSeparator/>
                <DropdownMenuItem onClick={() => logout()}>
                    {t('common.user.logout')}
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );
}

export default UserMenu