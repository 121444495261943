import {Message} from "@/api/message.types";
import {clsx} from "clsx";
import DateRenderer from "@/components/elements/DateRenderer";
import {LinkedinIcon, MailIcon} from "lucide-react";

type MessageBubbleProps = {
    message: Message;
}

export const MessageBubble = ({message}: MessageBubbleProps) => {
    return (
        <div className={clsx({
            'mt-2 w-full': true,
            'flex': true,
            'flex-col': true,
            'items-end': message.sent_by === 'user',
            'items-start': message.sent_by === 'contact',
        })}>
            <div
                className={clsx({
                    'inline-block': true,
                    'border': true,
                    'border-muted': true,
                    'bg-muted': message.sent_by === 'contact',
                    'p-2': true,
                    'px-4': true,
                    'rounded-xl': true,
                    'rounded-br-none': message.sent_by === 'contact',
                    'rounded-bl-none': message.sent_by === 'user',
                    'max-w-[65%]': true,
                    'self-start': message.sent_by === 'contact',
                    'self-end': message.sent_by === 'user',
                    'bg-primary': message.sent_by === 'user',
                    'border-primary': message.sent_by === 'user',
                    'text-primary-foreground': message.sent_by === 'user',
                })}
            >
                {message.message}
            </div>
            <p className="text-xs opacity-50 px-2 mt-1 flex">
                {message.platform === 'linkedin' && <LinkedinIcon className="size-3 inline-block mr-1"/>}
                {message.platform === 'email' && <MailIcon className="size-3 inline-block mr-1"/>}

                <DateRenderer date={message.sent_at}/>
            </p>
        </div>
    )
}

export default MessageBubble