import {api} from "@/api/api";
import {PaginatedQuery, PaginatedResponse} from "@/api/api.types";
import {Conversation, Message, SendMessageQuery} from "@/api/message.types";

export const messageApi = api.injectEndpoints({
    endpoints: (builder) => ({
        sendMessage: builder.mutation<void, SendMessageQuery>({
            query: ({contact_uuid, message, channels}) => ({
                url: `messages`,
                method: "PUT",
                body: {
                    message,
                    channels,
                    contact_uuid,
                },
            }),
        }),
        getMessages: builder.query<PaginatedResponse<Message>, PaginatedQuery & { contact_uuid: string }>({
            query: ({contact_uuid, page}) => `messages?contact_uuid=${contact_uuid}&page=${page}`,
        }),
        getConversations: builder.query<Conversation[], {}>({
            query: () => `messages/conversations`,
        }),
    }),
});

export const {
    useSendMessageMutation,
    useGetMessagesQuery,
    useGetConversationsQuery,
} = messageApi;
