import {FormEvent, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import useFullContactsList from "@/hooks/useFullContactsList";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select";
import {useGeneratePostMutation, useNewPostMutation} from "@/api/writer";
import {Button} from "@/components/ui/button";
import {Contact} from "@/api/contact.types";
import {Link} from "react-router-dom";
import ObjectAvatar from "@/components/elements/ObjectAvatar";
import {Label} from "@/components/ui/label";
import WysiwygEditor from "@/components/data/WysiwygEditor";
import {clsx} from "clsx";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {SparklesIcon, X} from "lucide-react";
import {useGetUserSubjectsQuery} from "@/api/dataset";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "@/components/ui/dropdown-menu";
import {LightBulb, Refresh} from "iconoir-react";
import {Textarea} from "@/components/ui/textarea";
import {Close} from "@radix-ui/react-popover";
import Loader from "@/components/elements/Loader";
import {useNavigate} from "react-router";
import {Slider} from "@/components/ui/slider";
import {useWriterDialogStore} from "@/modules/feed/components/PostWriterDialog";
import ImageResize from "image-resize";
import UploadButton from "@/components/elements/UploadButton";

type PostWriterProps = {
    className?: string
}
const PostWriter = ({className}: PostWriterProps) => {
    const {t} = useTranslation()

    const {initialMagicPrompt, setInitialMagicPrompt, setOpenState} = useWriterDialogStore((state) => state) as any;

    const [showWysiwyg, setShowWysiwyg] = useState(true)

    const [post, setPost] = useState<string>('')
    const [magicPostText, setMagicPostText] = useState<string>('')

    const [styleOfContactUuid, setStyleOfContactUuid] = useState<string | null>(null)
    const [language, setLanguage] = useState<string | null>(null)
    const [textLength, setTextLength] = useState<number>(.5)

    const [postImages, setPostImages] = useState<File[]>([])
    const [postBase64Images, setPostBase64Images] = useState<string[]>([])

    useEffect(() => {
        if (postImages.length > 0) {
            postImages.forEach((image) => {
                ImageResize(image, {
                    format: 'jpg',
                    width: 1500,
                    outputType: 'base64'
                }).then((imageResize) => {
                    setPostBase64Images((prev) => {
                        return [
                            ...prev,
                            imageResize as any
                        ]
                    })
                })
            })
        }
    }, [postImages])

    const [savePost, {
        isLoading: isSavingPost,
        isError: hasSavedPostError
    }] = useNewPostMutation();

    const [generatePost, {
        isLoading: isGeneratingPost,
    }] = useGeneratePostMutation();

    const {content: contacts} = useFullContactsList({
        popular: true
    });

    const {data: userSubjects} = useGetUserSubjectsQuery()

    const randomUserSubjects = useMemo(() => {
        return Object.values(userSubjects ?? [])
            .sort(() => Math.random() - 0.5)
            .slice(0, 7)
    }, [userSubjects]);

    const languages = [
        'en',
        'fr'
    ];

    const handleSubmit = async (e?: FormEvent<HTMLFormElement>, redirectAfterSave?: boolean) => {
        e?.preventDefault();

        await savePost({
            post_text: post,
            style_of_contact_uuid: styleOfContactUuid ?? '',
            original_text: magicPostText ?? '',
            medias: [{
                type: 'image',
                images: postBase64Images?.map((image) => {
                    return {
                        url: image
                    }
                }) ?? []
            }]
        });

        if (!hasSavedPostError && redirectAfterSave) {
            // Redirect to my posts
            navigate('/feed/posts?status=pending');
            setOpenState(false);
        }
    }

    useEffect(() => {
        console.log(post);
    }, [post])

    const navigate = useNavigate();

    const handleGeneratePost = (forceText?: string) => {
        if (isGeneratingPost) return;

        setShowWysiwyg(false);

        generatePost({
            post_text: (forceText ?? magicPostText).replace(/<[^>]*>?/gm, ''),
            style_of_contact_uuid: styleOfContactUuid ?? '',
            language: language ?? '',
            post_length: textLength ?? .5
        }).then((response) => {
            const generatedPostData = response.data;

            if (generatedPostData) {
                setPost(generatedPostData.text);

                setTimeout(() => {
                    setShowWysiwyg(true);
                }, 250);
            }
        });
    }

    useEffect(() => {
        if (initialMagicPrompt && !isGeneratingPost) {
            setMagicPostText(initialMagicPrompt);
            setTextLength(.4);
            handleGeneratePost(initialMagicPrompt);
            setInitialMagicPrompt('');
        }
    }, [isGeneratingPost, initialMagicPrompt, handleGeneratePost, setInitialMagicPrompt]);

    const toolbarButtonVariant = "ghost";

    return (
        <form
            onSubmit={handleSubmit}
        >
            <div
                className={clsx([
                    "border",
                    "rounded-md"
                ])}>
                {!isGeneratingPost && showWysiwyg ?
                    <WysiwygEditor
                        value={post}
                        onChange={setPost}
                        disabled={isSavingPost}
                        placeholder={t('feed.writer.write_something')}
                        className="p-4 min-h-48 max-h-[70vh] overflow-y-auto"
                    />
                    : <div className="p-4 h-48 flex flex-col justify-center items-center">
                        <Loader/>
                    </div>
                }

                {postImages.length > 0 && (
                    <div
                        className={clsx([
                            "grid",
                            "grid-cols-4",
                            "gap-2",
                            "px-4"
                        ])}
                    >
                        {postImages.map((image, index) => {
                            return (
                                <div
                                    className="w-full aspect-square relative">
                                    <img
                                        key={index}
                                        src={URL.createObjectURL(image)}
                                        alt=""
                                        className="object-cover rounded-sm w-full h-full"
                                    />
                                    <Button
                                        onClick={() => {
                                            setPostImages((prev) => {
                                                return prev.filter((_, i) => i !== index)
                                            })
                                        }}
                                        variant="outline"
                                        className="absolute top-1 right-1 !size-6 !rounded-full !p-0"
                                    >
                                        <X
                                            className="size-4"
                                        />
                                    </Button>
                                </div>
                            )
                        })}
                    </div>
                )}

                <div
                    className={clsx([
                        "flex",
                        "mt-4",
                        "w-full",
                        "items-center",
                        'justify-end',
                        'bg-muted',
                        'rounded-bl-md',
                        'rounded-br-md',
                        "p-4"
                    ])}
                >
                    {magicPostText && post && (
                        <Button
                            onClick={() => handleGeneratePost()}
                            disabled={isSavingPost || isGeneratingPost}
                            variant={toolbarButtonVariant}
                        >
                            <Refresh className="size-6"/>
                        </Button>
                    )}
                    <Popover>
                        <PopoverTrigger asChild>
                            <Button variant={toolbarButtonVariant}
                                    disabled={isSavingPost || isGeneratingPost}
                            >
                                <SparklesIcon className="size-6"/>
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-[420px]">
                            <div className="grid gap-4">
                                <div className="space-y-2">
                                    <h4 className="font-medium leading-none">{t('feed.writer.magic.title')}</h4>
                                    <p className="text-sm text-muted-foreground">
                                        {t('feed.writer.magic.description')}
                                    </p>
                                </div>
                                <div className="space-y-4">
                                    <div className="flex gap-4 justify-between items-start">
                                        <Label
                                            className={"mt-3"}
                                        >
                                            {t('feed.writer.about')}
                                        </Label>
                                        <div className="relative w-full">
                                            <Textarea
                                                disabled={isSavingPost || isGeneratingPost}
                                                value={magicPostText}
                                                onChange={(e) => setMagicPostText(e.target.value)}
                                                placeholder={t('feed.writer.magic.about_placeholder')}
                                                className={clsx("w-full block", {
                                                    "pr-12": randomUserSubjects && randomUserSubjects.length > 0,
                                                }, [
                                                    'resize-none',
                                                    'focus:resize-y',
                                                ])}
                                                rows={1}
                                            />

                                            {randomUserSubjects && randomUserSubjects.length > 0 && (
                                                <div
                                                    className={clsx([
                                                        "absolute",
                                                        "top-0",
                                                        "right-0",
                                                        "mt-1",
                                                        "mr-1",
                                                    ])}
                                                >
                                                    <DropdownMenu>
                                                        <DropdownMenuTrigger asChild>
                                                            <Button variant="outline"
                                                                    disabled={isSavingPost || isGeneratingPost}
                                                                    className="size-8 p-0 rounded-sm flex items-center justify-center">
                                                                <LightBulb className="size-5 text-foreground"/>
                                                            </Button>
                                                        </DropdownMenuTrigger>
                                                        <DropdownMenuContent>
                                                            {randomUserSubjects
                                                                .map((subject) => {
                                                                    return (
                                                                        <DropdownMenuItem key={subject}
                                                                                          onClick={() => {
                                                                                              setMagicPostText(subject)
                                                                                          }}
                                                                        >
                                                                            {subject}
                                                                        </DropdownMenuItem>
                                                                    )
                                                                })}
                                                        </DropdownMenuContent>
                                                    </DropdownMenu>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                    <div className="flex gap-4 justify-between items-center">
                                        <Label>
                                            {t('feed.writer.language')}
                                        </Label>

                                        <Select
                                            disabled={isSavingPost || isGeneratingPost}
                                            onValueChange={(value) => setLanguage(value)}
                                        >
                                            <SelectTrigger className="w-[180px]">
                                                <SelectValue placeholder={t('feed.writer.select_language')}/>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {languages.map((language) => {
                                                        return (
                                                            <SelectItem key={language} value={language}>
                                                                {t('common.languages.' + language)}
                                                            </SelectItem>
                                                        )
                                                    })}
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                    </div>
                                    {contacts &&
                                        <div className="flex gap-4 justify-between items-center">
                                            <Label>
                                                {t('feed.writer.style_of')}
                                            </Label>
                                            <Select
                                                disabled={isSavingPost || isGeneratingPost}
                                                onValueChange={(value) => setStyleOfContactUuid(value)}
                                            >
                                                <SelectTrigger className="w-[180px]">
                                                    <SelectValue placeholder={t('feed.writer.select_contact')}/>
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectGroup>
                                                        {[...contacts].sort((a: Contact, b: Contact) => {
                                                            return (a.display_name ?? '').localeCompare((b.display_name ?? ''))
                                                        }).map((contact) => {
                                                            return (
                                                                <SelectItem key={contact.uuid} value={contact.uuid}>
                                                                    <div className="flex items-center gap-2">
                                                                        <ObjectAvatar
                                                                            object={contact}
                                                                        />

                                                                        {contact.display_name}
                                                                    </div>
                                                                </SelectItem>
                                                            )
                                                        })}
                                                    </SelectGroup>
                                                </SelectContent>
                                            </Select>
                                        </div>
                                    }
                                    <div className="flex gap-4 justify-between items-center">
                                        <Label>
                                            {t('feed.writer.text_length')}
                                        </Label>
                                        <Slider
                                            defaultValue={[textLength]}
                                            max={1}
                                            step={.01}
                                            className="w-[180px]"
                                            onValueChange={(value) => setTextLength(value[0])}
                                        />
                                    </div>

                                    <div className="flex gap-4 justify-end border-t pt-4">
                                        <Close asChild>
                                            <Button
                                                onClick={() => handleGeneratePost()}
                                                disabled={isSavingPost || isGeneratingPost}
                                                variant="outline"
                                            >
                                                <SparklesIcon className="size-5 mr-2"/>
                                                {t('feed.writer.magic.create')}
                                            </Button>
                                        </Close>
                                    </div>
                                </div>
                            </div>
                        </PopoverContent>
                    </Popover>

                    <UploadButton
                        onUpload={setPostImages}
                        disabled={isSavingPost || isGeneratingPost}
                        variant={toolbarButtonVariant}
                    />
                </div>
            </div>

            <div
                className="flex items-end justify-end gap-4"
            >
                <Button
                    asChild
                    variant="link" disabled={isSavingPost || isGeneratingPost}

                >
                    <Link to="/feed/posts" onClick={() => setOpenState(false)}>
                        {t('feed.writer.my_posts')}
                    </Link>
                </Button>
                <Button disabled={isSavingPost || isGeneratingPost || !post || post.length < 15}
                        onClick={() => {
                            handleSubmit(undefined, false);
                        }}
                        className="mt-4"
                        variant="outline"
                >
                    {t('feed.writer.save_continue')}
                </Button>
                <Button disabled={isSavingPost || isGeneratingPost || !post || post.length < 15}
                        onClick={() => {
                            handleSubmit(undefined, true);
                        }}
                        className="mt-4"
                >
                    {t('feed.writer.save')}
                </Button>
            </div>
        </form>
    )
}

export default PostWriter