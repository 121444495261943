import {Switch} from "@/components/ui/switch";
import React from "react";
import {Automation} from "@/api/automation.types";
import {usePatchAutomationMutation} from "@/api/automation";
import {useTranslation} from "react-i18next";

type AutomationItemStatusSwitchProps = {
    automation?: Automation;
}

const AutomationItemStatusSwitch = ({automation}: AutomationItemStatusSwitchProps) => {
    const [patchAutomation, {isLoading}] = usePatchAutomationMutation();
    const {t} = useTranslation();
    const switchOnOff = () => {
        if (isLoading || !automation) return;

        patchAutomation({
            uuid: automation.uuid,
            status: automation.status === "running" ? "paused" : "running"
        })
    }

    if (!automation) return <></>

    return (
        <label
            className="flex items-center gap-2"
        >
            <p>
                {t('automation.status.' + automation.status)}
            </p>
            <Switch
                checked={automation.status === "running"}
                onCheckedChange={switchOnOff}
            />
        </label>
    )
}

export default AutomationItemStatusSwitch;