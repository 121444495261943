import {FeedItem} from "@/api/feed.types";
import {useCommentPostMutation, useGetCommentsQuery} from "@/api/feed";
import {useEffect, useRef, useState} from "react";
import {Button} from "@/components/ui/button";
import {Textarea} from "@/components/ui/textarea";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import DateRenderer from "@/components/elements/DateRenderer";

type PostCommentsProps = {
    post: FeedItem
}

const PostComments = ({post}: PostCommentsProps) => {
    dayjs.extend(relativeTime)

    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const [commentText, setCommentText] = useState('')
    const [postComment, {isLoading: postCommentLoading}] = useCommentPostMutation()
    const submitComment = (e: any) => {
        e?.preventDefault()

        if (postCommentLoading) return
        postComment({post_uuid: post.uuid, comment: commentText}).then(() => {
            setCommentText('')
            refetch();
        });
    }

    const {data, refetch} = useGetCommentsQuery({post_uuid: post.uuid})

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.focus()
        }
    }, [textareaRef])

    return (
        <div>
            {/* Post comments */}
            <div>
                {data?.data.map((comment) => (
                    <div key={comment.id} className="flex items-center gap-4">
                        <p className="font-semibold">{comment.author.display_name}</p>
                        <div dangerouslySetInnerHTML={
                            {__html: comment.content}
                        }></div>
                        <p
                            className="text-sm opacity-50"
                        >
                            <DateRenderer date={comment.posted_at} format="fromNow"/>
                        </p>
                    </div>
                ))}
            </div>

            <form onSubmit={submitComment}>
                <Textarea
                    ref={textareaRef}
                    disabled={postCommentLoading}
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                    placeholder="Write a comment..."
                    className="w-full border rounded p-2"
                ></Textarea>

                <Button type="submit" disabled={postCommentLoading} className="mt-2">Post</Button>
            </form>
        </div>
    )
}

export default PostComments;