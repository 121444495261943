import {Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader} from "@/components/ui/dialog";
import {useTranslation} from "react-i18next";
import {DialogTitle} from "@radix-ui/react-dialog";
import {Button} from "@/components/ui/button";
import {useAutoFillContactMutation} from "@/api/contact";
import {Contact} from "@/api/contact.types";

type ContactEnrichDialogProps = {
    open: boolean;
    onOpenChange: (isOpen: boolean) => void;
    contact: Contact;
}

const ContactEnrichDialog = ({open, onOpenChange, contact}: ContactEnrichDialogProps) => {
    const {t} = useTranslation();
    const [enrich, {isLoading: enrichLoading}] = useAutoFillContactMutation();

    return (
        <Dialog
            open={open}
            onOpenChange={onOpenChange}
        >
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>
                        {t('contacts.enrich.title')}
                    </DialogTitle>
                    <DialogDescription>
                        {t('contacts.enrich.description')}
                    </DialogDescription>
                </DialogHeader>

                <DialogFooter>
                    <Button
                        onClick={() => {
                            enrich({uuid: contact.uuid}).then(() => {
                                onOpenChange(false)
                            });
                        }}
                        disabled={enrichLoading}
                    >
                        {t('contacts.enrich.submit')}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default ContactEnrichDialog;