import React, {useCallback, useState} from "react";
import {List, PipelineStage} from "@/api/list.types";
import StageCard from "@/modules/pipelines/components/StageCard";
import {ReactSortable} from "react-sortablejs";
import {
    useCreateListPipelineStageMutation,
    useMoveListContactToPipelineStageMutation,
    useRemoveListPipelineStageMutation
} from "@/api/list";
import useFullContactsList from "@/hooks/useFullContactsList";
import StageCardSkeleton from "@/modules/pipelines/components/StageCardSkeleton";
import {EditableTextValue} from "@/components/data/EditableTextValue";
import ObjectIcon from "@/components/data/ObjectIcon";
import {Plus, X} from "lucide-react";
import {Button} from "@/components/ui/button";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {Label} from "@/components/ui/label";
import {Input} from "@/components/ui/input";
import {useTranslation} from "react-i18next";

type StageContainerProps = {
    list: List
    stage: PipelineStage
}

const StageContainer = ({list, stage}: StageContainerProps) => {
    const {t} = useTranslation();

    const [newStageName, setNewStageName] = useState<string>('');

    const [removeStage] = useRemoveListPipelineStageMutation();
    const [createStage] = useCreateListPipelineStageMutation();

    const handleNewStageSubmit = (e: any) => {
        e.preventDefault();

        // create new stage
        createStage({
            listUuid: list.uuid,
            name: newStageName,
            insertBefore: stage.uuid
        })
    }

    const handleStageRemove = (e: any) => {
        e.preventDefault();

        // remove stage
        removeStage({
            listUuid: list.uuid,
            uuid: stage.uuid
        })
    }

    const {content: contacts, isLoading} = useFullContactsList({
        pipelineStageUuid: stage.uuid
    });

    const [moveContactToStage] = useMoveListContactToPipelineStageMutation()

    const sortableContacts = (contacts ?? []).map((contact) => ({
        ...contact,
        id: contact.uuid
    }));

    const setContactsOrder = useCallback(async (newContacts: typeof sortableContacts) => {
        const diffContacts = newContacts.filter((contact) => !sortableContacts.find((oldContact) => oldContact.uuid === contact.uuid));
        for (const contact of diffContacts) {
            await moveContactToStage({
                listUuid: list.uuid,
                contactUuid: contact.uuid,
                stageUuid: stage.uuid
            });
        }
    }, [list.uuid, moveContactToStage, stage.uuid, sortableContacts]);

    return (
        <div
            className="w-[300px] h-full flex-grow flex flex-col mr-2 relative"
        >
            {stage.order > 0 && (
                <div
                    className="absolute -top-12 -left-2 h-[500px] w-2 flex flex-col items-center opacity-10 hover:opacity-100 transition-opacity duration-200">

                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                variant={"ghost"}
                                className={"size-10 p-0 rounded-full"}
                            >
                                <Plus className="size-4"/>
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-80">
                            <form className="grid gap-4"
                                  onSubmit={handleNewStageSubmit}
                            >
                                <div className="space-y-2">
                                    <h4 className="font-medium leading-none">
                                        {t('pipelines.new_stage.title')}
                                    </h4>
                                    <p className="text-sm text-muted-foreground">
                                        {t('pipelines.new_stage.description')}
                                    </p>
                                </div>
                                <div className="grid gap-2">
                                    <div className="grid grid-cols-3 items-center gap-4">
                                        <Label htmlFor="name">
                                            {t('pipelines.new_stage.name')}
                                        </Label>
                                        <Input
                                            id="name"
                                            className="col-span-2 h-8"
                                            value={newStageName}
                                            onChange={(e) => setNewStageName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="flex justify-end">
                                    <Button
                                        type="submit"
                                    >
                                        {t('pipelines.new_stage.create')}
                                    </Button>
                                </div>
                            </form>
                        </PopoverContent>
                    </Popover>

                    <div className="flex-grow w-[1px] bg-gradient-to-b from-muted to-transparent"/>
                </div>
            )}

            <div
                className="mb-4 flex gap-4 items-center justify-between"
            >
                <p
                    className="text-sm bg-muted inline-flex items-center px-4 py-2 rounded-full"
                >
                    <ObjectIcon
                        className="mr-2"
                        model="pipeline_stage"
                        item={stage}
                        name="icon"
                        params={{
                            listUuid: list.uuid,
                        }}
                    />

                    <EditableTextValue params={{
                        listUuid: list.uuid,
                    }} name={'name'} item={stage} model={"pipeline_stage"}/>
                </p>

                {!stage.fixed && (
                    <div>
                        <Button
                            variant={"ghost"}
                            className={"size-10 p-0 rounded-full"}
                            onClick={handleStageRemove}
                        >
                            <X className="size-4"/>
                        </Button>
                    </div>
                )}
            </div>

            <div className="flex-1">
                {(!isLoading || contacts.length > 0) &&
                    <ReactSortable group="pipelineContacts" list={sortableContacts} setList={setContactsOrder}
                                   className="min-h-40"
                    >
                        {contacts?.map((contact) => (
                            <StageCard contact={contact} key={contact.uuid}/>
                        ))}
                    </ReactSortable>
                }

                {isLoading && Array.from({length: 7}).map((_, index) => (
                    <StageCardSkeleton key={index}/>
                ))}
            </div>
        </div>
    )
}

export default StageContainer