import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import {useHeaderContainerStore} from "@/components/layout/header/HeaderContainer";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router";
import {useGetConversationsQuery} from "@/api/message";
import {ScrollArea} from "@/components/ui/scroll-area";
import {clsx} from "clsx";
import Conversation from "@/modules/messages/components/Conversation";
import {useGetContactQuery} from "@/api/contact";
import ObjectAvatar from "@/components/elements/ObjectAvatar";
import Loader from "@/components/elements/Loader";

const Messages = () => {
    const {t} = useTranslation();
    const {data: conversations} = useGetConversationsQuery({});

    const {uuid} = useParams();

    const {data: contact, isLoading: contactLoading} = useGetContactQuery({uuid: uuid ?? ''}, {
        skip: !uuid,
        refetchOnMountOrArgChange: true,
    });

    const {
        setCrumbs,
        setTitle
    } = useHeaderContainerStore((state) => state) as any;

    useEffect(() => {
        setCrumbs([]);
        setTitle(t('messages.page_title'));
    }, [t, setCrumbs, setTitle]);

    return (
        <>
            <Helmet>
                <title>
                    {t('messages.page_title')}
                </title>
            </Helmet>

            <div
                className="flex h-full max-h-screen"
            >
                <ScrollArea
                    className="border-r w-[20em] flex-shrink-0 h-full p-2"
                >
                    {conversations?.map((conversation) => {
                        return (
                            <a
                                className={clsx({
                                    'flex p-4': true,
                                    'bg-muted/50': uuid === conversation.contact.uuid,
                                }, [
                                    'rounded-md'
                                ])}
                                href={`/messages/${conversation.contact.uuid}`}
                                key={conversation.contact.uuid}
                            >
                                <ObjectAvatar
                                    object={conversation.contact}
                                    className="mr-4 size-10"
                                />

                                <div
                                    className={clsx({
                                        'opacity-50': uuid !== conversation.contact.uuid
                                    })}
                                >
                                    <p>
                                        {conversation.contact.display_name}
                                    </p>

                                    <p className="text-xs opacity-50 text-ellipsis line-clamp-1">
                                        {conversation.latestMessage?.message}
                                    </p>
                                </div>
                            </a>
                        )
                    })}
                </ScrollArea>

                <div className="w-3/4 p-8">
                    {!contactLoading && contact ? (
                        <Conversation contact={contact} showNewMessage/>
                    ) : (
                        <div className="flex items-center justify-center h-full">
                            <Loader/>
                        </div>
                    )}
                </div>
            </div>
        </>

    )
}

export default Messages