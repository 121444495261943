import slugify from "slugify";

const productName = process.env.REACT_APP_NAME ?? 'app';

const constants = {
    SESSION_TOKEN: slugify(productName, {
        replacement: '_',
    }) + '_token',
    DARK_MODE: 'use_dark_mode'
}

export default constants;