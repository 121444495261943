import {Contact} from "@/api/contact.types";
import {useState} from "react";
import {Textarea} from "@/components/ui/textarea";
import {Checkbox} from "@/components/ui/checkbox";
import {useTranslation} from "react-i18next";
import {useSendMessageMutation} from "@/api/message";
import {Button} from "@/components/ui/button";

type SendMessageProps = {
    contact: Contact
    onSent?: (message?: string) => void
}

export const SendMessage = ({contact, onSent}: SendMessageProps) => {
    const channels = ['linkedin' /*, 'email'*/];

    const {t} = useTranslation();

    const [message, setMessage] = useState("")
    const [selectedChannels, setSelectedChannels] = useState<string[]>(['linkedin'])

    const [send, {isLoading}] = useSendMessageMutation()

    const sendMessage = (e: any) => {
        e.preventDefault()
        send({contact_uuid: contact.uuid, message, channels: selectedChannels})
            .then(() => {
                setMessage('')
                if (onSent) {
                    onSent(message)
                }
            });
    }

    return (
        <form
            onSubmit={sendMessage}
            className="flex flex-col gap-4"
        >
            <div>
                <Textarea
                    disabled={isLoading}
                    className="w-full"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
            </div>

            <div>
                {channels.map(channel => {
                    return (
                        <div className="items-top flex space-x-2"
                             key={channel}
                        >
                            <Checkbox
                                disabled={isLoading || channels.length === 1}
                                id={'channel_' + channel}
                                onClick={(e: any) => {
                                    if (e.target.checked) {
                                        setSelectedChannels([...selectedChannels, channel])
                                    } else {
                                        setSelectedChannels(selectedChannels.filter(c => c !== channel))
                                    }
                                }}
                                checked={selectedChannels.includes(channel)}
                            />
                            <div className="grid gap-1.5 leading-none">
                                <label
                                    htmlFor={'channel_' + channel}
                                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                >
                                    {t('contacts.messages.channels.' + channel)}
                                </label>
                                <p className="text-sm text-muted-foreground">
                                    {t('contacts.messages.channels.' + channel + '_description')}
                                </p>
                            </div>
                        </div>
                    )
                })}
            </div>

            <div>
                <Button
                    variant="outline"
                    disabled={isLoading}
                    type="submit"
                >
                    {t('contacts.messages.send')}
                </Button>
            </div>
        </form>
    )
}

export default SendMessage;