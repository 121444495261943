import {useTranslation} from "react-i18next";
import ChipElement from "@/components/elements/ChipElement";
import {Check, Clock, Pencil} from "lucide-react";

type PostStatusProps = {
    status: string
}

const PostStatus = ({status}: PostStatusProps) => {
    const {t} = useTranslation();

    const variant = status === 'published'
        ? 'success'
        : status === 'scheduled'
            ? 'danger'
            : status === 'draft'
                ? 'warning'
                : undefined;

    const icon = status === 'published' ? <Check/> : status === 'scheduled' ? <Clock/> : status === 'draft' ?
        <Pencil/> : undefined;

    return (
        <ChipElement icon={icon} label={t(`feed.post_status.${status}`)} variant={variant}/>
    )
}

export default PostStatus;