import {useTranslation} from "react-i18next";
import {createRef, useEffect, useState} from "react";
import {usePatchContactMutation} from "@/api/contact";
import {Contact} from "@/api/contact.types";
import {usePatchListPipelineStageMutation} from "@/api/list";
import {PipelineStage} from "@/api/list.types";
import {Pencil} from "lucide-react";
import {cn} from "@/lib/utils";

type EditableTextValueProps = {
    name: string;
    item: Contact | PipelineStage;
    params?: Record<string, string | number>;
    model: 'contact' | 'pipeline_stage';
    type?: 'text' | 'email' | 'tel' | 'url';
    onEditingChange?: (isEditing: boolean) => void;
    showPencil?: boolean;
}

export const EditableTextValue = ({
                                      onEditingChange,
                                      name,
                                      params,
                                      item,
                                      model,
                                      showPencil = true,
                                      type = 'text'
                                  }: EditableTextValueProps) => {
    const inputRef = createRef<HTMLInputElement>()

    const [isEditing, setIsEditing] = useState(false)
    const [inputValue, setInputValue] = useState((item as any)[name] ?? '')

    const {t} = useTranslation()

    const [patchContact, {
        isLoading: isUpdatingContact
    }] = usePatchContactMutation()

    const [patchPipelineStage, {
        isLoading: isUpdatingPipelineStage
    }] = usePatchListPipelineStageMutation()

    const isLoading = isUpdatingContact || isUpdatingPipelineStage

    const handleClick = (e: any) => {
        e?.stopPropagation()
        setIsEditing(true)
    }

    useEffect(() => {
        if (onEditingChange) {
            onEditingChange(isEditing)
        }
    }, [isEditing, onEditingChange])

    const onEdited = () => {
        if (model === 'contact') {
            patchContact({
                ...(params ?? {}),
                uuid: item.uuid,
                [name]: inputValue
            }).then((data) => {
                setIsEditing(false)
                setInputValue(((data as any).data?.[name]) ?? (item as any)[name] ?? '')
            })
        }

        if (model === 'pipeline_stage') {
            patchPipelineStage({
                ...(params ?? {}),
                uuid: item.uuid,
                [name]: inputValue
            }).then((data) => {
                setIsEditing(false)
                setInputValue(((data as any).data?.[name]) ?? (item as any)[name] ?? '')
            })
        }
    }

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus()
            inputRef.current.setSelectionRange(0, inputRef.current.value.length)
        }
    }, [isEditing])

    if (isEditing) {
        return (
            <span>
                <input
                    disabled={isLoading}
                    ref={inputRef}
                    className="text-inherit inline-block w-auto border-none bg-transparent outline-none"
                    type={type}
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value)
                    }}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                            onEdited()
                        }
                    }}
                    onBlur={onEdited}
                />
            </span>
        )
    }

    return (
        <span
            className={cn("cursor-pointer border-transparent hover:border-border relative group transition-opacity", {
                'border-b-2': showPencil
            })}
            onClick={handleClick}
        >
            {showPencil &&
                <Pencil className="size-3 opacity-0 group-hover:opacity-30 absolute top-1 -right-5 transition-opacity"/>
            }

            {inputValue}

            {!inputValue && (
                <span className="opacity-25 italic">
                    {t('common.fill_info')}
                </span>
            )}
        </span>
    )
}

