import {useEffect, useState} from "react";
import quotes from '../static/quotes.json'

const useInspiringQuote = () => {
    const [author, setAuthor] = useState<string>('')
    const [quote, setQuote] = useState<string>('')

    useEffect(() => {
        if (quote) return
        if (author) return

        // const localStorageKey = 'inspiring_quote'
        //
        // const storedData = localStorage.getItem(localStorageKey)
        // if (storedData) {
        //     const data = JSON.parse(storedData)
        //     if (data && data.timestamp && Date.now() - data.timestamp < 60 * 60 * 1000) {
        //         setAuthor(data.author)
        //         setQuote(data.quote)
        //         return
        //     }
        // }
        //
        // fetch('https://api.api-ninjas.com/v1/quotes?category=communication').then(async (response) => {
        //     const data = await response.json()
        //     if (!data || !data[0]) return
        //
        //     setAuthor(data[0].author)
        //     setQuote(data[0].quote)
        //
        //     localStorage.setItem(localStorageKey, JSON.stringify({
        //         author: data[0].author,
        //         quote: data[0].content,
        //         timestamp: Date.now()
        //     }))
        // }).catch(() => {
        //     setAuthor('')
        //     setQuote('')
        // });

        const randomIndex = Math.floor(Math.random() * quotes.length)
        setAuthor(quotes[randomIndex].from)
        setQuote(quotes[randomIndex].text)
    }, [author, quote]);

    return {author, quote}
}

export default useInspiringQuote