import {Plus} from "iconoir-react";
import {clsx} from "clsx";
import {Contact} from "@/api/contact.types";
import {useRef} from "react";

type ScoreChipPropsT = {
    contact: Contact,
    variant?: 'rounded' | 'rounded-bordered' | 'square'
}

const ScoreChip = ({contact, variant}: ScoreChipPropsT) => {
    const element = useRef<HTMLDivElement>(null);

    const score = contact?.score ?? 0;

    return (
        <div className="flex items-center" ref={element}>
            <span
                className={clsx([
                    "font-normal h-6 w-6 flex items-center justify-center",
                    'bg-muted',
                    'text-sm'
                ], {
                    'rounded-full': variant?.includes('rounded'),
                    'border': variant?.includes('bordered'),
                })}
            >
                {score >= 100
                    ? <span>🔥</span>
                    : score > 0
                        ? score
                        : <Plus className="h-5 w-auto opacity-50"/>
                }
            </span>
        </div>
    )
}

export default ScoreChip