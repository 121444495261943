import Notable from "@/components/elements/Notable/Notable";
import useFullContactsList from "@/hooks/useFullContactsList";
import {useGetContactFieldsQuery} from "@/api/dataset";
import {useContactSheet} from "@/modules/contacts/components/ContactSheet";
import useSocket from "@/hooks/useSocket";
import {useEffect} from "react";

const ContactsTable = () => {

    const {content, isLoading: fieldsLoading, refetch} = useFullContactsList();

    const {data: fields, isLoading: contactsLoading} = useGetContactFieldsQuery();

    const {setContactUuid} = useContactSheet((state) => state) as any;

    const {lastEventData: lastContactUpdateEventData} = useSocket('contact_updates', true)
    const {lastEventData: lastContactInsertEventData} = useSocket('contact_inserts', true)

    useEffect(() => {
        refetch();
    }, [lastContactUpdateEventData, lastContactInsertEventData, refetch])

    return <Notable
        fields={fields ?? []}
        data={content ?? []}
        fieldsLoading={fieldsLoading}
        dataLoading={contactsLoading}
        draggable={true}
        navigateTo={(uuid: string) => setContactUuid(uuid)}
    />
}

export default ContactsTable;