import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import DashboardWidgets from "./dashboard/components/DashboardWidgets";
import {useTranslation} from "react-i18next";
import {useHeaderContainerStore} from "@/components/layout/header/HeaderContainer";

const Dashboard = () => {

    const {t} = useTranslation();

    const {
        setCrumbs,
        setTitle
    } = useHeaderContainerStore((state) => state) as any;

    useEffect(() => {
        setCrumbs([]);
        setTitle(t('dashboard.page_title'));
    }, [t, setCrumbs, setTitle]);

    return (
        <>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>

            <div
                className="p-4"
            >
                <DashboardWidgets/>
            </div>
        </>
    )
}

export default Dashboard