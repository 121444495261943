import {Helmet} from "react-helmet";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useHeaderContainerStore} from "@/components/layout/header/HeaderContainer";
import ContactsTable from "@/modules/contacts/ContactsTable";

const Contacts = () => {
    const {t} = useTranslation();

    const {
        setCrumbs,
        setTitle
    } = useHeaderContainerStore((state) => state) as any;

    useEffect(() => {
        setCrumbs([]);
        setTitle(t('contacts.page_title'))
    }, [setCrumbs, setTitle, t]);

    return (
        <>
            <Helmet>
                <title>
                    {t('contacts.page_title')}
                </title>
            </Helmet>

            <div className="p-4">
                <ContactsTable/>
            </div>
        </>
    )
}

export default Contacts