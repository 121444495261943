import {useDeleteListMutation, useGetListQuery, usePatchListMutation} from "@/api/list";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle
} from "@/components/ui/dialog";
import {Label} from "@/components/ui/label";
import {Input} from "@/components/ui/input";
import {Button} from "@/components/ui/button";
import {useTranslation} from "react-i18next";
import {Textarea} from "@/components/ui/textarea";
import {useForm} from "react-hook-form";
import {Form, FormField, FormMessage} from "@/components/ui/form";
import {z} from "zod"
import {zodResolver} from "@hookform/resolvers/zod";
import {useEffect} from "react";

type ListEditPanelPropsT = {
    uuid: string,
    isOpen?: boolean,
    onClose?: () => void
}

const formSchema = z.object({
    name: z.string().min(2).max(50),
    description: z.string().max(255).min(0)
})

const ListEditPanel = ({uuid, isOpen, onClose}: ListEditPanelPropsT) => {
    const {data, isLoading} = useGetListQuery({uuid});
    const [updateList, {
        isLoading: isUpdating
    }] = usePatchListMutation();
    const {t} = useTranslation();

    const [deleteList, {
        isLoading: isDeleting
    }] = useDeleteListMutation();

    const globalLoading = isUpdating || isDeleting || isLoading;

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: '',
            description: '',
        },
    })

    useEffect(() => {
        if (data) {
            form.reset({
                name: '',
                description: '',
                ...data
            })
        }
    }, [data])

    const onOpenChange = () => {
        if (onClose) {
            onClose()
        }
    }

    const onSubmit = async (values: z.infer<typeof formSchema>): Promise<void> => {
        if (globalLoading) return;

        const updated = await updateList({
            ...values,
            uuid,
        })

        if ((updated as any)?.error) {
            return
        }

        if (onClose) {
            onClose()
        }
    }

    const removeList = async () => {
        const deleted = await deleteList({uuid})

        console.log('deleted', (deleted as any)?.error);

        if ((deleted as any)?.error) {
            return
        }

        if (onClose) {
            onClose()
        }
    }

    if (isLoading) {
        return null
    }

    return <Dialog
        open={isOpen}
        onOpenChange={onOpenChange}
    >
        <DialogContent className="sm:max-w-[425px]">
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <DialogHeader>
                        <DialogTitle>
                            {t('contacts.lists.edit.title')}
                        </DialogTitle>
                        <DialogDescription>
                            {t('contacts.lists.edit.description')}
                        </DialogDescription>
                    </DialogHeader>

                    <div className="grid gap-4 py-4">
                        <FormField
                            control={form.control}
                            name="name"
                            render={({field}) => (
                                <div className="grid grid-cols-4 items-center gap-4">

                                    <Label htmlFor="name" className="text-right">
                                        {t('contacts.lists.form.name')}
                                    </Label>

                                    <div className="col-span-3">
                                        <Input
                                            defaultValue={data?.name}
                                            disabled={globalLoading}
                                            {...field}
                                        />
                                        <FormMessage/>
                                    </div>
                                </div>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="description"
                            render={({field}) => (
                                <div className="grid grid-cols-4 items-center gap-4">
                                    <Label htmlFor="username" className="text-right">
                                        {t('contacts.lists.form.description')}
                                    </Label>

                                    <div className="col-span-3">
                                        <Textarea
                                            defaultValue={data?.description}
                                            disabled={globalLoading}
                                            {...field}
                                        />
                                        <FormMessage/>
                                    </div>
                                </div>
                            )}
                        />

                    </div>

                    <DialogFooter>
                        <Button
                            variant="destructive"
                            type={'button'}
                            onClick={removeList}

                            disabled={globalLoading}

                        >
                            {t('common.delete')}
                        </Button>
                        <Button type="submit"

                                disabled={globalLoading}
                        >
                            {t('common.save')}
                        </Button>
                    </DialogFooter>
                </form>
            </Form>
        </DialogContent>
    </Dialog>
}

export default ListEditPanel