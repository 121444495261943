import {clsx} from "clsx";
import {Skeleton} from "@/components/ui/skeleton";
import ObjectAvatar from "@/components/elements/ObjectAvatar";

type ObjectChipProps = {
    object: any;
    className?: string;
    loading?: boolean;
    tags?: string[];
}
const ObjectChip = ({tags, object, className, loading}: ObjectChipProps) => {
    return (
        <div
            className={clsx([
                "flex",
                "items-center",
                "gap-2",
                "border",
                "rounded-full",
                "p-1",
                "h-8"
            ], {
                "pr-3": !tags || !tags.length,
            })}
        >
            {loading ? (
                <Skeleton className="mx-1 w-20 h-4"/>
            ) : (

                <>
                    <ObjectAvatar object={object} className="size-6 text-xs"/>

                    <p
                        className={clsx([
                            "font-semibold",
                            "text-sm"
                        ])}
                    >
                        {object.display_name}
                    </p>

                    {(tags ?? []).map((tag) => (
                        <span
                            key={tag}
                            className={clsx([
                                "bg-muted",
                                "text-black/50",
                                "dark:text-white/50",
                                "rounded-full",
                                "px-3",
                                "text-xs",
                                "h-6",
                                "flex",
                                "items-center",
                            ])}
                        >
                            {tag}
                        </span>
                    ))}
                </>
            )}

        </div>
    )
}

export default ObjectChip;