import useUser from "@/hooks/useUser";
import {cn} from "@/lib/utils";
import ObjectAvatar from "@/components/elements/ObjectAvatar";
import {useMemo, useState} from "react";
import Markdown from "react-markdown";
import {Button} from "@/components/ui/button";
import linkedinPostBottomViewer from '../assets/linkedin-post-bottom-viewer.png';
import {Skeleton} from "@/components/ui/skeleton";

type LinkedInPostPreviewProps = {
    text: string;
    medias?: string[];
}

const LinkedInPostPreview = ({text, medias}: LinkedInPostPreviewProps) => {
    const {user} = useUser();

    const linesCount = useMemo(() => {
        return text.split("\n").reduce((acc, line) => {
            const lineLength = line.length;
            // For each line, break after max 46 characters
            if (acc + lineLength > 46) {
                acc += lineLength;
                return acc;
            }
            return acc + lineLength + 1;
        }, 0);
    }, [text]);

    const [showAll, setShowAll] = useState(false);

    return <div
        className={cn([
            "flex",
            "flex-col",
            "gap-3",
            "rounded-[10px]",
            "bg-[white]",
            "text-[black]",
            "p-[12px]",
            'font-[-apple-system,system-ui,"system-ui"]',
            'font-[16px]',
            'border-[1px]'
        ])}
    >
        <div className="flex items-center gap-3">
            <ObjectAvatar
                object={user}
                className="size-[48px] flex-shrink-0"
            />

            <div className="flex flex-col flex-grow">
                <p className="font-medium">
                    {!user?.display_name ? <Skeleton className="w-40 h-5 mb-1"/> : user?.display_name}
                </p>
                <p className="text-sm opacity-50 w-full">
                    <span className="truncate">
                        Lorem ipsum dolor sit amet
                    </span>
                </p>
            </div>
        </div>

        <div className={cn("relative" )}>
            <Markdown className={cn("[&>p]:line-clamp-[24px]", {
                "[&>p:nth-child(3)]:w-[75%] [&>p:nth-child(3)]:truncate": !showAll,
                "[&>p:nth-child(n+4)]:hidden": !showAll,
            })}>
                {text}
            </Markdown>

            {linesCount > 3 && (
                <Button
                    onClick={() => setShowAll(!showAll)}
                    className="absolute bottom-0 right-0 p-0 m-0 h-auto w-auto text-[black] opacity-50 font-normal hover:text-underline"
                    variant="link"
                >
                    {showAll ? "Show less" : "...more"}
                </Button>
            )}
        </div>

        <img src={linkedinPostBottomViewer} alt="linkedin post bottom viewer" className="w-full" />
    </div>
}

export default LinkedInPostPreview