import {Contact} from "@/api/contact.types";
import {FormEvent, useEffect, useState} from "react";
import WysiwygEditor from "@/components/data/WysiwygEditor";
import {Button} from "@/components/ui/button";
import {useTranslation} from "react-i18next";
import DateRenderer from "@/components/elements/DateRenderer";
import Markdown from "react-markdown";
import {XIcon} from "lucide-react";
import {Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle} from "@/components/ui/dialog";
import CreateFirst from "@/components/elements/CreateFirst";
import {
    useCreateCalendarEventMutation,
    useDeleteCalendarEventMutation,
    useGetCalendarEventsQuery
} from "@/api/calendar_event";
import {DatePicker} from "@/components/elements/DatePicker";
import HourSelector from "@/components/data/HourSelector";

type ContactRemindersProps = {
    contact: Contact
}
const ContactReminders = ({contact}: ContactRemindersProps) => {
    const [newReminderName, setNewReminderName] = useState('')
    const [newReminderDate, setNewReminderDate] = useState<Date | undefined>(undefined)

    const [showNewReminder, setShowNewReminder] = useState(false)

    const {data, isLoading: getLoading} = useGetCalendarEventsQuery({
        contactUuid: contact.uuid,
        type: "reminder"
    })

    const reminders = [...(data?.data ?? [])].sort((a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime())

    const [createEvent, {isLoading: createLoading}] = useCreateCalendarEventMutation()
    const [deleteEvent, {isLoading: deleteLoading}] = useDeleteCalendarEventMutation()

    const isLoading = getLoading || createLoading || deleteLoading

    const {t} = useTranslation()

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()

        createEvent({
            relatedContactUuids: [contact.uuid],
            name: newReminderName.charAt(0).toUpperCase() + newReminderName.slice(1),
            dueDate: newReminderDate,
            type: "reminder"
        }).then(() => {
            setNewReminderName('')
            setShowNewReminder(false)
            setNewReminderDate(undefined)
        }).catch(() => {
            // handle error
        });
    }

    useEffect(() => {
        if (showNewReminder) {
            setNewReminderName('')
        }
    }, [showNewReminder]);

    if (getLoading) return null

    return (
        <>
            {reminders?.length ? (
                <div className="flex justify-end gap-4">
                    <Button onClick={() => setShowNewReminder(true)} variant="outline">
                        {t('contacts.reminders.add_reminder')}
                    </Button>
                </div>) : (

                <CreateFirst
                    title={t('contacts.reminders.no_reminders_title')}
                    description={t('contacts.reminders.no_reminders_description')}
                >
                    <Button onClick={() => setShowNewReminder(true)} variant="outline">
                        {t('contacts.reminders.add_reminder')}
                    </Button>
                </CreateFirst>
            )}

            <div className="w-full max-w-[700px]">
                <div
                    className="space-y-4 mt-4"
                >
                    {reminders?.map(reminder => (
                        <div key={reminder.uuid} className="border rounded-sm">
                            <div className="flex justify-between items-center">
                                <p
                                    className="text-sm opacity-50 mb-2 p-4"
                                >
                                    <DateRenderer date={reminder.dueDate}/>
                                </p>

                                <Button
                                    variant="link"
                                    onClick={() => {
                                        deleteEvent({
                                            uuid: reminder.uuid,
                                        })
                                    }}>
                                    <XIcon
                                        className="size-6 text-foreground opacity-50"
                                    />
                                </Button>
                            </div>

                            <Markdown className="p-4 pt-0">{reminder.name}</Markdown>
                        </div>
                    ))}
                </div>
            </div>
            <Dialog
                open={showNewReminder}
                onOpenChange={setShowNewReminder}
            >
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>
                            {t('contacts.reminders.new_reminder')}
                        </DialogTitle>
                    </DialogHeader>

                    <form
                        onSubmit={handleSubmit}
                        className="space-y-4"
                    >
                        <WysiwygEditor value={newReminderName} onChange={setNewReminderName} outline={true}
                                       disabled={isLoading}/>

                        <div
                            className="flex gap-4 items-center"
                        >
                            <DatePicker value={newReminderDate} onChange={setNewReminderDate}
                                        minDate={new Date()}/>

                            <HourSelector onHourChange={(hour) => {
                                if (!newReminderDate) return

                                const date = new Date(newReminderDate)
                                date.setHours(hour)
                                setNewReminderDate(date)
                            }} onMinChange={(minute) => {
                                if (!newReminderDate) return

                                const date = new Date(newReminderDate)
                                date.setMinutes(minute)
                                setNewReminderDate(date)
                            }} disabled={isLoading}
                                          hour={newReminderDate?.getHours()} min={newReminderDate?.getMinutes()}
                                          minutesRange={5}
                            />
                        </div>

                        <DialogFooter>
                            <Button type="submit" onClick={handleSubmit} variant="outline"
                                    disabled={isLoading || !newReminderName}>
                                {t('contacts.reminders.submit_reminder')}
                            </Button>
                        </DialogFooter>
                    </form>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ContactReminders;