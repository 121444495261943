import {User, UserAdvice, UserQueue, UserServices, UserSetting} from "./user.types";
import {api} from "@/api/api";
import constants from "@/config/constants";

export const userApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getUser: builder.query<User | null, void>({
            query: () => `user`,
            // transformResponse: (response: User | null) => {
            //     if (!response) {
            //         localStorage.removeItem(constants.SESSION_TOKEN);
            //     }
            //
            //     return response;
            // },
            providesTags: ["User"]
        }),
        getUserActionToken: builder.mutation<{ token: string }, void>({
            query: () => ({
                url: `user/action-token`,
                method: "POST",
            }),
        }),
        logoutUser: builder.mutation<void, void>({
            query: () => ({
                url: `user/logout`,
                method: "POST",
            }),
            transformResponse: () => {
                localStorage.removeItem(constants.SESSION_TOKEN);
            },
            invalidatesTags: ["User"],
        }),
        // registerUser: builder.mutation<User, UserRequest>({
        //     query: (body) => ({
        //         url: `user/register`,
        //         method: "POST",
        //         body,
        //     }),
        //     transformResponse: (response: User) => {
        //         if (response.user_token) {
        //             localStorage.setItem(constants.SESSION_TOKEN, response.user_token.token);
        //         }
        //
        //         return response;
        //     },
        //     invalidatesTags: ["User"],
        // }),
        // patchUser: builder.mutation<User, Partial<UserRequest>>({
        //     query: (body) => ({
        //         url: `user`,
        //         method: "PATCH",
        //         body,
        //     }),
        //     invalidatesTags: ["User"],
        // }),
        // loginUser: builder.mutation<User, UserLoginRequest>({
        //     query: (body) => ({
        //         url: `user/login`,
        //         method: "POST",
        //         body,
        //     }),
        //     transformResponse: (response: User) => {
        //         if (response.user_token) {
        //             localStorage.setItem(constants.SESSION_TOKEN, response.user_token.token);
        //         }
        //
        //         return response;
        //     },
        //     invalidatesTags: ["User"],
        // }),
        getUserSettings: builder.query<UserSetting, void>({
            query: () => `user/settings`,
            providesTags: ["User"],
        }),
        setUserSettings: builder.mutation<void, Partial<UserSetting>>({
            query: (body) => ({
                url: `user/settings`,
                method: "POST",
                body,
            }),
            invalidatesTags: ["User"],
        }),
        getUserServices: builder.query<UserServices, void>({
            query: () => `user/services`,
            providesTags: ["User"],
        }),
        getUserQueue: builder.query<UserQueue, void>({
            // @ts-ignore
            query: () => `user/queue`,
        }),
        getUserStatistics: builder.query<any, void>({
            query: () => `user/statistics`,
            providesTags: ["User", "Contacts"],
        }),
        getUserAdvices: builder.query<{
            advices: UserAdvice[]
        }, void>({
            query: () => `user/advices`,
            providesTags: ["User", 'UserAdvices'],
        }),
        patchUserAdvices: builder.mutation<UserAdvice, any>({
            query: (body) => ({
                url: `user/advices/${body.uuid}`,
                method: "PATCH",
                body,
            }),
            invalidatesTags: ['UserAdvices'],
        }),
    }),
});

export const {
    useGetUserQuery,
    useLogoutUserMutation,
    useGetUserSettingsQuery,
    useSetUserSettingsMutation,
    useGetUserActionTokenMutation,
    useGetUserServicesQuery,
    useGetUserQueueQuery,
    useGetUserStatisticsQuery,
    useGetUserAdvicesQuery,
    usePatchUserAdvicesMutation,
} = userApi;
