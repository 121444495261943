import {useTranslation} from "react-i18next";
import {useHeaderContainerStore} from "@/components/layout/header/HeaderContainer";
import {useEffect, useMemo, useState} from "react";
import {Helmet} from "react-helmet";
import {useGetUserSettingsQuery} from "@/api/user";
import {Dialog, DialogContent, DialogHeader, DialogTitle} from "@/components/ui/dialog";
import {DialogDescription} from "@radix-ui/react-dialog";
import QueueSettings from "@/modules/feed/components/QueueSettings";
import {Button} from "@/components/ui/button";
import dayjs from "dayjs";
import {usePostsQuery, useUpdatePostMutation} from "@/api/writer";
import {UserPost} from "@/api/writer.types";
import {clsx} from "clsx";
import {useWriterDialogStore} from "@/modules/feed/components/PostWriterDialog";


const MyQueuePage = () => {
    const {t} = useTranslation();

    const {
        setCrumbs,
    } = useHeaderContainerStore((state) => state) as any;

    const {setOpenState: setWriterOpenState} = useWriterDialogStore((state) => state) as any;

    const [updatePost] = useUpdatePostMutation();

    const onDrop = (date: Date, slot: any, postUuid: string) => {
        const scheduledAt = dayjs(date).set('hour', parseInt(slot.time.split(':')[0])).set('minute', parseInt(slot.time.split(':')[1]))
            .subtract(dayjs().utcOffset(), 'minute').toDate();
        updatePost({
            uuid: postUuid,
            status: 'scheduled',
            scheduledAt
        })
    }

    useEffect(() => {
        setCrumbs([{
            label: t('feed.page_title'),
            to: "/feed"
        }, {
            label: t('feed.my_queue.page_title'),
            to: "/feed/queue"
        }]);
    }, [t, setCrumbs]);

    const {data: userSettings, isLoading} = useGetUserSettingsQuery();

    const [isSettingsOpen, setIsSettingsOpen] = useState(false);

    const queueSettings = useMemo<any>(() => userSettings?.postsQueue ?? {}, [userSettings]);
    const queueDays = useMemo<string[]>(() => {
        const days: string[] = [];
        for (const slot of queueSettings?.slots ?? []) {
            for (const day of slot.days) {
                if (!days.includes(day)) {
                    days.push(day);
                }
            }
        }

        return days;
    }, [queueSettings]);

    useEffect(() => {
        if (!isLoading && (!queueSettings || !Object.keys(queueSettings).length)) {
            setIsSettingsOpen(true);
        }
    }, [isLoading, queueSettings]);

    const {data: scheduledPosts} = usePostsQuery({
        page: 1,
        status: "scheduled"
    });

    const {data: draftPosts} = usePostsQuery({
        page: 1,
        status: "draft"
    });

    const {data: publishedPosts} = usePostsQuery({
        page: 1,
        status: "published"
    });

    const allPosts = useMemo(() => {
        return [
            ...(scheduledPosts?.data ?? []),
            ...(draftPosts?.data ?? []),
            ...(publishedPosts?.data ?? [])
        ]
    }, [scheduledPosts, draftPosts, publishedPosts]);

    const nextDatesAmount = 50;

    const nextDates = useMemo(() => {
        const dates: {
            date: Date,
            slots: {
                time: string,
                posts: UserPost[],
                temporary?: boolean
            }[]
        }[] = [];
        const today = dayjs().startOf('day');
        for (let i = 0; i < nextDatesAmount; i++) {
            const checkDate = today.add(i, 'day');
            const checkDateDay = checkDate.format('dddd').toLowerCase();

            if (queueDays.includes(checkDateDay)) {
                const slots = (queueSettings.slots.filter((slot: any) => slot.days.includes(checkDateDay))
                    .map((slot: any) => {
                        const posts = allPosts.filter((post: any) => dayjs(post.scheduled_at) === checkDate).sort((a, b) => {
                            return dayjs(a.scheduled_at).diff(dayjs(b.scheduled_at));
                        });

                        const hours = slot.time.split(':')[0];
                        const minutes = slot.time.split(':')[1];

                        const formattedTime = dayjs().set('hour', hours).set('minute', minutes).format('HH:mm');

                        return {
                            time: formattedTime,
                            posts: posts
                        }
                    }));

                dates.push({
                    date: checkDate.toDate(),
                    slots
                });
            }
        }

        const postsFromNow = allPosts.filter((post: any) => dayjs(post.scheduled_at).isAfter(dayjs()));
        for (const post of postsFromNow) {
            const postDate = dayjs(post.scheduled_at);
            let inDates = dates.find((date) => dayjs(date.date).isSame(postDate, 'day'));
            if (!inDates) {
                dates.push({
                    date: postDate.toDate(),
                    slots: []
                });

                inDates = dates.find((date) => dayjs(date.date).isSame(postDate, 'day'));
            }
            const postTime = postDate.add(dayjs().utcOffset(), 'minute')
                .format('HH:mm');
            let inSlot = inDates?.slots.find((slot) => slot.time === postTime);
            if (!inSlot) {
                inDates?.slots.push({
                    time: postTime,
                    posts: [],
                    temporary: true
                });

                inSlot = inDates?.slots.find((slot) => slot.time === postTime);
            }
            let inPosts = inSlot?.posts.find((p) => p.uuid === post.uuid);
            if (!inPosts) {
                inSlot?.posts.push(post);
            }
        }

        return dates.sort((a, b) => {
            return dayjs(a.date).diff(dayjs(b.date));
        }).map((date) => {
            return {
                date: date.date,
                slots: date.slots.sort((a, b) => {
                    return dayjs(a.time, 'HH:mm').diff(dayjs(b.time, 'HH:mm'));
                })
            }
        });
    }, [allPosts, queueDays, queueSettings.slots]);

    return (
        <>
            <Helmet>
                <title>{t('feed.my_posts.page_title')}</title>
            </Helmet>

            <div className="p-4">
                <div className="mb-8 flex justify-end items-center gap-2">
                    <Button
                        onClick={() => setIsSettingsOpen(true)}
                        variant="outline"
                    >
                        {t('feed.my_queue.settings_button')}
                    </Button>

                    <Button
                        onClick={() => setWriterOpenState(true)}
                    >
                        {t('feed.my_posts.write_post')}
                    </Button>
                </div>

                <div className="space-y-8 max-w-[640px]">
                    {nextDates.map((date, index) => (
                            <div key={index}>
                                <p className="uppercase text-muted-foreground text-sm text-bold mb-4">{dayjs(date.date).format('dddd LL')}</p>

                                <div className="space-y-4">
                                    {date.slots.map((slot, index: number) => (
                                        <div key={index} className="flex gap-4">
                                            <p className={clsx("w-24 text-lg flex-shrink-0", {
                                                'italic': slot.temporary,
                                                'text-muted-foreground': slot.temporary,
                                            })}>
                                                {slot.time}
                                                {slot.temporary && <small className="block">
                                                    {t('feed.my_queue.temporary_slot')}
                                                </small>}
                                            </p>
                                            <div
                                                className="flex-grow rounded-lg bg-muted min-h-12 p-1 flex gap-2"

                                                onDragOver={(e) => {
                                                    e.preventDefault();
                                                    // setHasDragOver(true);
                                                }}
                                                onDragLeave={() => {
                                                    // setHasDragOver(false);
                                                }}
                                                onDrop={(e) => {
                                                    e.preventDefault();
                                                    // setHasDragOver(false);
                                                    onDrop(date.date, slot, e?.dataTransfer?.getData('text/plain'));
                                                }}
                                            >
                                                {slot.posts.map((post, index: number) => (
                                                    <div key={index}
                                                         className="p-2 line-clamp-4 bg-background rounded-md text-muted-foreground text-xs cursor-move"
                                                         draggable="true"
                                                         onDragStart={(e) => {
                                                             e.dataTransfer.setData('text/plain', post.uuid)
                                                         }}
                                                    >
                                                        {post.content}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>

            <Dialog
                open={isSettingsOpen}
                onOpenChange={setIsSettingsOpen}
            >
                <DialogContent
                    className="w-[720px] max-w-full"
                >
                    <DialogHeader>
                        <DialogTitle>
                            {t('feed.my_queue.settings_dialog_title')}
                        </DialogTitle>
                        <DialogDescription>
                            {t('feed.my_queue.settings_dialog_description')}
                        </DialogDescription>
                    </DialogHeader>

                    <QueueSettings/>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default MyQueuePage