import {FeedItem} from "@/api/feed.types";
import {Button} from "@/components/ui/button";
import {Heart, HeartSolid} from "iconoir-react";
import {useState} from "react";
import {useLikePostMutation} from "@/api/feed";
import {useTranslation} from "react-i18next";

type LikeButtonProps = {
    post: FeedItem
    initialStatus?: boolean
}

const LikeButton = ({post, initialStatus}: LikeButtonProps) => {
    const {t} = useTranslation();

    const [loading, setLoading] = useState(false);
    const [liked, setLiked] = useState(!!initialStatus);

    const [like] = useLikePostMutation()
    const onLike = () => {
        setLoading(true);

        like({post_uuid: post.uuid}).then((response) => {
            setLiked((response as any)?.data?.status)
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    };

    return (
        <Button
            variant="outline"
            disabled={loading}
            onClick={onLike}
        >
            {liked ? <HeartSolid className="mr-2 text-red-600"/> : <Heart className="mr-2"/>}
            {liked ? t('feed.liked') : t('feed.like')}
        </Button>
    )
}

export default LikeButton;