import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import {useHeaderContainerStore} from "@/components/layout/header/HeaderContainer";
import {useGetAutomationQuery} from "@/api/automation";
import {clsx} from "clsx";
import {useParams} from "react-router";
import AutomationItemStatusSwitch from "@/modules/automation/components/AutomationItemStatusSwitch";
import AutomationActions from "@/modules/automation/components/AutomationActions";
import UserServices from "@/modules/user/panels/UserServices";

const Automation = () => {
    const {t} = useTranslation();

    const {uuid} = useParams();

    const {
        setCrumbs
    } = useHeaderContainerStore((state) => state) as any;

    const {data: automation} = useGetAutomationQuery({
        uuid: uuid ?? ''
    });

    useEffect(() => {
        if (automation) {
            setCrumbs([{
                label: t('automation.page_title'),
                to: '/automation'
            }, {
                label: automation.name,
                to: `/automation/${automation.uuid}`
            }]);
        } else {
            setCrumbs([{
                label: t('automation.page_title'),
                to: '/automation'
            }]);
        }
    }, [setCrumbs, t, automation]);

    return (
        <>
            <Helmet>
                <title>
                    {t('automation.page_title')}
                </title>
            </Helmet>

            <div
                className={clsx([
                    "flex",
                    "items-center",
                    "justify-end",
                    "p-4",
                    "w-full",
                    "pb-0"
                ])}>
                <AutomationItemStatusSwitch automation={automation}/>
            </div>

            <div className={clsx([
                "p-4",
            ])}>
                {automation &&
                    <AutomationActions
                        automation={automation}
                    />
                }
            </div>

            <div
                className={clsx([
                    "p-8",
                    "border-t",
                    'mt-16'
                ])}
            >
                <p>(tmp) Make sure you are connected to the needed services.</p>
                <UserServices/>
            </div>
        </>
    )
}

export default Automation