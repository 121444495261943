import {io} from 'socket.io-client';
import {useEffect, useState} from "react";
import useUser from "@/hooks/useUser";

const useSocket = (eventName: string, userSpecific = false) => {
    const [lastEventData, setLastEventData] = useState<any>(null);

    const backendUrl = (process.env.REACT_APP_API_HOST ?? '')
    const {user} = useUser();

    useEffect(() => {
        const socket = io(backendUrl);
        socket.on((user?.uuid ? user?.uuid + '.' : '') + eventName, setLastEventData);

        return () => {
            socket.disconnect();
        }
    }, [backendUrl, eventName, user, userSpecific])

    return {
        lastEventData
    }
}

export default useSocket;