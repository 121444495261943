import {FeedItem, ItemMedia} from "@/api/feed.types";
import VideoPlayer from "@/components/elements/VideoPlayer";
import {Button} from "@/components/ui/button";
import {clsx} from "clsx";
import {ExternalLinkIcon} from "lucide-react";
import PostItem from "@/modules/feed/components/PostFeed/PostItem";

type PostMediasProps = {
    post: FeedItem
}

const SharedPost = ({shared_post}: ItemMedia) => {
    if (!shared_post) return null;

    return (
        <div className="bg-muted/50 rounded-sm">
            <PostItem post={shared_post}
                      withInteractions={false}
                      withExternaLink={false}
            />
        </div>
    )
}
const ExternalVideo = ({external_video}: ItemMedia) => {
    if (!external_video) return null;

    return (
        <a href={external_video.video_url} target="_blank" rel="noreferrer"
           className="relative block"
        >
            <img
                loading="lazy"
                src={external_video.thumbnail_url}
                className="rounded-sm w-full h-auto"
                alt="External Video"
            />
            <ExternalLinkIcon className="absolute top-4 right-4 drop-shadow-sm text-white"/>
        </a>
    )
}

const PostArticle = ({article}: ItemMedia) => {
    if (!article) return null;

    return (
        <a
            href={article.url}
            target="_blank"
            className={clsx([
                "block",
                'bg-muted',
                'rounded-sm'
            ])} rel="noreferrer"
        >
            {article.image_url &&
                <img
                    loading="lazy"
                    src={article.image_url}
                    className="rounded-t-sm w-full h-auto"
                    alt={article.title}
                />
            }

            {(article.title || article.description) &&
                <div className="p-4">
                    {article.title &&
                        <p className="text-lg font-semibold">
                            {article.title}
                        </p>
                    }
                    {article.description &&
                        <p>
                            {article.description}
                        </p>
                    }
                </div>
            }
        </a>
    )
}

const PostImages = ({images}: ItemMedia) => {
    if (!images?.length) {
        return null
    }
    if (images.length === 1) {
        return <img
            loading="lazy" src={images[0].url} className="rounded-sm w-full h-auto" alt={images[0].alt}/>
    }
    return (
        <div className="grid grid-cols-3 gap-2">
            {images?.map((image) => (
                <img
                    loading="lazy"
                    key={image.url}
                    alt={image.alt}
                    src={image.url} className="rounded-sm w-full h-auto aspect-square object-cover"/>
            ))}
        </div>
    )
}

const PostVideo = ({video}: ItemMedia) => {
    if (!video) {
        return null
    }

    return <VideoPlayer
        className="rounded-sm"
        playOnVisible={true}
        videoUrl={{
            mp4: video.video_url
        }} posterUrl={video.thumbnail_url}/>
}

const PostDocument = ({document}: ItemMedia) => {
    if (!document) {
        return null
    }

    return (
        <div
            className="flex items-center w-full justify-center gap-2 p-4">

            <Button asChild>
                <a href={document.url} target="_blank" rel="noreferrer">
                    {document.title}
                </a>
            </Button>
        </div>
    )
}

const PostMedias = ({post}: PostMediasProps) => {
    const medias = post.medias ?? []

    if (medias.length === 0) {
        return null
    }

    return (
        <div className="p-4 pt-0">
            {medias.map((media, index) => {
                if (media.type === 'image') {
                    return <PostImages {...media}
                                       key={index}
                    />
                }

                if (media.type === 'video') {
                    return <PostVideo {...media}
                                      key={index}
                    />
                }

                if (media.type === 'document') {
                    return <PostDocument {...media}
                                         key={index}
                    />
                }

                if (media.type === 'article') {
                    return <PostArticle {...media}
                                        key={index}
                    />
                }

                if (media.type === 'external_video') {
                    return <ExternalVideo {...media}
                                          key={index}
                    />
                }

                if (media.type === 'shared_post') {
                    return <SharedPost {...media}
                                       key={index}
                    />
                }

                return null
            })}
        </div>
    )
}

export default PostMedias