import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import {useHeaderContainerStore} from "@/components/layout/header/HeaderContainer";
import {useTranslation} from "react-i18next";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "@/components/ui/tabs";
import {clsx} from "clsx";
import {Card, CardContent, CardHeader, CardTitle} from "@/components/ui/card";
import UserServices from "@/modules/user/panels/UserServices";

const User = () => {
    const {t} = useTranslation();


    const {
        setCrumbs,
        setTitle
    } = useHeaderContainerStore((state) => state) as any;

    useEffect(() => {
        setCrumbs([]);
        setTitle(t('user.page_title'))
    }, [setCrumbs, setTitle, t]);

    return (
        <>
            <Helmet>
                <title>
                    {t('user.page_title')}
                </title>
            </Helmet>

            <Tabs defaultValue="account" className={clsx([
                'p-4'
            ])}>
                <TabsList>
                    <TabsTrigger value="account">
                        {t('user.account')}
                    </TabsTrigger>
                    <TabsTrigger value="services">
                        {t('user.services')}
                    </TabsTrigger>
                </TabsList>
                <TabsContent value="account">
                    <Card>
                        <CardHeader>
                            <CardTitle>  {t('user.account')}</CardTitle>
                        </CardHeader>
                        <CardContent>

                        </CardContent>
                    </Card>
                </TabsContent>
                <TabsContent value="services">
                    <Card>
                        <CardHeader>
                            <CardTitle>  {t('user.services')}</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <UserServices/>
                        </CardContent>
                    </Card>
                </TabsContent>
            </Tabs>

        </>

    )
}

export default User