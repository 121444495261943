import {useGetUserQueueQuery} from "@/api/user";
import {useEffect} from "react";
import {BellIcon} from "lucide-react";
import {badgeVariants} from "@/components/ui/badge";
import {clsx} from "clsx";
import {Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger} from "@/components/ui/sheet";
import {useTranslation} from "react-i18next";
import NotificationCard from "@/modules/user/components/NotificationCard";
import dayjs from "dayjs";
import useSocket from "@/hooks/useSocket";
import {toast} from "sonner";
import {QueueTask} from "@/api/user.types";

const NotificationsPanel = () => {
    const {t} = useTranslation();

    const {data: queue, isLoading: queueLoading, refetch: refetchQueue} = useGetUserQueueQuery();

    const {lastEventData: lastQueueEventData} = useSocket('queue', true)
    const {lastEventData: lastNotificationEventData} = useSocket('notification', true)

    useEffect(() => {
        refetchQueue()
        setTimeout(() => {
            refetchQueue()
        }, 5000)
    }, [lastQueueEventData, refetchQueue])

    const hasNotCompletedTasks = queue && queue.length && queue.some((queueTask) => queueTask.status === 'pending' || queueTask.status === 'running');

    const isLoading = queueLoading && !queue;

    const unreadCount = 0;

    const isDisabled = isLoading || (!unreadCount && !hasNotCompletedTasks);


    // Notification toast
    useEffect(() => {
        if (lastNotificationEventData && lastNotificationEventData.type === 'task') {
            const task = lastNotificationEventData.object as QueueTask;

            console.log('task', task)

            if (task.status === 'failed') {
                toast.error(t('notifications.titles.' + task.action + '_' + task.status, task), {
                    description: t('notifications.messages.' + task.action + '_' + task.status, task),
                })
            } else if (task.status === 'completed') {
                toast.success(t('notifications.titles.' + task.action + '_' + task.status, task), {
                    description: t('notifications.messages.' + task.action + '_' + task.status, task),
                })
            } else {
                toast(t('notifications.titles.' + task.action + '_' + task.status, task), {
                    description: t('notifications.messages.' + task.action + '_' + task.status, task),
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastNotificationEventData])

    return (
        <Sheet>
            <SheetTrigger>
                <div
                    className={clsx([
                        'relative',
                        'cursor-pointer',
                    ], {
                        'opacity-50': isDisabled
                    })}>
                    <BellIcon
                        className={clsx([
                            'size-5'
                        ], {
                            '-translate-x-2': !isDisabled
                        })}
                    />
                    {!isDisabled &&
                        <span
                            className={clsx(badgeVariants({variant: hasNotCompletedTasks ? "outline" : "destructive"}), [
                                '!size-5',
                                'absolute',
                                'top-0',
                                'right-0',
                                'translate-x-2',
                                '-translate-y-2',
                                "rounded-full",
                                "flex",
                                "items-center",
                                "justify-center",
                                "text-sm"
                            ])}>
                            {
                                !hasNotCompletedTasks && unreadCount > 0 && unreadCount
                            }
                    </span>
                    }
                </div>
            </SheetTrigger>
            <SheetContent>
                <SheetHeader>
                    <SheetTitle>
                        {t('notifications.title')}
                    </SheetTitle>
                </SheetHeader>

                <div
                    className="mt-4 space-y-4"
                >
                    {queue && queue
                        .map((queueTask) => (
                            <NotificationCard key={'q' + queueTask.id}
                                              title={t('notifications.titles.' + queueTask.action + '_' + queueTask.status, queueTask)}
                                              message={t('notifications.messages.' + queueTask.action + '_' + queueTask.status, queueTask)}
                                              notifiedAt={queueTask.updatedAt}
                                              image={queueTask.imageUrl}
                                              link={queueTask.contact ? '/contacts/' + queueTask.contact.uuid : ''}
                                              type={queueTask.status === 'running' ? "progress" : "default"}
                                              read={queueTask.status === 'completed' && dayjs(queueTask.updatedAt).subtract(3, 'minute').isBefore(dayjs())}
                            />
                        ))}
                </div>

                {!isLoading && !queue?.length &&
                    <div
                        className="text-center text-muted"
                    >
                        {t('notifications.empty')}
                    </div>
                }
            </SheetContent>
        </Sheet>
    )
}

export default NotificationsPanel;