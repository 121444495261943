import {useGetUserStatisticsQuery} from "@/api/user";
import {ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent} from "@/components/ui/chart";
import {Label, PolarRadiusAxis, RadialBar, RadialBarChart} from "recharts";

const chartConfig = {
    contacts: {
        label: "Warming Contacts",
        color: "hsl(var(--chart-1))",
    },
    warmContacts: {
        label: "Warm Contacts",
        color: "hsl(var(--chart-2))",
    },
} satisfies ChartConfig

const WarmingContacts = () => {
    const {data, isLoading} = useGetUserStatisticsQuery();

    const lastEntry = data?.[data.length - 1]

    const chartData = [{
        contacts: (lastEntry?.totalContacts ?? 0) - (lastEntry?.totalWarmContacts ?? 0),
        warmContacts: lastEntry?.totalWarmContacts ?? 0,
    }]

    if (isLoading) return <></>;

    return (
        <div
            className="overflow-hidden w-full h-[220px]"
        >
            <ChartContainer
                config={chartConfig}
                className="mx-auto aspect-square w-full max-w-[250px]"
            >
                <RadialBarChart
                    data={chartData}
                    endAngle={180}
                    innerRadius={80}
                    outerRadius={130}
                >
                    <ChartTooltip
                        cursor={false}
                        content={<ChartTooltipContent hideLabel/>}
                    />
                    <PolarRadiusAxis tick={false} tickLine={false} axisLine={false}>
                        <Label
                            content={({viewBox}) => {
                                if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                                    return (
                                        <text x={viewBox.cx} y={viewBox.cy} textAnchor="middle">
                                            <tspan
                                                x={viewBox.cx}
                                                y={(viewBox.cy || 0) - 16}
                                                className="fill-foreground text-2xl font-bold"
                                            >
                                                {lastEntry?.totalContacts}
                                            </tspan>
                                            <tspan
                                                x={viewBox.cx}
                                                y={(viewBox.cy || 0) + 4}
                                                className="fill-muted-foreground"
                                            >
                                                Contacts
                                            </tspan>
                                        </text>
                                    )
                                }
                            }}
                        />
                    </PolarRadiusAxis>
                    <RadialBar
                        dataKey="contacts"
                        stackId="a"
                        cornerRadius={0}
                        fill="hsl(var(--chart-1))"
                        className="stroke-transparent stroke-2"
                    />
                    <RadialBar
                        dataKey="warmContacts"
                        fill="hsl(var(--chart-2))"
                        stackId="a"
                        cornerRadius={0}
                        className="stroke-transparent stroke-2"
                    />
                </RadialBarChart>
            </ChartContainer>
        </div>
    )
}

export default WarmingContacts;