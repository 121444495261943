import {ContactActivity} from "@/api/contact.types";
import {FireFlame} from "iconoir-react";
import DateRenderer from "@/components/elements/DateRenderer";
import {useTranslation} from "react-i18next";
import {Button} from "@/components/ui/button";
import {Link} from "react-router-dom";

type ContactActivityItemProps = {
    activity: ContactActivity;
};

const ContactActivityItem = ({activity}: ContactActivityItemProps) => {
    const {t} = useTranslation();

    const link = activity.related_content?.type === 'social_post' && activity.related_content?.uuid ?
        `/feed/${activity.related_content?.uuid}` : null;

    return (
        <li className="mb-8 ms-10">
        <span
            className="absolute text-primary dark:text-white flex items-center justify-center size-10 p-2 bg-muted/50 rounded-full -start-5 ring-8 ring-background">
            <FireFlame/>
        </span>
            <h3 className="flex items-center mb-1 text-lg font-semibold">
                {t('interactions.actions.' + activity.action)}
            </h3>
            <p
                className="block mb-2 text-sm font-normal leading-none opacity-50">

                <DateRenderer date={activity.tracked_at}/>
            </p>
            <div className="mb-4 text-base font-normal">
                {activity.related_content?.text &&
                    <p
                        className="bg-muted opacity-50 p-2 text-sm italic line-clamp-3">

                        {activity.related_content?.text}
                    </p>
                }
                {link &&
                    <div
                        className="flex justify-end w-full"
                    >
                        <Button variant="link" asChild>
                            <Link to={link}>
                                {t('interactions.view_post')}
                            </Link>
                        </Button>
                    </div>
                }
            </div>
        </li>
    );
};

export default ContactActivityItem;