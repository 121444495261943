import {api} from "@/api/api";
import {AutomationAction, Field, Filter} from "@/api/dataset.types";

export const contactApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getContactFields: builder.query<Field[], void>({
            query: () => `datasets/contact-fields`,
            providesTags: ["Datasets", "Contacts"],
        }),
        getContactFilters: builder.query<Filter[], void>({
            query: () => `datasets/contact-filters`,
            providesTags: ["Datasets", "Contacts"],
        }),
        getAvailableAutomationActions: builder.query<AutomationAction[], void>({
            query: () => `datasets/automation-actions`,
            providesTags: ["Datasets"],
        }),
        getUserSubjects: builder.query<string[], void>({
            query: () => `datasets/user-subjects`,
            providesTags: ["Datasets", "User"],
        }),
    }),
});

export const {
    useGetContactFieldsQuery,
    useGetContactFiltersQuery,
    useGetAvailableAutomationActionsQuery,
    useGetUserSubjectsQuery,
} = contactApi;
