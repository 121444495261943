import {Avatar, AvatarFallback, AvatarImage} from "@/components/ui/avatar";
import {Skeleton} from "@/components/ui/skeleton";
import {cn} from "@/lib/utils";

type ObjectAvatarProps = {
    object: any;
    className?: string;
}
const ObjectAvatar = ({object, className}: ObjectAvatarProps) => {
    const url = object?.avatar_url;
    const displayName = object?.display_name ?? (object?.first_name + ' ' + object?.last_name).trim() ?? object?.email ?? ''
    const initials = displayName
        .split(' ')
        .map((name: string) => name[0])
        .join('')
        .slice(0, 3)
        .toUpperCase()

    if(!object){
        return <Skeleton className={cn("size-8 rounded-full", className)}/>
    }

    return (
        <Avatar
            className={className}
        >
            <AvatarImage src={url} alt={displayName}/>
            <AvatarFallback>{initials}</AvatarFallback>
        </Avatar>
    )
}

export default ObjectAvatar;