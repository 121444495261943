import {Contact} from "@/api/contact.types";
import SendMessage from "@/modules/messages/components/SendMessage";
import {useGetMessagesQuery} from "@/api/message";
import {useEffect, useState} from "react";
import {ArrowUpIcon} from "lucide-react";
import {Message} from "@/api/message.types";
import {ScrollArea} from "@/components/ui/scroll-area";
import MessageBubble from "@/modules/messages/components/MessageBubble";
import {Button} from "@/components/ui/button";

type ConversationProps = {
    contact: Contact;
    showNewMessage?: boolean;
}

const Conversation = ({contact, showNewMessage}: ConversationProps) => {
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const {data, isLoading} = useGetMessagesQuery({contact_uuid: contact.uuid, page});
    const [messages, setMessages] = useState<Message[]>([])

    useEffect(() => {
        if (data) {
            setHasMore(data.meta.last_page > page)
            setMessages([...data.data, ...messages].sort((a: Message, b: Message) => {
                return a.sent_at < b.sent_at ? -1 : 1
            }))
        }

        return () => {
            setMessages([])
            setPage(1)
            setHasMore(true)
        }
        // eslint-disable-next-line
    }, [data]);

    const onSent = (message?: string) => {
        if (message)
            setMessages([...messages, {
                id: Math.random(),
                message,
                sent_at: new Date().toISOString(),
                sent_by: 'user',
                platform: 'email',
            }])
    }

    return (
        <div>
            <ScrollArea
                className="h-[70vh] w-full flex flex-col"
            >
                <div
                    className="flex justify-center items-center"
                >
                    {hasMore && !isLoading && messages?.length && (
                        <Button
                            variant="outline"
                            onClick={() => setPage(page + 1)}
                        >
                            <ArrowUpIcon/>
                        </Button>
                    )}
                </div>

                {messages.map((message) => {
                    return (
                        <MessageBubble key={message.id} message={message}/>
                    )
                })}
            </ScrollArea>

            {showNewMessage && (
                <SendMessage contact={contact} onSent={onSent}/>
            )}
        </div>
    )
}

export default Conversation