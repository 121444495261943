import ObjectAvatar from "@/components/elements/ObjectAvatar";

type ObjectIconsStackProps = {
    objects: any[]
}

const ObjectAvatarsStack = ({objects}: ObjectIconsStackProps) => {
    return <div className="flex gap-2">
        {objects.map((object, index) => (
            <ObjectAvatar
                key={index}
                object={object}
                className="size-6"
            />
        ))}
    </div>
}

export default ObjectAvatarsStack;