import {useGetListsQuery} from "@/api/list";

const useFullLists = (params?: Record<string, string | number>) => {
    // const [page, setPage] = useState(1);
    // const [content, setContent] = useState<List[]>([]);
    //
    // const {
    //     data,
    //     isLoading,
    //     refetch: refetchInit,
    //
    // } = useGetListsQuery({
    //     ...(params ?? {}),
    //     page,
    // });
    //
    // useEffect(() => {
    //     if (!data?.data.length) return;
    //
    //     setContent([
    //         ...content,
    //         ...(data?.data ?? [])
    //     ]);
    //
    //     setPage(page + 1)
    //
    //     // eslint-disable-next-line
    // }, [data]);
    //
    // const refetch = () => {
    //     setContent([]);
    //     setPage(1);
    //     refetchInit();
    // }

    const {data, isLoading, refetch} = useGetListsQuery({...(params ?? {}), page: 1});

    return {
        content: data?.data ?? [],
        isLoading,
        refetch
    }
}

export default useFullLists;