import {PropsWithChildren} from "react";
import {clsx} from "clsx";

type EmailParameterLineProps = {
    label: string;
    separator?: boolean;
}

const EmailParameterLine = ({separator, label, children}: PropsWithChildren<EmailParameterLineProps>) => {
    return (

        <div
            className={clsx([
                "py-2",
                "flex",
                "gap-2",
                "items-center"
            ], {
                "border-b": separator === true || separator === undefined
            })}
        >
            <p
                className={clsx([
                    "opacity-50"
                ])}
            >
                {label}
            </p>

            {children}
        </div>
    )
}

export default EmailParameterLine;