import {useLocation} from "react-router-dom";
import {useEffect} from "react";

const Footer = () => {
    const productName = process.env.REACT_APP_PRODUCT_NAME;
    const productVersion = process.env.REACT_APP_VERSION;

    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <footer className="  container mt-8">
            <div className=" h-24 flex items-center justify-center text-sm opacity-30">
                <p>&copy; {productName} v{productVersion}</p>
            </div>
        </footer>
    )
}

export default Footer;