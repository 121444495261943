import useUser from "@/hooks/useUser";
import React from "react";
import LinkedInSignIn from "@/modules/login/LinkedInSignIn";
import {useTranslation} from "react-i18next";
import Logo from "@/components/elements/Logo";
import useInspiringQuote from "@/hooks/useInspiringQuote";

const UserSignInModal = () => {
    const anonymousRoutePrefixes = [
        'proxy', 'static'
    ];

    const {author: quoteAuthor, quote: quoteText} = useInspiringQuote();

    const uri = window.location.pathname.split('/')[1];

    const {user, isLoading} = useUser();

    const {t} = useTranslation();

    if (!isLoading && !user && !anonymousRoutePrefixes.includes(uri)) {
        return (
            <div
                className="fixed top-0 left-0 z-50 w-full h-full bg-background bg-opacity-90 flex items-center justify-center"
            >
                <div className="md:hidden relative">

                </div>
                <div
                    className="h-full container relative flex-col items-center justify-center grid lg:max-w-none lg:grid-cols-2 lg:px-0">

                    <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
                        <div className="absolute inset-0 bg-zinc-900">
                            <img
                                src={'https://picsum.photos/2000/2000?random=1'}
                                className="object-cover w-full h-full absolute inset-0 opacity-80"
                                alt="Background"
                            />
                            <div
                                className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-70"
                            />
                        </div>
                        <div className="relative z-20 flex items-center text-lg font-medium">
                            <Logo className="w-12 h-12"/>
                        </div>
                        {quoteText && quoteAuthor && (
                            <div className="relative z-20 mt-auto">
                                <blockquote className="space-y-2">
                                    <p className="text-lg">
                                        &ldquo;{quoteText}&rdquo;
                                    </p>
                                    <footer className="text-sm">
                                        {quoteAuthor}
                                    </footer>
                                </blockquote>
                            </div>
                        )}
                    </div>
                    <div className="lg:p-8">
                        <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
                            <div className="flex flex-col space-y-2 text-center">
                                <h1 className="text-2xl font-semibold tracking-tight">
                                    {t('sign_in.title')}
                                </h1>
                                <p className="text-sm text-muted-foreground">
                                    {t('sign_in.description')}
                                </p>
                            </div>

                            <div className="grid gap-6">

                                <div className="relative">
                                    <div className="absolute inset-0 flex items-center">
                                        <span className="w-full border-t"/>
                                    </div>
                                    <div className="relative flex justify-center text-xs uppercase">
          <span className="bg-background px-2 text-muted-foreground">
            {t('sign_in.continue')}
          </span>
                                    </div>
                                </div>

                                <LinkedInSignIn/>

                            </div>

                            <p className="px-8 text-center text-sm text-muted-foreground">
                                {t('sign_in.disclaimer')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return <></>
}

export default UserSignInModal