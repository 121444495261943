import {useGetListQuery} from "@/api/list";
import {useTranslation} from "react-i18next";
import useUser from "@/hooks/useUser";
import ObjectChip from "@/components/elements/ObjectChip";
import EmailParameterLine from "@/modules/automation/components/EmailComposer/EmailParameterLine";
import {Input} from "@/components/ui/input";

import {useState} from "react";
import WysiwygEditor from "@/components/data/WysiwygEditor";

type EmailActionProps = {
    toList?: string;
    onUpdate?: (data: any) => void;
    data?: any;
    toTags?: string[];
}

const EmailAction = ({toTags, onUpdate, toList, data}: EmailActionProps) => {
    const [html, setHtml] = useState(data?.body ?? '');
    const [subject, setSubject] = useState(data?.subject ?? '');

    const {t} = useTranslation();

    const {user} = useUser();

    const {data: list, isLoading: listLoading} = useGetListQuery({uuid: toList ?? ''}, {
        skip: !toList
    });

    const toLoading = listLoading;
    const to = {
        avatar_url: null,
        display_name: list?.name,
    }

    const handleHtmlChange = (value: string) => {
        setHtml(value);
        handleDataUpdate();
    }

    const handleDataUpdate = () => {
        if (onUpdate) {
            onUpdate({
                body: html,
                subject
            })
        }
    }

    return (
        <>
            <EmailParameterLine label={t('messages.from')}>
                <ObjectChip
                    object={user}
                />
            </EmailParameterLine>

            <EmailParameterLine label={t('messages.to')}>
                <ObjectChip
                    object={to}
                    loading={toLoading}
                    tags={[t('messages.tags.with_email'), ...(toTags ?? [])]}
                />
            </EmailParameterLine>

            <EmailParameterLine label={t('messages.subject')} separator={false}>
                <Input
                    placeholder={t('messages.subject_placeholder')}
                    className="px-0 w-full border-none !outline-none !ring-0 text-base"
                    value={subject}
                    onChange={(e) => {
                        setSubject(e.target.value);
                        handleDataUpdate()
                    }}
                    onKeyUp={(e) => {
                        setTimeout(() => {
                            setSubject((e.target as HTMLInputElement).value);
                            handleDataUpdate()
                        }, 150);
                    }}
                />
            </EmailParameterLine>

            <WysiwygEditor value={html} onChange={handleHtmlChange}/>
        </>
    )
}

export default EmailAction;