import {Skeleton} from "@/components/ui/skeleton";

const StageCardSkeleton = () => {
    return (
        <div className="block w-full border mb-2 p-2"
        >
            <div className="flex space-x-2 items-start">
                <Skeleton
                    className="size-8 rounded-full"/>

                <div className="flex-grow">
                    <div className="font-bold mb-2">
                        <Skeleton className="h-4 w-20"/>
                    </div>
                    <div className="text-sm mb-2">
                        <Skeleton className="h-3 w-10"/>
                    </div>
                    <div className="text-sm ">
                        <Skeleton className="h-3 w-14"/>
                    </div>
                </div>
            </div>

            <div className="flex-1">

            </div>
        </div>
    )
}

export default StageCardSkeleton