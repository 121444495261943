import {Label} from "@/components/ui/label";
import {Textarea} from "@/components/ui/textarea";
import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {clsx} from "clsx";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "@/components/ui/dropdown-menu";
import {Button} from "@/components/ui/button";
import {LightBulb} from "iconoir-react";
import useFullContactsList from "@/hooks/useFullContactsList";
import {useGetUserSubjectsQuery} from "@/api/dataset";
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select";
import {Contact} from "@/api/contact.types";
import ObjectAvatar from "@/components/elements/ObjectAvatar";
import {Slider} from "@/components/ui/slider";
import {useGeneratePostMutation} from "@/api/writer";

type WriterOptionsProps = {
    isSavingPost?: boolean;
    setPostText: (text: string) => void;
    onLoading?: (loading: boolean) => void;
}
const WriterOptions = ({isSavingPost,setPostText,onLoading}: WriterOptionsProps) => {
    const [magicPostText, setMagicPostText] = useState<string>('')

    const [styleOfContactUuid, setStyleOfContactUuid] = useState<string | null>(null)
    const [language, setLanguage] = useState<string | null>(null)
    const [textLength, setTextLength] = useState<number>(.33)

    const {content: contacts} = useFullContactsList({
        popular: true
    });

    const {data: userSubjects} = useGetUserSubjectsQuery()

    const randomUserSubjects = useMemo(() => {
        return Object.values(userSubjects ?? [])
            .sort(() => Math.random() - 0.5)
            .slice(0, 7)
    }, [userSubjects]);

    const languages = [
        'en',
        'fr'
    ];

    const {t} = useTranslation();

    const [generatePost, {
        isLoading: isGeneratingPost,
    }] = useGeneratePostMutation();

    useEffect(() => {
        if(onLoading) onLoading(isGeneratingPost)
    }, [isGeneratingPost, onLoading])

    const handleGeneratePost = () => {
        if (isGeneratingPost) return;

        generatePost({
            post_text: magicPostText,
            style_of_contact_uuid: styleOfContactUuid ?? '',
            language: language ?? '',
            post_length: textLength ?? .5
        }).then((response) => {
            const generatedPostData = response.data;

            if (generatedPostData) {
                setPostText(generatedPostData.text);
            }
        });
    }

    return <div>
        <div className="relative w-full">
            <Textarea
                disabled={isSavingPost || isGeneratingPost}
                value={magicPostText}
                onChange={(e) => setMagicPostText(e.target.value)}
                placeholder={t('feed.writer.magic.about_placeholder')}
                className={clsx("w-full block", {
                    "pr-12": randomUserSubjects && randomUserSubjects.length > 0,
                }, [
                    'resize-none',
                    'focus:resize-y',
                ])}
                rows={1}
            />

            {randomUserSubjects && randomUserSubjects.length > 0 && (
                <div
                    className={clsx([
                        "absolute",
                        "top-0",
                        "right-0",
                        "mt-1",
                        "mr-1",
                    ])}
                >
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="outline"
                                    disabled={isSavingPost || isGeneratingPost}
                                    className="size-8 p-0 rounded-sm flex items-center justify-center">
                                <LightBulb className="size-5 text-foreground"/>
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            {randomUserSubjects
                                .map((subject) => {
                                    return (
                                        <DropdownMenuItem key={subject}
                                                          onClick={() => {
                                                              setMagicPostText(subject)
                                                          }}
                                        >
                                            {subject}
                                        </DropdownMenuItem>
                                    )
                                })}
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            )}
        </div>


        <div className="space-y-4 mt-4">
            <div className="flex gap-4 justify-between items-center">
                <Label>
                    {t('feed.writer.language')}
                </Label>

                <Select
                    disabled={isSavingPost || isGeneratingPost}
                    onValueChange={(value) => setLanguage(value)}
                >
                    <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder={t('feed.writer.select_language')}/>
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            {languages.map((language) => {
                                return (
                                    <SelectItem key={language} value={language}>
                                        {t('common.languages.' + language)}
                                    </SelectItem>
                                )
                            })}
                        </SelectGroup>
                    </SelectContent>
                </Select>
            </div>

            {contacts &&
                <div className="flex gap-4 justify-between items-center">
                    <Label>
                        {t('feed.writer.style_of')}
                    </Label>
                    <Select
                        disabled={isSavingPost || isGeneratingPost}
                        onValueChange={(value) => setStyleOfContactUuid(value)}
                    >
                        <SelectTrigger className="w-[180px]">
                            <SelectValue placeholder={t('feed.writer.select_contact')}/>
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                {[...contacts].sort((a: Contact, b: Contact) => {
                                    return (a.display_name ?? '').localeCompare((b.display_name ?? ''))
                                }).map((contact) => {
                                    return (
                                        <SelectItem key={contact.uuid} value={contact.uuid}>
                                            <div className="flex items-center gap-2">
                                                <ObjectAvatar
                                                    object={contact}
                                                />

                                                {contact.display_name}
                                            </div>
                                        </SelectItem>
                                    )
                                })}
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>
            }
            <div className="flex gap-4 justify-between items-center">
                <Label>
                    {t('feed.writer.text_length')}
                </Label>
                <Slider
                    defaultValue={[textLength]}
                    max={1}
                    step={.01}
                    className="w-[180px]"
                    onValueChange={(value) => setTextLength(value[0])}
                />
            </div>
        </div>

        <Button
            onClick={handleGeneratePost}
            disabled={isSavingPost || isGeneratingPost || !magicPostText}
            className="mt-4 w-full"
        >
            {t('feed.writer.magic.create')}
        </Button>
    </div>
}

export default WriterOptions;