import {FeedItem} from "@/api/feed.types";
import {Button} from "@/components/ui/button";
import {Bookmark, BookmarkSolid} from "iconoir-react";
import {useState} from "react";
import {useResourcePostMutation} from "@/api/feed";
import {useTranslation} from "react-i18next";

type ResourceButtonProps = {
    post: FeedItem
    initialStatus?: boolean
}

const ResourceButton = ({post, initialStatus}: ResourceButtonProps) => {
    const {t} = useTranslation();

    const [loading, setLoading] = useState(false);
    const [resourced, setResourced] = useState(!!initialStatus);

    const [addResource] = useResourcePostMutation()
    const onResource = () => {
        setLoading(true);

        addResource({post_uuid: post.uuid}).then((response) => {
            setResourced((response as any)?.data?.status)
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    };

    return (
        <Button
            variant="outline"
            disabled={loading}
            onClick={onResource}
        >
            {resourced ? <BookmarkSolid className="mr-2 text-primary"/> : <Bookmark className="mr-2"/>}
            {resourced ? t('feed.added_resources') : t('feed.add_resource')}
        </Button>
    )
}

export default ResourceButton;