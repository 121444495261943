import {Skeleton} from "@/components/ui/skeleton";
import {clsx} from "clsx";

const PostItemSkeleton = () => {
    return (
        <div
            className={`max-w-[560px] w-full flex-shrink-0 justify-between p-4 border rounded mb-4`}
        >
            <div className={`flex items-center border-b pb-4 mb-4 gap-4`}>
                <Skeleton
                    className={"size-12 rounded-full"}
                ></Skeleton>

                <div className="flex-grow">
                    <div>
                        <Skeleton
                            className={clsx([
                                'h-4',
                                'mb-2',
                                'w-32',
                            ])}
                        />
                    </div>
                    <div
                        className="text-sm"
                    >
                        <Skeleton
                            className={clsx([
                                'h-4',
                                'w-16',
                            ])}
                        />
                    </div>
                </div>
            </div>
            <div

            >
                <Skeleton
                    className={clsx([
                        'h-4',
                        'mb-2',
                        'w-5/6',
                    ])}
                />

                <Skeleton
                    className={clsx([
                        'h-4',
                        'mb-2',
                        'w-3/6',
                    ])}
                />

                <Skeleton
                    className={clsx([
                        'h-4',
                        'mb-2',
                        'w-4/6',
                    ])}
                />
            </div>
        </div>
    )
}

export default PostItemSkeleton;